import React from 'react';
import { Typography, makeStyles, fade, Checkbox } from '@material-ui/core';
import InvoiceItemTable from './AffiliationInvoiceItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { usePagination } from 'hooks/pagination';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { AffiliationInvoice } from 'types/affiliationInvoice';
import { useAffiliationInvoice } from 'pages/affiliation-invoices/hooks/useAffiliationInvoices';

const useStyles = makeStyles(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  paidRow: {
    backgroundColor: fade(theme.palette.success.main, 0.2),
  },
  notPaidRow: {
    backgroundColor: fade(theme.palette.error.main, 0.2),
  },
}));

type AffiliationInvoiceListTableProps = {
  affiliationInvoices: AffiliationInvoice[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const AffiliationInvoiceListTable: React.FC<AffiliationInvoiceListTableProps> = ({
  affiliationInvoices,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const { setSelectAll, selectAll } = useAffiliationInvoice();

  return (
    <TableContent>
      <TableHeader>
        {tableTemplate.map(item => (
          <div
            className={classes.headerItem}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            {item.id === 'checkbox' ? (
              <div>
                <Checkbox onChange={e => setSelectAll(e.target.checked)} checked={selectAll} />
              </div>
            ) : (
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
            )}
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {affiliationInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(affiliationInvoice => (
          <TableRow key={affiliationInvoice.id}>
            <InvoiceItemTable affiliationInvoice={affiliationInvoice} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default AffiliationInvoiceListTable;
