import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Button, Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import { Arena } from 'types/arena';
import { useArenaValidation } from 'pages/arena/validation/ArenaValidation';
import { ArenaProvider } from 'pages/arena/hooks/useArena';
import ArenaActions from '../ArenaActions';
import ArenaForm from '../ArenaForm';
import { ArenaPlan as IArenaPlan } from 'types/arenaPlan';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import ArenaTabs, { ArenaTabValues } from '../ArenaTabs';
import ArenaPlan from '../plans/registration/ArenaPlan';

const INITIAL_STATE: Arena = {
  description: '',
  contact: '',
  whatsapp: '',
  id: 0,
  active: true,
  plans: [],
};

const ArenaNew: React.FC = () => {
  const [arena, setArena] = useState<Arena>(INITIAL_STATE);
  const [validation, setValidation, validate] = useArenaValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const [selectedPlan, setSelectedPlan] = useState<null | IArenaPlan>(null);
  const [tab, setTab] = useState<ArenaTabValues>('arena');
  const [dialogPlan, setDialogPlan] = useState(false);

  useEffect(() => {
    if (!selectedPlan) {
      return;
    }

    setDialogPlan(true);
  }, [selectedPlan]);

  const handleChange = useCallback((index: keyof Arena, value: any) => {
    setArena(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(arena)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/arenas`, arena)
      .then(() => {
        setSaving(false);
        history.push('/arenas');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error ? 'Não foi possível criar a arena' : '');
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleFormatPlan(plan: IArenaPlan): IArenaPlan {
    return {
      ...plan,
      formattedActive: plan.active ? 'Sim' : 'Não',
      formattedCreatedAt: format(parseISO(plan.created_at), 'P', { locale: ptBR }),
      formattedValue: moneyFormat(plan.value),
      formattedMonthQuantity:
        plan.months_quantity > 1 ? `${plan.months_quantity} meses` : `${plan.months_quantity} mês`,
    };
  }

  function handleAddPlan(plan: IArenaPlan) {
    setArena(state => ({
      ...state,
      plans: [...state.plans, handleFormatPlan(plan)],
    }));
  }

  function handleUpdatePlan(plan: IArenaPlan) {
    setArena(state => ({
      ...state,
      plans: state.plans.map(_plan => (plan.id === _plan.id ? handleFormatPlan(plan) : _plan)),
    }));
  }

  function handleDeletePlan(planId: number) {
    setArena(state => ({
      ...state,
      plans: state.plans.filter(plan => plan.id !== planId),
    }));
  }

  function onExitedPlanDialog() {
    setDialogPlan(false);
    setSelectedPlan(null);
  }

  return (
    <ArenaProvider
      value={{
        handleChange,
        handleValidation,
        arena,
        selectedPlan,
        setSelectedPlan,
        handleAddPlan,
        handleDeletePlan,
        handleUpdatePlan,
      }}
    >
      {saving && <Loading />}
      {dialogPlan && <ArenaPlan onExited={onExitedPlanDialog} />}
      <Appbar
        title="Arenas"
        ActionsComponent={<ArenaActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/arenas')}
        Tab={<ArenaTabs tab={tab} onChange={tab => setTab(tab)} />}
      />
      <PageHeaderActions
        title="Arenas"
        ActionComponent={
          tab === 'plans' ? (
            <Button size="small" variant="contained" color="primary" onClick={() => setDialogPlan(true)}>
              adicionar
            </Button>
          ) : undefined
        }
      />
      <Grid container>
        <form onSubmit={handleValidation}>
          <ArenaForm handleChange={handleChange} arena={arena} validation={validation} />
        </form>
      </Grid>
    </ArenaProvider>
  );
};

export default ArenaNew;
