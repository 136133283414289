import React, { useEffect, useRef } from 'react';
import { Button, Checkbox, FormControlLabel, makeStyles, MenuItem, TextField } from '@material-ui/core';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { QueryParams } from './DayUseInvoices';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '220px 1fr 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      gridTemplateColumns: '200px 200px 200px',
      alignItems: 'center',
      columnGap: 20,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '200px 200px 200px',
        columnGap: 20,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  date: {
    display: 'grid',
    gap: 10,
  },
}));

type PlansFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  hasSelected: boolean;
  handleConciliated(): void;
};

const PlansFilterBox: React.FC<PlansFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
  hasSelected,
  handleConciliated,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      {hasSelected ? (
        <div>
          <Button onClick={handleConciliated} color="primary" variant="contained" size="small">
            conciliar
          </Button>
        </div>
      ) : (
        <div className={classes.filter}>
          <TextField
            label="Nome do cliente"
            placeholder="Digite o nome do cliente"
            value={queryParams.customer_name}
            onChange={e => handleQueryParamsChange('customer_name', e.target.value)}
            autoFocus
          />
          <div className="search-params">
            <TextField
              select
              label="Tipo da data"
              onChange={e => handleQueryParamsChange('date_field', e.target.value)}
              value={queryParams.date_field}
            >
              <MenuItem value="day_use">Day use</MenuItem>
              <MenuItem value="due_at">Vencimento</MenuItem>
              <MenuItem value="created_at">Pagamento</MenuItem>
            </TextField>
            <div className={classes.date}>
              <KeyboardDatePicker
                label="Data inicial"
                value={queryParams.initial_date}
                onChange={date => handleQueryParamsChange('initial_date', date)}
                autoOk
              />
              <KeyboardDatePicker
                label="Data Final"
                value={queryParams.final_date}
                onChange={date => handleQueryParamsChange('final_date', date)}
                autoOk
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={queryParams.only_conciliated}
                  onChange={e => handleQueryParamsChange('only_conciliated', e.target.checked)}
                  color="primary"
                />
              }
              label="Conciliados"
            />
          </div>
        </div>
      )}

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default PlansFilterBox;
