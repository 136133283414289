import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import PageItemModule from './PageItemModule';
import { Page } from 'types/page';

type PageListModuleProps = {
  pages: Page[];
};

const PageListModule: React.FC<PageListModuleProps> = ({ pages }) => {
  const { rowsPerPage, page: pageNumber } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {pages.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage).map(page => (
            <PageItemModule key={page.id} page={page} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default PageListModule;
