import { Address } from 'types/address';
import { User } from 'types/user';
import { UserActionsType, USER_CHANGE, SET_USER, SET_PROFILE } from './types';

export const USER_INITIAL_STATE: User = {
  name: '',
  email: '',
  phone: '',
  activity_id: 0,
  cpf: '',
  id: 0,
  user_profile: null,
  user_profile_id: null,
  address: {} as Address,
  customerAddress: '',
  customerCity: '',
  customerNeighborhood: '',
  customerState: '',
  password: '',
};

export default function userReducer(state = USER_INITIAL_STATE, action: UserActionsType): User {
  switch (action.type) {
    case USER_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case SET_USER: {
      return action.user;
    }

    case SET_PROFILE: {
      return {
        ...state,
        user_profile_id: action.profile.id as number,
        user_profile: action.profile,
      };
    }

    default: {
      return state;
    }
  }
}
