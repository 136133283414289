import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import { User } from 'types/user';
import { CustomersProvider } from './hooks/useCustomers';
import CustomerListTable from './list/table/CustomerListTable';
import CustomerListModule from './list/module/CustomerListModule';
import CustomerFilterBox from './CustomerFilterBox';
import { customerTableTemplate } from './customerTableTemplate';
import CustomerSelector from './selected-customer/SelectedCustomer';
import { CustomerExport } from './CustomerExport';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Customers: React.FC = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState<User[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<null | User>(null);
  const [loading, setLoading] = useState(true);
  const [excelExport, setExcelExport] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<User[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    api
      .get<User[]>('/users')
      .then(response => {
        setCustomers(
          response.data.map(customer => {
            customer.customerAddress = customer.address
              ? `${customer.address.street}, ${customer.address.street_number}`
              : '';
            customer.customerCity = customer.address ? customer.address.city : '';
            customer.customerNeighborhood = customer.address ? customer.address.neighborhood : '';
            customer.customerState = customer.address ? customer.address.state : '';
            return customer;
          }),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    const f = search(searchValue, 'name', customers);
    setFiltered(f);
  }, [customers, searchValue, search]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleUpdateCustomers(customer: User) {
    setCustomers(state =>
      state.map(_customer => {
        if (_customer.id === customer.id) {
          return {
            ..._customer,
            ...customer,
          };
        }

        return _customer;
      }),
    );
  }

  return (
    <CustomersProvider value={{ selectedCustomer, setSelectedCustomer, setCustomers, handleUpdateCustomers }}>
      {selectedCustomer && <CustomerSelector onExited={() => setSelectedCustomer(null)} />}
      {excelExport && <CustomerExport onExited={() => setExcelExport(false)} data={customers} />}

      <Appbar title="Clientes" />
      <PageHeaderActions
        title="Clientes"
        description="Relação de clientes"
        ActionComponent={
          <Button
            disabled={customers.length === 0}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setExcelExport(true)}
          >
            Exportar clientes
          </Button>
        }
      />
      <TableContainer tableTemplate={customerTableTemplate}>
        <CustomerFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch="name"
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum cliente para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <CustomerListTable customers={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <CustomerListModule customers={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </CustomersProvider>
  );
};

export default Customers;
