import React, { Dispatch, SetStateAction } from 'react';
import { Tab, Tabs } from '@material-ui/core';

export type SelectedCustomerTabValues = 'data' | 'invoicePaid' | 'invoiceNoPaid' | 'day_use';

interface CustomerTabsProps {
  handleTabChange: Dispatch<SetStateAction<SelectedCustomerTabValues>>;
  tab: SelectedCustomerTabValues;
}

const CustomerTabs: React.FC<CustomerTabsProps> = ({ handleTabChange, tab }) => {
  return (
    <Tabs value={tab} onChange={(e, tabValue) => handleTabChange(tabValue)} variant="scrollable">
      <Tab style={{ textTransform: 'capitalize' }} value="data" label="Dados" />
      <Tab style={{ textTransform: 'capitalize' }} value="invoicePaid" label="Faturas pagas" />
      <Tab style={{ textTransform: 'capitalize' }} value="invoiceNoPaid" label="Faturas não pagas" />
      <Tab style={{ textTransform: 'capitalize' }} value="day_use" label="Day Use" />
    </Tabs>
  );
};

export default CustomerTabs;
