import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { Arena } from 'types/arena';

export type ArenaValidation = {
  contact?: string;
  whatsapp?: string;
  description?: string;
};

type UseArenaValidation = [ArenaValidation, Dispatch<SetStateAction<ArenaValidation>>, (arena: Arena) => Promise<void>];

export function useArenaValidation(): UseArenaValidation {
  async function handleValidation(arena: Arena) {
    const schema = yup.object().shape({
      contact: yup.string().required('O contato é obrigatório'),
      whatsapp: yup.string().required('O whatsapp é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
    });

    try {
      await schema.validate(arena);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<ArenaValidation>({});
  return [validation, setValidation, handleValidation];
}
