import { TableTemplate } from 'types/tableTemplate';

export const courtTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'description',
    description: 'Descrição',
    originalId: 'description',
    width: 300,
  },
  {
    id: 'formattedActive',
    description: 'Ativo',
    originalId: 'active',
    width: 150,
  },
  {
    id: 'arena_description',
    description: 'Descrição Arena',
    originalId: 'arena_description',
    width: 150,
  },
];
