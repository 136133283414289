import React, { useCallback, useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Appbar from 'components/appbar/Appbar';
import { DayUse } from 'types/dayuse';
import DayUseActions from './DayUseActions';
import DayUseFilterBox from './DayUseFilterBox';
import { dayUseTableTemplate } from './dayUseTableTemplate';
import { DaysUseProvider } from './hooks/useDaysUse';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'hooks/app';
import { format, parseISO, subDays } from 'date-fns';
import { api } from 'services/api';
import Pagination from 'components/pagination/Pagination';
import PaginationProvider from 'hooks/pagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import TableContainer from 'components/table/TableContainer';
import DayUseListTable from './list/table/DayUseListTable';
import DayUseListModule from './list/module/DayUseListModule';
import history from 'services/history';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';

let timer: NodeJS.Timeout;

export interface QueryParams {
  initial_date: Date | null;
  final_date: Date | null;
  description: string;
  active: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const queryParamsInitialValue: QueryParams = {
  initial_date: subDays(new Date(), 0),
  final_date: subDays(new Date(), -30),
  description: '',
  active: false,
};

const DaysUse: React.FC = () => {
  const classes = useStyles();
  const [dayUse, setDayUse] = useState<DayUse[]>([]);
  const [selectedDayUse, setSelectedDayUse] = useState<null | DayUse>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<DayUse[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);

  const fetchInvoices = useCallback((query: QueryParams) => {
    setLoading(true);

    api
      .get('/dayuses', { params: { ...query } })
      .then(response => {
        setDayUse(
          response.data.map(dayuse => ({
            ...dayuse,
            formattedValue: moneyFormat(dayuse.value),
            formattedActive: !dayuse.active ? 'Sim' : 'Não',
            formattedDay: format(parseISO(dayuse.day), 'P', { locale: ptBR }),
            formattedTypeDay: dayuse.type_day === 'weeked' ? 'Dia de semana' : 'Final de semana',
          })),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => fetchInvoices(queryParamsInitialValue), [fetchInvoices]);

  useEffect(() => {
    setFiltered(dayUse);
  }, [dayUse]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, dayUse);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'description') {
      fetchInvoices(query);
      return;
    }

    if (index === 'description' && value.lenght < 4) {
      return;
    }

    timer = setTimeout(() => fetchInvoices(query), 500);
  }

  return (
    <DaysUseProvider value={{ selectedDayUse, setSelectedDayUse }}>
      <Appbar title="Site" ActionsComponent={<DayUseActions />} />
      <PageHeaderActions
        title="Day Use"
        description="Gestão dos day use para site H2i"
        ActionComponent={
          <Button size="small" variant="contained" color="primary" onClick={() => history.push('/day-use/new')}>
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={dayUseTableTemplate}>
        <DayUseFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum day use para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <DayUseListTable daysUse={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <DayUseListModule daysUse={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </DaysUseProvider>
  );
};

export default DaysUse;
