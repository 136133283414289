import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import DefaultLayout from 'components/layout/DefaultLayout';
import { useAuth } from 'hooks/auth';

interface PrivateRouteProps extends RouteProps {
  component: FC;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={() =>
        auth.checkAuth() ? (
          <DefaultLayout>
            <Component />
          </DefaultLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
