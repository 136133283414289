import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { Affiliate } from 'types/affiliate';
import { useAssociates } from 'pages/associate/hooks/useAssociates';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type AssociateItemModuleProps = {
  affiliate: Affiliate;
};

const AssociateItemModule: React.FC<AssociateItemModuleProps> = ({ affiliate }) => {
  const classes = useStyles();
  const { setSelectedAssociate } = useAssociates();

  function handleClick() {
    setSelectedAssociate(affiliate);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <Typography variant="caption" color="primary" gutterBottom>
          ID {affiliate.id}
        </Typography>
        <Typography gutterBottom>{affiliate.user.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          Válido até {affiliate.formattedValidAt}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {affiliate.active ? 'Ativo' : 'Inativo'}
        </Typography>
      </ListItem>
    </>
  );
};

export default AssociateItemModule;
