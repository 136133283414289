import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  listItemActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderLeft: `2px solid ${theme.palette.secondary.light}!important`,
    transition: 'border-color 0.5s ease',
    '& svg': {
      color: theme.palette.secondary.main,
      transition: 'color 0.5s ease',
    },
  },
});

class CustomNavLink extends React.Component {
  render() {
    const { classes } = this.props;

    return <NavLink {...this.props} exact activeClassName={classes.listItemActive} />;
  }
}

export default withStyles(styles)(CustomNavLink);
