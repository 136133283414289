import React from 'react';
import { useDialog } from 'components/dialogs/Dialog';
import { Button, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  downloadButton: {
    marginTop: 40,
  },
});

const ExcelButtonDownload: React.FC = () => {
  const classes = styles();

  const { handleClose } = useDialog();
  return (
    <Button onClick={handleClose} className={classes.downloadButton} size="large" color="primary" variant="contained">
      Baixar planilha
    </Button>
  );
};

export default ExcelButtonDownload;
