import React from 'react';
import { Typography, makeStyles, fade, Checkbox } from '@material-ui/core';
import InvoiceItemTable from './InvoiceItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { usePagination } from 'hooks/pagination';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Invoice } from 'types/invoice';
import { useInvoices } from 'pages/invoices/hooks/useInvoices';

const useStyles = makeStyles(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  paidRow: {
    backgroundColor: fade(theme.palette.success.main, 0.2),
  },
  notPaidRow: {
    backgroundColor: fade(theme.palette.error.main, 0.2),
  },
  selectedrow: {
    backgroundColor: fade(theme.palette.secondary.main, 0.2),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.3),
    },
  },
}));

type InvoiceListTableProps = {
  invoices: Invoice[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const InvoiceListTable: React.FC<InvoiceListTableProps> = ({ invoices, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();
  const { setSelectAll, selectAll } = useInvoices();

  return (
    <TableContent>
      <TableHeader>
        {tableTemplate.map(item => (
          <div
            className={classes.headerItem}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            {item.id === 'checkbox' ? (
              <div>
                <Checkbox onChange={e => setSelectAll(e.target.checked)} checked={selectAll} />
              </div>
            ) : (
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
            )}

            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(invoice => (
          <TableRow
            className={invoice.selectedInGrid ? classes.selectedrow : invoice.paid_at ? classes.paidRow : undefined}
            key={invoice.id}
            onClick={() => history.push(`/invoices/${invoice.id}`)}
          >
            <InvoiceItemTable invoice={invoice} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default InvoiceListTable;
