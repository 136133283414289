import React, { ReactElement } from 'react';
import { AppBar as NativeAppbar, Toolbar, IconButton, Typography, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useApp } from 'hooks/app';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { SIDEBAR_WIDTH } from 'constants/constants';

type AppbarProps = {
  title: string;
  ActionsComponent?: ReactElement;
  backAction?(): void;
  Tab?: ReactElement;
};

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title: {
    flexGrow: 1,
  },
  appbar: {
    boxShadow: 'none',
    zIndex: 1102,
    '@media print': {
      display: 'none',
    },
  },
  appBarTabsSpace: {
    marginBottom: 45,
    [theme.breakpoints.down('md')]: {
      marginBottom: 48,
    },
  },
  appBarTabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.light,
    },
    paddingLeft: 0,
    transition: 'padding-left 400ms ease',
  },
  appBarTabsMenuOpen: {
    paddingLeft: SIDEBAR_WIDTH,
  },
}));

const Appbar: React.FC<AppbarProps> = ({ title, ActionsComponent, backAction, Tab }) => {
  const classes = useStyles();
  const app = useApp();

  return (
    <>
      <NativeAppbar position="fixed" classes={{ root: classes.appbar }}>
        <Toolbar>
          {backAction && app.isMobile && app.windowWidth < 960 ? (
            <IconButton onClick={backAction} color="inherit">
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <IconButton onClick={app.handleOpenMenu} color="inherit">
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.title}>
            <Typography color="inherit">{app.isMobile || app.windowWidth < 960 ? title : 'Arena H2i'}</Typography>
          </div>
          {ActionsComponent && <div className={classes.actions}>{ActionsComponent}</div>}
        </Toolbar>
      </NativeAppbar>
      {Tab && (
        <>
          <NativeAppbar
            className={`${classes.appBarTabs} ${
              app.isOpenedMenu && !app.isMobile && window.innerWidth > 960 && classes.appBarTabsMenuOpen
            }`}
          >
            {Tab}
          </NativeAppbar>
          <div className={Tab && classes.appBarTabsSpace} />
        </>
      )}
    </>
  );
};

export default Appbar;
