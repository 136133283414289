import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Invoice } from 'types/invoice';
import { BoxReport } from 'components/chart/BoxReport';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface InvoiceNoPaidChartProps {
  invoicesNoPaid: Invoice[];
}

export const InvoiceNoPaidChart: React.FC<InvoiceNoPaidChartProps> = ({ invoicesNoPaid }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BoxReport title="Qtd de faturas não pagas">
        <Typography variant="body2">Total de faturas não pagas: {invoicesNoPaid.length}</Typography>
      </BoxReport>
    </div>
  );
};
