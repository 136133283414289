import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Arena } from 'types/arena';

type ArenasContextValue = {
  selectedArena: Arena | null;
  setSelectedArena(arena: Arena | null): void;
  setArena: Dispatch<SetStateAction<Arena[]>>;
};

const ArenasContext = createContext<ArenasContextValue>({} as ArenasContextValue);
export const ArenasProvider = ArenasContext.Provider;

export function useArenas(): ArenasContextValue {
  const context = useContext(ArenasContext);
  return context;
}
