import { TableTemplate } from 'types/tableTemplate';

export const newsletterSubscribers: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'email',
    description: 'E-MAIL',
    originalId: 'email',
    width: 300,
  },
  {
    id: 'formattedPhone',
    description: 'TELEFONE',
    originalId: 'formattedPhone',
    width: 200,
  },
  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
