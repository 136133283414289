import React, { useState, useEffect } from 'react';
import Routes from 'routes/Routes';
import AppContext, { AppContextData } from 'hooks/app';
import { useAuth } from 'hooks/auth';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'config/theme';
import MessagingProvider from 'hooks/messaging';
import { mobileCheck } from 'helpers/MobileCheck';
import SplashScreen from 'components/loading/SplashScreen';
import PermissionRulesProvider from 'providers/PermissionRulesProvider';

const App: React.FC = () => {
  const [isMobile, setIsMobile] = useState(mobileCheck());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenedMenu, setIsOpenedMenu] = useState(!mobileCheck() && windowWidth > 1280);
  const { logout, isLoading } = useAuth();

  const contextValue: AppContextData = {
    isMobile,
    windowWidth,
    isOpenedMenu,
    handleOpenMenu,
    handleLogout,
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  function handleResize() {
    setIsMobile(mobileCheck());
    setWindowWidth(window.innerWidth);
  }

  function handleOpenMenu() {
    setIsOpenedMenu(!isOpenedMenu);
  }

  function handleLogout() {
    logout();
  }

  return (
    <AppContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        {isLoading && <SplashScreen />}
        <MessagingProvider>
          <PermissionRulesProvider>
            <Routes />
          </PermissionRulesProvider>
        </MessagingProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
