import React, { useEffect, useRef, useState } from 'react';
import { FormControlLabel, Grid, Switch, TextField, makeStyles } from '@material-ui/core';
import { SiteBannerValidation } from './validation/siteBannerValidation';
import ImageSelector from 'components/image-selector/ImageSelector';
import ImagePreview from 'components/image-preview/ImagePreview';
import SiteBannerImageForm from './SiteBannerImageForm';
import { SliderBanner } from 'types/sliderBanner';
import SiteBannerMobileImageForm from './SiteBannerMobileImageForm';

const useStyles = makeStyles({
  switch: {
    marginTop: 20,
  },
  container: {
    marginBottom: 100,
  },
});

type SiteBannerFormProps = {
  banner: SliderBanner;
  handleChange(index: keyof SliderBanner, value: any): void;
  validation: SiteBannerValidation;
};

const SiteBannerForm: React.FC<SiteBannerFormProps> = ({ banner, handleChange, validation }) => {
  const classes = useStyles();

  const [imageSelector, setImageSelector] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<null | string>('');

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    subtitle: useRef<HTMLInputElement>(null),
    link: useRef<HTMLInputElement>(null),
    order: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {!!selectedImageSrc && (
        <ImagePreview
          src={selectedImageSrc}
          description="Imagem da mensagem de e-mail"
          onExited={() => setSelectedImageSrc('')}
        />
      )}
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('desktop_image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      <Grid item xs={12} xl={4} lg={4} md={6} className={classes.container}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name}
          label="Nome"
          placeholder="Digite um nome para o banner"
          margin="normal"
          fullWidth
          value={banner.name}
          onChange={e => handleChange('name', e.target.value)}
          autoFocus
        />
        <TextField
          inputRef={inputs.title}
          error={!!validation.title}
          helperText={validation.title}
          label="Título"
          placeholder="Digite um título para o banner"
          margin="normal"
          fullWidth
          value={banner.title}
          onChange={e => handleChange('title', e.target.value)}
        />

        <TextField
          inputRef={inputs.subtitle}
          error={!!validation.subtitle}
          helperText={validation.subtitle}
          label="Subtítulo"
          placeholder="Digite um subtítulo para o banner"
          margin="normal"
          fullWidth
          value={banner.subtitle}
          onChange={e => handleChange('subtitle', e.target.value)}
        />

        <TextField
          inputRef={inputs.link}
          error={!!validation.link}
          helperText={validation.link}
          label="Link"
          placeholder="Informe o link"
          margin="normal"
          fullWidth
          value={banner.link}
          onChange={e => handleChange('link', e.target.value)}
        />

        <TextField
          inputRef={inputs.order}
          error={!!validation.order}
          helperText={validation.order}
          label="Ordem"
          placeholder="Informe a ordem"
          margin="normal"
          fullWidth
          value={banner.order}
          onChange={e => handleChange('order', e.target.value)}
        />

        <SiteBannerImageForm validation={validation} banner={banner} handleChange={handleChange} />
        <SiteBannerMobileImageForm validation={validation} banner={banner} handleChange={handleChange} />

        <FormControlLabel
          label="Ativo"
          className={classes.switch}
          control={
            <Switch
              color="primary"
              onChange={event => handleChange('active', event.target.checked)}
              checked={banner.active}
            />
          }
        />

        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </>
  );
};

export default SiteBannerForm;
