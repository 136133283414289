import React from 'react';
import { fade, ListItem, makeStyles, Typography } from '@material-ui/core';
import { User } from 'types/user';
import { useDialog } from 'components/dialogs/Dialog';
import { useInvoice } from 'pages/invoices/hooks/useInvoice';

const styles = makeStyles(theme => ({
  listItem: {
    justifyContent: 'space-between',
    boxShadow: '1px 1px 9px 1px #eee',
    '&.selected': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
  },
}));

interface UserSelectorItemProps {
  user: User;
}

const UserSelectorItem: React.FC<UserSelectorItemProps> = ({ user }) => {
  const classes = styles();
  const { handleClose } = useDialog();
  const { handleChange } = useInvoice();

  function handleClick() {
    handleChange('user', user);
    handleChange('user_id', user.id);
    handleClose();
  }

  return (
    <ListItem button className={classes.listItem} onClick={handleClick}>
      <div>
        <Typography variant="body1">{user.name}</Typography>
        <Typography variant="subtitle1">CPF - {user.cpf}</Typography>
        <Typography variant="body2" color="textSecondary">
          Telefone - {user.phone}
        </Typography>
      </div>
    </ListItem>
  );
};

export default UserSelectorItem;
