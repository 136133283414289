import React from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from 'components/dialogs/Dialog';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { DayUseInvoice } from 'types/dayUseInvoice';
import { moneyFormat } from 'helpers/numberFormat';

type DayUseInvoicesDataProps = {
  data: DayUseInvoice[];
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    columnGap: '8px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 60px',
    marginBottom: 1,
    paddingBottom: 1,
  },
  headerInfo: {
    marginTop: 10,
  },
  rowP: {
    fontSize: '7pt',
    textAlign: 'end',
  },
  rowPL: {
    fontSize: '7pt',
    textAlign: 'start',
  },
});

const DayUseInvoicesData: React.FC<DayUseInvoicesDataProps> = ({ data, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  return (
    <Dialog fullScreen title="Faturas" onExited={onExited}>
      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de faturas do day use</h6>
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p className={classes.rowPL}>Cliente</p>
          <p className={classes.rowPL}>Telefone</p>
          <p className={classes.rowPL}>E-mail</p>
          <p className={classes.rowPL}>Documento</p>
          <p className={classes.rowPL}>Day use</p>
          <p className={classes.rowP}>Valor</p>
        </div>
        {data.map(item => (
          <div key={item.id} className={classes.row}>
            <p className={classes.rowPL}>{item.customer_name}</p>
            <p className={classes.rowPL}>{item.customer_phone}</p>
            <p className={classes.rowPL}>{item.customer_email}</p>
            <p className={classes.rowPL}>{item.customer_document}</p>
            <p className={classes.rowPL}> {format(parseISO(item.day_use.day), 'P', { locale: ptBR })}</p>
            <p className={classes.rowP}>{moneyFormat(item.value)}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default DayUseInvoicesData;
