import React, { ReactElement } from 'react';
import { IconButton, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import history from 'services/history';

interface useStylesProps {
  gutterBottom: boolean;
}

const useStyles = makeStyles(theme => ({
  container: ({ gutterBottom }: useStylesProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: gutterBottom ? 20 : 0,
    '@media print': {
      display: 'none',
    },
  }),
  content: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  backButton: {
    marginRight: 15,
  },
  actions: {
    '& button + button': {
      marginLeft: 7,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface PageHeaderActionsProps {
  title: string;
  description?: string;
  gutterBottom?: boolean;
  ActionComponent?: ReactElement;
  backUrl?: string;
}

const PageHeaderActions: React.FC<PageHeaderActionsProps> = ({
  title,
  description,
  gutterBottom = true,
  ActionComponent,
  backUrl,
}) => {
  const classes = useStyles({ gutterBottom });

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {backUrl && (
          <IconButton className={classes.backButton} size="small" onClick={() => history.push(backUrl)}>
            <ArrowBack />
          </IconButton>
        )}
        <div>
          <Typography variant="h6">{title}</Typography>
          {description && (
            <Typography color="textSecondary" variant="body2">
              {description}
            </Typography>
          )}
        </div>
      </div>

      {ActionComponent && <div className={classes.actions}>{ActionComponent}</div>}
    </div>
  );
};

export default PageHeaderActions;
