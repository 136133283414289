import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { Arena } from 'types/arena';
import ArenaSelectorItem from './ArenaSelectorItem';

const styles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
});

interface ArenasSelectorListProps {
  arenas: Arena[];
}

const ArenasSelectorList: React.FC<ArenasSelectorListProps> = ({ arenas }) => {
  const classes = styles();

  return (
    <List className={classes.list}>
      {arenas.map(arena => (
        <ArenaSelectorItem arena={arena} key={arena.id} />
      ))}
    </List>
  );
};

export default ArenasSelectorList;
