import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { useCustomers } from 'pages/customers/hooks/useCustomers';
import { User } from 'types/user';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type CustomerItemModuleProps = {
  customer: User;
};

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  const classes = useStyles();
  const { setSelectedCustomer } = useCustomers();

  function handleClick() {
    setSelectedCustomer(customer);
  }

  return (
    <>
      <ListItem button className={classes.listItem} onClick={handleClick}>
        <Typography variant="caption" color="primary">
          ID {customer.id}
        </Typography>
        <Typography gutterBottom>{customer.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {customer.cpf}
        </Typography>
      </ListItem>
    </>
  );
};

export default CustomerItemModule;
