import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { DayUse } from 'types/dayuse';
import { DayUseProvider } from 'pages/day-use/hooks/useDayUse';
import DayUseForm from '../DayUseForm';
import DayUseActions from './DayUseActions';
import { useDayUseValidation } from '../validation/dayUseValidation';

const INITIAL_STATE: DayUse = {
  id: 0,
  day: '',
  value: 0,
  active: false,
  type_day: '',
};

const DayUseEdit: React.FC = () => {
  const [dayUse, setDayUse] = useState<DayUse>(INITIAL_STATE);
  const [validation, setValidation, validate] = useDayUseValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(false);

  useEffect(() => {
    setType(true);
    api
      .get<DayUse>(`/dayuses/${id}`)
      .then(response => {
        setDayUse({
          ...response.data,
          active: !response.data.active,
        });
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  const handleChange = useCallback((index: keyof DayUse, value: any) => {
    setDayUse(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(dayUse)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .patch(`/dayuses/${id}`, { ...dayUse, active: !dayUse.active })
      .then(() => {
        setSaving(false);
        history.push('/day-use');
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível atualizar o day use');
        setSaving(false);
      });
  }

  return (
    <DayUseProvider value={{ handleChange, dayUse }}>
      {saving && <Loading />}
      <Appbar
        title="Day use"
        ActionsComponent={<DayUseActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/day-use')}
      />
      <PageHeader title="Editar day use" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <DayUseForm type={type} handleChange={handleChange} dayUse={dayUse} validation={validation} />
          </form>
        </Grid>
      )}
    </DayUseProvider>
  );
};

export default DayUseEdit;
