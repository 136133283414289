import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import ArenaPlanItemModule from './ArenaPlanItemModule';
import { ArenaPlan } from 'types/arenaPlan';

type ArenaPlanListModuleProps = {
  plans: ArenaPlan[];
};

const ArenaPlanListModule: React.FC<ArenaPlanListModuleProps> = ({ plans }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {plans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(plan => (
            <ArenaPlanItemModule key={plan.id} plan={plan} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ArenaPlanListModule;
