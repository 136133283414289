import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import DayUseInvoiceItemModule from './AffiliationInvoiceItemModule';
import { AffiliationInvoice } from 'types/affiliationInvoice';

type AffiliationInvoiceListModuleProps = {
  affiliationInvoices: AffiliationInvoice[];
};

const AffiliationInvoiceListModule: React.FC<AffiliationInvoiceListModuleProps> = ({ affiliationInvoices }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {affiliationInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(affiliationInvoice => (
        <DayUseInvoiceItemModule key={affiliationInvoice.id} affiliationInvoice={affiliationInvoice} />
      ))}
    </List>
  );
};

export default AffiliationInvoiceListModule;
