import { makeStyles, Switch, Typography } from '@material-ui/core';
import React from 'react';
import { AccessRule } from 'types/accessRule';

type ProfileFormAccessRulesProps = {
  accessRules: AccessRule[];
  handleAccessRulesChange(id: number): void;
  accessRuleId: number;
};

const useStyles = makeStyles({
  formControl: {
    paddingLeft: 15,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rules: {
    marginTop: 20,
  },
  title: {
    textTransform: 'uppercase',
  },
});

const ProfileFormAccessRules: React.FC<ProfileFormAccessRulesProps> = ({
  accessRules,
  handleAccessRulesChange,
  accessRuleId,
}) => {
  const classes = useStyles();

  return (
    <>
      {accessRules.map(
        rule =>
          rule.access_rule_id === accessRuleId && (
            <div key={rule.id} className={classes.formControl}>
              <div className={classes.header}>
                <Typography className={classes.title} variant="body2">
                  {rule.title}
                </Typography>
                <Switch color="primary" onChange={() => handleAccessRulesChange(rule.id)} checked={rule.active} />
              </div>
              <Typography variant="body2" color="textSecondary">
                {rule.description}
              </Typography>
              {rule.access_rules.length > 0 && (
                <ProfileFormAccessRules
                  accessRules={rule.access_rules}
                  handleAccessRulesChange={handleAccessRulesChange}
                  accessRuleId={rule.id}
                />
              )}
            </div>
          ),
      )}
    </>
  );
};

export default ProfileFormAccessRules;
