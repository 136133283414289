import { Button, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { api } from 'services/api';
import history from 'services/history';
import { AccessRule as AccessRuleType } from 'types/accessRule';
import AccessRulesActions from './AccessRulesActions';
import AccessRulesModule from './list/module/AccessRulesList';
import AccessRulesTable from './list/table/AccessRulesList';

const useStyles = makeStyles({
  noData: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    background: '#fff',
    marginTop: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const AccessRules: React.FC = () => {
  const classes = useStyles();
  const [rules, setRules] = useState<AccessRuleType[]>([]);
  const [loading, setLoading] = useState(true);
  const app = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(
    app.isMobile || app.windowWidth < 930 ? 'module' : 'list',
  );
  const search = useSearch();
  const [filtered, setFiltered] = useState<AccessRuleType[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    setLoading(true);
    api
      .get<AccessRuleType[]>('/accessRules')
      .then(response => {
        setRules(response.data);
        setFiltered(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleSearch(searchValue: string) {
    if (searchValue) {
      const _rules = search(searchValue, 'title', rules);
      setFiltered(_rules);
      return;
    }

    setFiltered(rules);
  }

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <>
      <Appbar title="Permissões" ActionsComponent={<AccessRulesActions />} />
      <PageHeaderActions
        title="Regras de acesso"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/access-rule')}>
            Adicionar
          </Button>
        }
      />
      <FilterBox>
        <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}>
          <TextField
            fullWidth
            label="Pesquisar"
            placeholder="Digite sua pesquisa"
            autoFocus
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <div className={classes.noData}>
          <Typography color="textSecondary" variant="h6">
            Nenhuma regra de acesso
          </Typography>
        </div>
      ) : (
        <PaginationProvider>
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <AccessRulesTable accessRules={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              <AccessRulesModule accessRules={filtered} />
            )}
            <Pagination count={filtered.length} />
          </div>
        </PaginationProvider>
      )}
    </>
  );
};

export default AccessRules;
