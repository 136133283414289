import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import history from 'services/history';

interface useStylesProps {
  gutterBottom: boolean;
}

const useStyles = makeStyles({
  header: ({ gutterBottom }: useStylesProps) => ({
    marginBottom: gutterBottom ? 10 : 0,
    display: 'flex',
    alignItems: 'center',
    '@media print': {
      display: 'none',
    },
  }),
  backButton: {
    marginRight: 15,
  },
});

interface PageHeaderProps {
  title: string;
  description?: string;
  gutterBottom?: boolean;
  backUrl?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description, gutterBottom = true, backUrl }) => {
  const classes = useStyles({ gutterBottom });

  return (
    <Grid className={classes.header} item>
      {backUrl && (
        <IconButton className={classes.backButton} size="small" onClick={() => history.push(backUrl)}>
          <ArrowBack />
        </IconButton>
      )}
      <div>
        <Typography variant="h6">{title}</Typography>
        {description && (
          <Typography color="textSecondary" variant="body2">
            {description}
          </Typography>
        )}
      </div>
    </Grid>
  );
};

export default PageHeader;
