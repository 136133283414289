import * as yup from 'yup';
import { cnpjValidator } from 'helpers/cnpjValidator';
import { cpfValidator } from 'helpers/cpfValidator';
import { Dispatch, SetStateAction, useState } from 'react';
import { User } from 'types/user';

export interface CustomerValidation {
  name?: string;
  cpf?: string;
  phone?: number;
  email?: string;
}

type UseCustomerValidation = [
  CustomerValidation,
  Dispatch<SetStateAction<CustomerValidation>>,
  (customer: User) => Promise<void>,
];

export function useCustomerValidation(): UseCustomerValidation {
  async function handleValidation(customer: User) {
    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').required('O e-mail de contato é obrigatório'),
      phone: yup.string().required('O telefone é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
      cpf: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .required(`CNPJ ou CPF é obrigatório`)
        .test('docValidation', `documento inválido`, value => {
          if (!value) return false;

          const cnpj = cnpjValidator(value);
          const cpf = cpfValidator(value);

          return cnpj || cpf;
        }),
    });

    try {
      await schema.validate(customer);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setCustomerInfoValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [customerInfoValidation, setCustomerInfoValidation] = useState<CustomerValidation>({});
  return [customerInfoValidation, setCustomerInfoValidation, handleValidation];
}
