import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import DaysWeekItemModule from './DaysWeekItemModule';
import { DaysWeek } from 'types/daysWeek';

type DaysWeekListModuleProps = {
  daysWeeks: DaysWeek[];
};

const DaysWeekListModule: React.FC<DaysWeekListModuleProps> = ({ daysWeeks }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {daysWeeks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(daysWeek => (
            <DaysWeekItemModule key={daysWeek.id} daysWeek={daysWeek} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default DaysWeekListModule;
