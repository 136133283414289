import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { DaysWeek } from 'types/daysWeek';

type DaysWeeksContextValue = {
  selectedDaysWeek: DaysWeek | null;
  setSelectedDaysWeek(daysWeek: DaysWeek | null): void;
  setDaysWeek: Dispatch<SetStateAction<DaysWeek[]>>;
};

const DaysWeeksContext = createContext<DaysWeeksContextValue>({} as DaysWeeksContextValue);
export const DaysWeeksProvider = DaysWeeksContext.Provider;

export function useDaysWeeks(): DaysWeeksContextValue {
  const context = useContext(DaysWeeksContext);
  return context;
}
