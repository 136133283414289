import { makeStyles, Typography } from '@material-ui/core';
import { BoxReport } from 'components/chart/BoxReport';
import React from 'react';

interface CustomerChartProps {
  customerLength: number;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const CustomerChart: React.FC<CustomerChartProps> = ({ customerLength }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <BoxReport title="Qtd de Clientes">
        <Typography variant="body2">Total de clientes: {customerLength}</Typography>
      </BoxReport>
    </div>
  );
};
