import React from 'react';
import UserItem from './AccessRuleItem';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { AccessRule } from 'types/accessRule';

type AccessRulesListProps = {
  accessRules: AccessRule[];
};

const UserList: React.FC<AccessRulesListProps> = ({ accessRules }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {accessRules.map(rule => (
            <UserItem key={rule.id} accessRule={rule} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default UserList;
