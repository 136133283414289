import React from 'react';
import { Collapse } from '@material-ui/core';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { useSidebar } from '../hook/useSidebar';
import { Event, FiberNew, Person, Star } from '@material-ui/icons';

const Registration: React.FC = () => {
  const { handleCollapseClick, collapsible } = useSidebar();
  return (
    <>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('registration')}
        icon={<FiberNew />}
        text="Cadastro"
        opened={collapsible.registration}
      />
      <Collapse in={collapsible.registration}>
        <SidebarItem to="/day-use" icon={<Event />} text="Day Use" nested={4} />
        <SidebarItem to="/customers" icon={<Person />} text="Clientes" nested={4} />
        <SidebarItem to="/associates" icon={<Star />} text="Associados" nested={4} />
      </Collapse>
    </>
  );
};

export default Registration;
