import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import { Affiliate } from 'types/affiliate';
import AssociateItemModule from './AssociateItemModule';

type AssociateListModuleProps = {
  affiliates: Affiliate[];
};

const AssociateListModule: React.FC<AssociateListModuleProps> = ({ affiliates }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {affiliates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(affiliate => (
            <AssociateItemModule key={affiliate.id} affiliate={affiliate} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default AssociateListModule;
