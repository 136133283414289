import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Invoice } from 'types/invoice';
import { BoxReport } from 'components/chart/BoxReport';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface InvoiceChartProps {
  invoices: Invoice[];
}

export const InvoiceChart: React.FC<InvoiceChartProps> = ({ invoices }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BoxReport title="Qtd de faturas pagas">
        <Typography variant="body2">Total de faturas pagas: {invoices.length}</Typography>
      </BoxReport>
    </div>
  );
};
