import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { DayUseInvoice } from 'types/dayUseInvoice';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type DayUseInvoiceItemModuleProps = {
  dayUseInvoice: DayUseInvoice;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const DayUseInvoiceItemModule: React.FC<DayUseInvoiceItemModuleProps> = ({ dayUseInvoice }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography gutterBottom variant="caption" color="primary">
        Fatura {dayUseInvoice.id}
      </Typography>
      <Typography>{dayUseInvoice.user.name}</Typography>
      <Typography gutterBottom variant="body2" color="textSecondary">
        {format(parseISO(dayUseInvoice.day_use.day), 'P', { locale: ptBR })}
      </Typography>
    </ListItem>
  );
};

export default DayUseInvoiceItemModule;
