import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    color: theme.palette.primary.contrastText,
  },
  listItemText: {
    color: '#fff',
    fontSize: 14,
  },
  expandColor: {
    color: '#fff',
  },
  nested: (props: { nested: number }) => ({
    paddingLeft: props.nested ? theme.spacing(props.nested) : 16,
    borderLeft: '2px solid transparent',
    minHeight: 50,
  }),
}));

interface SidebarItemProps {
  text: string;
  icon: React.ReactElement;
  opened: boolean;
  onClick: () => void;
  nested?: number;
}

const SidebarItemExpendable: React.FC<SidebarItemProps> = ({ icon, text, opened, onClick, nested = 0 }) => {
  const classes = useStyles({ nested });

  return (
    <ListItem onClick={onClick} button className={classes.nested}>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: classes.listItemText }} primary={text} />
      {opened ? <ExpandLess className={classes.expandColor} /> : <ExpandMore className={classes.expandColor} />}
    </ListItem>
  );
};

export default SidebarItemExpendable;
