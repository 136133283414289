import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Delete } from '@material-ui/icons';

type UserActionsProps = {
  handleSubmit(): void;
  handleDelete(): void;
};

const UserActions: React.FC<UserActionsProps> = ({ handleSubmit, handleDelete }) => {
  return (
    <>
      <Tooltip title="Excluir usuário">
        <IconButton color="inherit" onClick={handleDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default UserActions;
