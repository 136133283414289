import React from 'react';
import { Typography } from '@material-ui/core';
import { accessRulesTableTemplate } from './accessRulesTableTemplate';
import { AccessRule } from 'types/accessRule';

type AccessRuleItemProps = {
  accessRule: AccessRule;
};

const AccessRuleItem: React.FC<AccessRuleItemProps> = ({ accessRule }) => {
  return (
    <>
      {accessRulesTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{accessRule[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default AccessRuleItem;
