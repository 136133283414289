import React, { useState } from 'react';
import { Typography, Avatar } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import SidebarItem from './SidebarItem';
import { useApp } from 'hooks/app';
import { SIDEBAR_WIDTH } from 'constants/constants';
import { useSelector } from 'store/selector';
import { Dashboard, People, Settings } from '@material-ui/icons';
import { SidebarProvider } from './hook/useSidebar';
import Financial from './collapsible/Financial';
import Site from './collapsible/Site';
import Registration from './collapsible/Registration';
import BasicRegistration from './collapsible/BasicRegistration';
import SidebarItemMore from './SidebarItemMore';
import UserControlSidebar from './user/UserControlSidebar';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    backgroundColor: '#272c3a',
    '@media print': {
      display: 'none',
    },
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  avatar: {
    width: 40,
    height: 40,
    border: `2px solid ${theme.palette.secondary.light}`,
  },
  logout: {
    position: 'absolute',
    bottom: 0,
  },
  account: {
    minHeight: 90,
    marginTop: 30,
  },
  site: {
    padding: 10,
    border: '2px solid white',
    color: 'white',
    textAlign: 'center',
  },
}));

export type Collapsible = {
  settings: boolean;
  registration: boolean;
  trade: boolean;
  basicRegistration: boolean;
  financial: boolean;
  customers: boolean;
  updatedCustomers: boolean;
  charge: boolean;
  creditAnalysis: boolean;
  site: boolean;
};

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const app = useApp();
  const [collapsible, setCollapsible] = useState<Collapsible>({
    settings: false,
    basicRegistration: false,
    registration: false,
    trade: false,
    financial: false,
    customers: false,
    charge: false,
    updatedCustomers: false,
    creditAnalysis: false,
    site: false,
  });
  const [shownUserControl, setShownUserControl] = useState(false);

  function handleCollapseClick(index: keyof Collapsible, closeOthers = true): void {
    if (closeOthers) {
      const keys = Object.keys(collapsible);

      let otherValues: Collapsible = {} as Collapsible;
      keys.forEach(key => {
        otherValues = {
          ...otherValues,
          [key]: false,
        };
      });

      setCollapsible({
        ...otherValues,
        [index]: !collapsible[index],
      });
      return;
    }

    setCollapsible({
      ...collapsible,
      [index]: !collapsible[index],
    });
  }

  return (
    <Drawer
      variant={app.isMobile || app.windowWidth < 1280 ? 'temporary' : 'persistent'}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      open={app.isOpenedMenu}
      onClose={app.handleOpenMenu}
    >
      <UserControlSidebar show={shownUserControl} handleClose={() => setShownUserControl(false)} />

      <SidebarProvider value={{ handleCollapseClick, collapsible }}>
        <div>
          <div className={classes.drawerHeader}>
            <Typography variant="h6">Arena</Typography>
          </div>
          <SidebarItem to="/dashboard" icon={<Dashboard />} text="Dashboard" />

          <BasicRegistration />

          <Registration />

          <Financial />

          <Site />

          <SidebarItemMore
            handleClick={() => setShownUserControl(true)}
            text="Controle de usuários"
            icon={<People color="inherit" />}
          />

          <SidebarItem to="/settings" icon={<Settings />} text="Configurações" />
        </div>

        <SidebarItem
          className={classes.account}
          to="/account"
          icon={
            user ? (
              <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
            ) : (
              <Avatar className={classes.avatar}>U</Avatar>
            )
          }
          text={user ? user.name : 'Carregando...'}
        />
      </SidebarProvider>
    </Drawer>
  );
};

export default Sidebar;
