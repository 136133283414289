import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { User } from 'types/user';
import { useCustomers } from 'pages/customers/hooks/useCustomers';
import { MoreHoriz } from '@material-ui/icons';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type CustomerItemTableProps = {
  customer: User;
};

const CustomerItemTable: React.FC<CustomerItemTableProps> = ({ customer }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedCustomer } = useCustomers();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedCustomer(customer);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{customer[item.id as keyof User]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default CustomerItemTable;
