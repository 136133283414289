import React, { ChangeEvent, MouseEvent } from 'react';
import { Checkbox, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { AffiliationInvoice } from 'types/affiliationInvoice';
import { useAffiliationInvoice } from 'pages/affiliation-invoices/hooks/useAffiliationInvoices';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type AffiliationInvoiceItemTableProps = {
  affiliationInvoice: AffiliationInvoice;
};

const AffiliationInvoiceItemTable: React.FC<AffiliationInvoiceItemTableProps> = ({ affiliationInvoice }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedAffiliationInvoice, handleSelectInvoiceInGrid } = useAffiliationInvoice();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedAffiliationInvoice(affiliationInvoice);
  }

  function handleCheckboxClick(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    handleSelectInvoiceInGrid(affiliationInvoice.id);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'checkbox' ? (
            <div>
              <Checkbox
                checked={affiliationInvoice.selectedInGrid}
                onChange={event => handleCheckboxClick(event)}
                onClick={e => e.stopPropagation()}
              />
            </div>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{affiliationInvoice[item.id as keyof AffiliationInvoice]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default AffiliationInvoiceItemTable;
