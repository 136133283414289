import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { QueryParams } from './DayUse';
const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    // alignItems: 'center',
    gridTemplateColumns: '220px 1fr 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      // alignItems: 'baseline',
      gridTemplateColumns: '200px 200px 200px',
      columnGap: 10,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

type DayUseFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const DayUseFilterBox: React.FC<DayUseFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
    // inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, []);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <div className="search-params">
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.initial_date}
            onChange={date => handleQueryParamsChange('initial_date', date)}
            maxDate={queryParams.final_date}
            autoOk
          />
          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.final_date}
            onChange={date => handleQueryParamsChange('final_date', date)}
            minDate={queryParams.initial_date}
            autoOk
          />
        </div>
      </div>

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default DayUseFilterBox;
