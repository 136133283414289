import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import history from 'services/history';
import BannerActions from './BannerActions';
import BannerForm from '../BannerForm';
import { useBannerValidation } from '../validation/bannerValidation';
import { Banner } from 'types/banner';

const BannerUpdate: React.FC = () => {
  const [banner, setBanner] = useState<Banner>({} as Banner);
  const [validation, setValidation, validate] = useBannerValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/banners/${id}`)
      .then(response => {
        setBanner(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleChange(index: keyof Banner, value: any) {
    setBanner(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});
    validate(banner)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/banners/${id}`, { ...banner, image_id: banner.image?.id })
      .then(() => {
        setSaving(false);
        history.push('/banners');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/banners/${id}`)
      .then(() => {
        setSaving(false);
        history.push('/banners');
        handleOpen('Excluído');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Site"
        ActionsComponent={
          <BannerActions
            saving={saving}
            loading={loading}
            handleSubmit={handleValidation}
            handleDelete={handleDelete}
          />
        }
        backAction={() => history.push('/site/banners')}
      />
      <PageHeader title="Editar banner" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <BannerForm handleChange={handleChange} banner={banner} validation={validation} />
          </form>
        </Grid>
      )}
    </>
  );
};

export default BannerUpdate;
