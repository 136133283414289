import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Invoice } from 'types/invoice';
import ReactExport from 'react-export-excel';
import Dialog from 'components/dialogs/Dialog';
import ExcelButtonDownload from 'components/button-download/ExcelButtonDownload';

interface InvoicesExportProps {
  data: Invoice[];
  onExited(): void;
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
});

export const InvoicesExport: React.FC<InvoicesExportProps> = ({ data, onExited }) => {
  const classes = useStyles();
  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <div className={classes.container}>
        <Typography>Sua planilha está pronta.</Typography>

        <ExcelFile element={<ExcelButtonDownload />}>
          <ExcelSheet data={data} name="Faturas">
            <ExcelColumn label="Cliente" value="formattedName" />
            <ExcelColumn label="Descrição" value="description" />
            <ExcelColumn label="Valor" value="formattedValue" />
            <ExcelColumn label="Data de vencimento" value="formattedPayday" />
            <ExcelColumn label="Pago em" value="formattedPaidAt" />
            <ExcelColumn label="Cpf" value="formattedCpf" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    </Dialog>
  );
};
