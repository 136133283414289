import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { Court } from 'types/court';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type CourtItemModuleProps = {
  court: Court;
};

const CourtItemModule: React.FC<CourtItemModuleProps> = ({ court }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/court/${court.id}`)}>
      <Typography variant="caption" color="primary">
        ID {court.id}
      </Typography>
      <Typography gutterBottom>{court.description}</Typography>
      <Typography variant="body2" color="textSecondary">
        Ativo - {court.formattedActive}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Arena ID - {court.arena_description}
      </Typography>
    </ListItem>
  );
};

export default CourtItemModule;
