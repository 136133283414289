import { createContext, useContext } from 'react';
import { Activity } from 'types/activity';

interface CustomerActivityContextValue {
  handleValidation(): void;
  handleChange(index: keyof Activity, value: any): void;
  activity: Activity | null;
}

const CustomerActivityContext = createContext<CustomerActivityContextValue>({} as CustomerActivityContextValue);
export const CustomerActivityProvider = CustomerActivityContext.Provider;
export const CustomerActivityConsumer = CustomerActivityContext.Consumer;

export function useCustomerActivity(): CustomerActivityContextValue {
  const context = useContext(CustomerActivityContext);
  return context;
}
