import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import history from 'services/history';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const SiteBannersActions: React.FC = () => {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} color="inherit" onClick={() => history.push('/slider-banner')}>
      <AddIcon />
    </IconButton>
  );
};

export default SiteBannersActions;
