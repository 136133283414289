import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { DaysWeekValidation } from '../validation/DaysWeekValidation';
import { DaysWeek } from 'types/daysWeek';
import { Search } from '@material-ui/icons';
import ArenasSelector from '../arena-selector/ArenasSelector';
import CourtsSelector from '../court-selector/CourtsSelector';

const styles = makeStyles({
  formControlLabel: {
    marginTop: 30,
  },
});

type DaysWeekFormProps = {
  daysWeek: DaysWeek;
  handleChange(index: keyof DaysWeek, value: any): void;
  validation: DaysWeekValidation;
};

const DaysWeekForm: React.FC<DaysWeekFormProps> = ({ daysWeek, handleChange, validation }) => {
  const classes = styles();
  const [arenaSelector, setArenaSelector] = useState(false);
  const [courtSelector, setCourtSelector] = useState(false);
  const inputs = {
    active: useRef<HTMLSelectElement>(null),
    arena_id: useRef<HTMLInputElement>(null),
    block_id: useRef<HTMLInputElement>(null),
    week_day: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Grid item xs={12} xl={4} lg={4} md={6}>
      {arenaSelector && <ArenasSelector onExited={() => setArenaSelector(false)} />}
      {courtSelector && <CourtsSelector onExited={() => setCourtSelector(false)} />}

      <TextField
        inputRef={inputs.arena_id}
        error={!!validation.arena_id}
        helperText={validation.arena_id}
        label="Arena"
        placeholder="Selecione uma arena"
        margin="normal"
        fullWidth
        value={daysWeek.arena?.description || ''}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setArenaSelector(true)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        inputRef={inputs.block_id}
        error={!!validation.block_id}
        helperText={validation.block_id}
        label="Quadra"
        placeholder="Selecione uma quadra"
        margin="normal"
        fullWidth
        value={daysWeek.block?.description || ''}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setCourtSelector(true)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        inputRef={inputs.week_day}
        error={!!validation.week_day}
        helperText={validation.week_day}
        label="Dia da semana"
        placeholder="Dia da semana"
        value={daysWeek.week_day}
        onChange={e => handleChange('week_day', e.target.value)}
        fullWidth
        margin="normal"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            checked={daysWeek.active}
            onChange={e => handleChange('active', e.target.checked)}
            color="primary"
          />
        }
        label="Ativo"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Grid>
  );
};

export default DaysWeekForm;
