import React from 'react';
import { Tab, Tabs } from '@material-ui/core';

export type ArenaTabValues = 'arena' | 'plans';

interface ArenaTabsProps {
  onChange(tabValue: ArenaTabValues): void;
  tab: ArenaTabValues;
}

const ArenaTabs: React.FC<ArenaTabsProps> = ({ onChange, tab }) => {
  return (
    <Tabs value={tab} onChange={(e, tabValue) => onChange(tabValue)} variant="scrollable">
      <Tab value="arena" label="Arena" />
      <Tab value="plans" label="Planos" />
    </Tabs>
  );
};

export default ArenaTabs;
