import { Dispatch, SetStateAction, useState } from 'react';
import { Banner } from 'types/banner';
import * as yup from 'yup';

export type BannerValidation = {
  name?: string;
  image?: string;
  link?: string;
};

type UseBannerValidation = [
  BannerValidation,
  Dispatch<SetStateAction<BannerValidation>>,
  (banner: Banner) => Promise<void>,
];

export function useBannerValidation(): UseBannerValidation {
  async function handleValidation(banner: Banner) {
    const schema = yup.object().shape({
      image: yup.object().required('O banner é obrigatório'),
      link: yup.string().required('O link é obrigatório'),
      name: yup.string().required('O nome do banner é obrigatória'),
    });

    try {
      await schema.validate(banner);
    } catch (err) {
      setValidation({
        [err.path]: err.message,
      });
      throw new Error(err.message);
    }
  }

  const [validation, setValidation] = useState<BannerValidation>({});
  return [validation, setValidation, handleValidation];
}
