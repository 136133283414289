import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import history from 'services/history';
import { ArenasProvider } from './hooks/useArenas';
import { Arena } from 'types/arena';
import { arenaTableTemplate } from './ArenaTableTemplate';
import ArenaFilterBox from './ArenaFilterBox';
import ArenaListTable from './list/table/ArenaListTable';
import ArenaListModule from './list/module/ArenaListModule';
import ArenaActions from './ArenaActions';
import { formatPhoneNumber } from 'helpers/phoneNumberFormatter';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Arenas: React.FC = () => {
  const classes = useStyles();
  const [arena, setArena] = useState<Arena[]>([]);
  const [selectedArena, setSelectedArena] = useState<null | Arena>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Arena[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    api
      .get<Arena[]>('/arenas')
      .then(response => {
        setArena(
          response.data.map(item => {
            item.formattedActive = item.active ? 'Sim' : 'Não';
            item.formattedPhone = formatPhoneNumber(item.whatsapp);

            return item;
          }),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    const f = search(searchValue, 'description', arena);
    setFiltered(f);
  }, [searchValue, search, arena]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <ArenasProvider value={{ selectedArena, setSelectedArena, setArena }}>
      <Appbar title="Arenas" ActionsComponent={<ArenaActions />} />
      <PageHeaderActions
        title="Arenas"
        description="Arenas da Arena H2i"
        ActionComponent={
          <Button size="small" variant="contained" color="primary" onClick={() => history.push('/arena/new')}>
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={arenaTableTemplate}>
        <ArenaFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch="description"
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma arena para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ArenaListTable arenas={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <ArenaListModule arenas={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </ArenasProvider>
  );
};

export default Arenas;
