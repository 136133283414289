import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import history from 'services/history';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { OrderIndexData } from 'hooks/tableOrder';
import { usePagination } from 'hooks/pagination';
import { accessRulesTableTemplate } from './accessRulesTableTemplate';
import AccessRuleItem from './AccessRuleItem';
import { AccessRule } from 'types/accessRule';
import TableContent from 'components/table/TableContent';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type AccessRulesListProps = {
  accessRules: AccessRule[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const AccessRulesList: React.FC<AccessRulesListProps> = ({ accessRules, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { page, rowsPerPage } = usePagination();

  function handleClick(id?: number) {
    history.push(`/access-rule/${id}`);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer tableTemplate={accessRulesTableTemplate}>
          <TableContent>
            <TableHeader>
              {accessRulesTableTemplate.map(item => (
                <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                  <Typography variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId &&
                    (orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    ))}
                </div>
              ))}
            </TableHeader>
            {accessRules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(accessRule => (
              <TableRow onClick={() => handleClick(accessRule.id)} key={accessRule.id}>
                <AccessRuleItem accessRule={accessRule} />
              </TableRow>
            ))}
          </TableContent>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AccessRulesList;
