import React, { useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core';
import { Arena } from 'types/arena';
import { ArenaValidation } from '../validation/ArenaValidation';
import PhoneInput from 'components/masked-input/PhoneInput';

const styles = makeStyles({
  formControlLabel: {
    marginTop: 30,
  },
});

type ArenaFormProps = {
  arena: Arena;
  handleChange(index: keyof Arena, value: any): void;
  validation: ArenaValidation;
};

const ArenaForm: React.FC<ArenaFormProps> = ({ arena, handleChange, validation }) => {
  const classes = styles();
  const inputs = {
    whatsapp: useRef<HTMLInputElement>(null),
    contact: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Grid item xs={12} xl={4} lg={4} md={6}>
      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Nome da Arena"
        placeholder="Nome da Arena"
        value={arena.description}
        onChange={e => handleChange('description', e.target.value)}
        fullWidth
        margin="normal"
      />

      <TextField
        inputRef={inputs.contact}
        error={!!validation.contact}
        helperText={validation.contact}
        label="Nome do Contato"
        placeholder="Nome do Contato"
        value={arena.contact}
        onChange={e => handleChange('contact', e.target.value)}
        fullWidth
        margin="normal"
      />

      <TextField
        inputRef={inputs.whatsapp}
        error={!!validation.whatsapp}
        helperText={validation.whatsapp}
        label="WhatsApp do Contato"
        placeholder="WhatsApp do Contato"
        value={arena.whatsapp}
        onChange={e => handleChange('whatsapp', e.target.value)}
        fullWidth
        margin="normal"
        type="phone"
        autoComplete="phone"
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
        inputMode="numeric"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox checked={arena.active} onChange={e => handleChange('active', e.target.checked)} color="primary" />
        }
        label="Ativo"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Grid>
  );
};

export default ArenaForm;
