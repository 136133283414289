import { Button, makeStyles } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import React, { useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import history from 'services/history';
import BannersFilterBox from './BannersFilterBox';
import { bannersTableTemplate } from './bannersTableTemplate';
import { BannersProvider } from './hooks/useBanners';
import BannerListModule from './list/module/BannerListModule';
import BannerListTable from './list/table/BannerListTable';
import BannersActions from './BannersActions';
import { Banner } from 'types/banner';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Banners: React.FC = () => {
  const classes = useStyles();
  const [banners, setBanners] = useState<Banner[]>([]);
  const [selectedBanner, setSelectedBanner] = useState<null | Banner>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Banner[]>([]);
  const [indexToSearch, setIndexToSearch] = useState('name');
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    const f = search(searchValue, 'name', banners);
    setFiltered(f);
  }, [banners, searchValue, search]);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get<Banner[]>('/banners', { cancelToken: source.token })
      .then(response => {
        if (request)
          setBanners(
            response.data.map(item => {
              item.formattedCreatedAt = format(parseISO(item.created_at), 'PPp', { locale: ptBR });
              item.formattedActive = item.active ? 'Sim' : 'Não';
              return item;
            }),
          );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });

    return () => {
      if (request) request = false;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <BannersProvider value={{ selectedBanner, setSelectedBanner }}>
      <Appbar title="Site" ActionsComponent={<BannersActions />} />
      <PageHeaderActions
        title="Banners"
        description="Gestão dos banners para site H2i"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => history.push('/banner')}>
              Adicionar
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={bannersTableTemplate}>
        <BannersFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch={indexToSearch}
          setIndexToSearch={setIndexToSearch}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum banner para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <BannerListTable banners={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <BannerListModule banners={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </BannersProvider>
  );
};

export default Banners;
