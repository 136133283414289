import React, { useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import { Page } from 'types/page';
import PageNewActions from './PageNewActions';
import PageForm from '../PageForm';
import history from 'services/history';

const PageNew: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const [page, setPage] = useState<Page>({
    id: 0,
    created_at: '',
    title: '',
    description: '',
    html: '',
    page_id: '',
    keywords: '',
    url: '',
  });

  function handleSubmit() {
    setSaving(true);

    api
      .post('/pages', page)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/pages');
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : err.message);
      })
      .finally(() => setSaving(false));
  }

  function handleChange(index: string, value: string) {
    setPage(state => ({
      ...state,
      [index as keyof typeof state]: value,
    }));
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar title="Site" ActionsComponent={<PageNewActions handleSubmit={handleSubmit} />} />
      <PageHeader title="Página sobre" description="Gestão do conteúdo do página sobre" />

      <form onSubmit={handleSubmit}>
        <PageForm page={page} handleChange={handleChange} />
        <button type="submit" style={{ display: 'none' }} />
      </form>
    </>
  );
};

export default PageNew;
