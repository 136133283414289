import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { Court } from 'types/court';
import CourtSelectorItem from './CourtSelectorItem';

const styles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
});

interface CourtsSelectorListProps {
  courts: Court[];
}

const CourtsSelectorList: React.FC<CourtsSelectorListProps> = ({ courts }) => {
  const classes = styles();

  return (
    <List className={classes.list}>
      {courts.map(court => (
        <CourtSelectorItem court={court} key={court.id} />
      ))}
    </List>
  );
};

export default CourtsSelectorList;
