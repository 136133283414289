import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { User } from 'types/user';

export type UserValidation = {
  name?: string;
  email?: string;
  phone?: string;
};

type UseUserValidation = [UserValidation, Dispatch<SetStateAction<UserValidation>>, (user: User) => Promise<void>];

export function useUserValidation(): UseUserValidation {
  async function handleValidation(user: User) {
    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      email: yup.string().required('O e-mail é obrigatório'),
      phone: yup.string().required('O telefone é obrigatório'),
    });

    try {
      await schema.validate(user);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<UserValidation>({});
  return [validation, setValidation, handleValidation];
}
