import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PlanActions from './DayUseInvoicesActions';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'hooks/app';
import { api } from 'services/api';
import { DayUseInvoicesProvider } from './hooks/useDayUseInvoices';
import TableContainer from '../../components/table/TableContainer';
import { dayUseInvoicesTableTemplate } from './dayUseInvoicesTableTemplate';
import PlansFilterBox from './DayUseInvoicesFilterBox';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import DayUseInvoiceListTable from './list/table/DayUseInvoiceListTable';
import DayUseInvoiceListModule from './list/module/DayUseInvoiceListModule';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import { DayUseInvoice } from 'types/dayUseInvoice';
import Loading from 'components/loading/Loading';
import DayUseInvoicesData from './DayUseInvoicesData';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export interface QueryParams {
  initial_date: Date | null;
  final_date: Date | null;
  date_field: 'day_use' | 'created_at' | 'due_at';
  customer_name: string;
  only_conciliated: boolean;
}

const queryParamsInitialValue: QueryParams = {
  initial_date: subDays(new Date(), 30),
  final_date: new Date(),
  date_field: 'created_at',
  customer_name: '',
  only_conciliated: false,
};

let timer: NodeJS.Timeout;

const DayUseInvoices: React.FC = () => {
  const classes = useStyles();
  const [showData, setShowData] = useState(false);
  const [dayUseInvoices, setDayUseInvoices] = useState<DayUseInvoice[]>([]);
  const [selectedDayUseInvoice, setSelectedDayUseInvoice] = useState<null | DayUseInvoice>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<DayUseInvoice[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);
  const [selectAll, setSelectAll] = useState(false);
  const [saving, setSaving] = useState(false);

  const hasSelected = useMemo(() => filtered.some(invoice => invoice.selectedInGrid), [filtered]);

  useEffect(() => {
    setFiltered(state =>
      state.map(invoice => {
        invoice.selectedInGrid = selectAll;
        return invoice;
      }),
    );
  }, [selectAll]);

  const fetchInvoices = useCallback((query?: QueryParams, selected = false) => {
    setLoading(true);
    api
      .get<DayUseInvoice[]>('/dayUseInvoices', {
        params: { ...query, only_conciliated: query?.only_conciliated ? 1 : 0 },
      })
      .then(response => {
        setDayUseInvoices(
          response.data.map(dayUseInvoice => ({
            ...dayUseInvoice,
            formattedCreatedAt: format(parseISO(dayUseInvoice.created_at), 'PPp', { locale: ptBR }),
            formattedValue: moneyFormat(dayUseInvoice.value),
            formattedConciliatedAt: dayUseInvoice.conciliated_at
              ? format(parseISO(dayUseInvoice.conciliated_at), 'PPp', { locale: ptBR })
              : '-',
            formattedDueAt: dayUseInvoice.due_at
              ? format(parseISO(dayUseInvoice.due_at), 'PPp', { locale: ptBR })
              : '-',
            formattedCanceledAt: dayUseInvoice.canceled_at
              ? format(parseISO(dayUseInvoice.canceled_at), 'PPp', { locale: ptBR })
              : '-',
            formattedDayUse:
              typeof dayUseInvoice.day_use.day === 'string'
                ? format(parseISO(dayUseInvoice.day_use.day), 'PP', { locale: ptBR })
                : '-',
            selectedInGrid: selected,
          })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => fetchInvoices(queryParamsInitialValue), [fetchInvoices]);

  useEffect(() => {
    setFiltered(dayUseInvoices);
  }, [dayUseInvoices]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'customer_name') {
      fetchInvoices(query);
      return;
    }

    if (index === 'customer_name' && value.lenght < 4) {
      return;
    }

    timer = setTimeout(() => fetchInvoices(query), 500);
  }

  function handleSelectInvoiceInGrid(invoiceId: string) {
    setFiltered(state =>
      state.map(invoice => {
        invoice.selectedInGrid = invoice.id === invoiceId ? !invoice.selectedInGrid : invoice.selectedInGrid;
        return invoice;
      }),
    );
  }

  function handleConciliated() {
    const ids = filtered.filter(invoice => invoice.selectedInGrid).map(invoice => invoice.id);
    setSaving(true);

    api
      .post('/dayUseInvoices/updateConciliated', { ids })
      .then(() => {
        setFiltered(state =>
          state.map(invoice => {
            invoice.selectedInGrid = false;
            return invoice;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <DayUseInvoicesProvider
      value={{ selectedDayUseInvoice, setSelectedDayUseInvoice, setSelectAll, handleSelectInvoiceInGrid, selectAll }}
    >
      {saving && <Loading />}
      {showData && <DayUseInvoicesData data={dayUseInvoices} onExited={() => setShowData(false)} />}

      <Appbar title="Faturas Day Use" ActionsComponent={<PlanActions />} />
      <PageHeaderActions
        title="Faturas Day Use"
        description="Gestão das faturas de Day Use Arena H2i"
        ActionComponent={
          <Button size="small" color="primary" variant="contained" onClick={() => setShowData(true)}>
            Imprimir
          </Button>
        }
      />
      <TableContainer tableTemplate={dayUseInvoicesTableTemplate}>
        <PlansFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
          handleConciliated={handleConciliated}
          hasSelected={hasSelected}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum fatura de day use para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <DayUseInvoiceListTable dayUseInvoices={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <DayUseInvoiceListModule dayUseInvoices={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </DayUseInvoicesProvider>
  );
};

export default DayUseInvoices;
