import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Invoice } from 'types/invoice';

type InvoicesContextValue = {
  selectedInvoice: Invoice | null;
  setSelectedInvoice(invoice: Invoice | null): void;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  selectAll: boolean;
  handleSelectInvoiceInGrid(invoiceId: string): void;
};

const InvoicesContext = createContext<InvoicesContextValue>({} as InvoicesContextValue);
export const InvoicesProvider = InvoicesContext.Provider;

export function useInvoices(): InvoicesContextValue {
  const context = useContext(InvoicesContext);
  return context;
}
