import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { AccessRule } from 'types/accessRule';

type AccessRuleItemProps = {
  accessRule: AccessRule;
};

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
}));

const UserItem: React.FC<AccessRuleItemProps> = ({ accessRule }) => {
  const classes = useStyles();

  function handleClick(id?: number) {
    history.push(`/access-rule/${id}`);
  }

  return (
    <ListItem button className={classes.listItem} onClick={() => handleClick(accessRule.id)}>
      <Typography variant="h6">{accessRule.name}</Typography>
      <Typography variant="body1">{accessRule.title}</Typography>
      <Typography variant="caption" color="textSecondary">
        {accessRule.description}
      </Typography>
    </ListItem>
  );
};

export default UserItem;
