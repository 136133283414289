import React from 'react';
import { Collapse } from '@material-ui/core';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { useSidebar } from '../hook/useSidebar';
import { AddBox, CalendarToday, SportsSoccer, SportsTennis, Star } from '@material-ui/icons';

const BasicRegistration: React.FC = () => {
  const { handleCollapseClick, collapsible } = useSidebar();
  return (
    <>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('basicRegistration')}
        icon={<AddBox />}
        text="Cadastros Básicos"
        opened={collapsible.basicRegistration}
      />
      <Collapse in={collapsible.basicRegistration}>
        <SidebarItem to="/arenas" icon={<SportsTennis />} text="Arenas" nested={4} />
        <SidebarItem to="/customers-activity" icon={<Star />} text="Classificação de cliente" nested={4} />
        <SidebarItem to="/courts" icon={<SportsSoccer />} text="Quadras" nested={4} />
        <SidebarItem to="/daysweek" icon={<CalendarToday />} text="Dias da Semana" nested={4} />
      </Collapse>
    </>
  );
};

export default BasicRegistration;
