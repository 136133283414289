import Dialog from 'components/dialogs/Dialog';
import { useArena } from 'pages/arena/hooks/useArena';
import React, { useEffect, useState } from 'react';
import { ArenaPlan as ArenaPlanType } from 'types/arenaPlan';
import ArenaPlanActions from './ArenaPlanActions';
import ArenaPlanForm from './ArenaPlanForm';
import { useArenaPlanValidation } from './validation/arenaPlanValidation';

interface ArenaPlanProps {
  onExited(): void;
}

const ArenaPlan: React.FC<ArenaPlanProps> = ({ onExited }) => {
  const { selectedPlan, handleUpdatePlan, handleAddPlan } = useArena();
  const [plan, setPlan] = useState<ArenaPlanType>({} as ArenaPlanType);
  const [validation, setValidation, validate] = useArenaPlanValidation();
  const [actionType, setActionType] = useState<'update' | 'add'>('update');

  useEffect(() => {
    if (!selectedPlan) {
      setActionType('add');
      setPlan(state => ({
        ...state,
        id: new Date().getTime(),
        created_at: new Date().toISOString(),
        active: true,
      }));
      return;
    }

    setActionType('update');
    setPlan(JSON.parse(JSON.stringify(selectedPlan)));
  }, [selectedPlan]);

  function handleValidation() {
    setValidation({});

    validate(plan)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    if (actionType === 'add') {
      handleAddPlan(plan);
      return;
    }

    handleUpdatePlan(plan);
  }

  function handleChange(index: keyof ArenaPlanType, value: any) {
    setPlan(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <Dialog
      onExited={onExited}
      title="Plano para arena"
      ComponentActions={<ArenaPlanActions handleSubmit={handleValidation} />}
      maxWidth="sm"
      height="80vh"
    >
      <ArenaPlanForm validation={validation} handleChange={handleChange} plan={plan} />
    </Dialog>
  );
};

export default ArenaPlan;
