import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { Activity } from 'types/activity';
import history from 'services/history';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type CustomerActivityItemModuleProps = {
  activity: Activity;
};

const CustomerActivityItemModule: React.FC<CustomerActivityItemModuleProps> = ({ activity }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/customers-activity/${activity.id}`)}>
      <Typography variant="caption" color="primary">
        ID {activity.id}
      </Typography>
      <Typography gutterBottom>{activity.description}</Typography>
      <Typography variant="body2" color="textSecondary">
        % Fim de semana - {activity.value_holiday}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        % Dia de semana - {activity.value_week}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        % Desconto associado - {activity.value_associate}
      </Typography>
    </ListItem>
  );
};

export default CustomerActivityItemModule;
