import React from 'react';
import { Collapse } from '@material-ui/core';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { useSidebar } from '../hook/useSidebar';
import { AttachMoney, Receipt } from '@material-ui/icons';

const Financial: React.FC = () => {
  const { handleCollapseClick, collapsible } = useSidebar();
  return (
    <>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('financial')}
        icon={<AttachMoney />}
        text="Financeiro"
        opened={collapsible.financial}
      />
      <Collapse in={collapsible.financial}>
        <SidebarItem to="/invoices" icon={<Receipt />} text="Faturas" nested={4} />
        <SidebarItem to="/day-use-invoices" icon={<Receipt />} text="Faturas Day Use" nested={4} />
        <SidebarItem to="/affiliation-invoices" icon={<Receipt />} text="Faturas Associados" nested={4} />
      </Collapse>
    </>
  );
};

export default Financial;
