import { TableTemplate } from 'types/tableTemplate';

export const customerActivityTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'description',
    description: 'Descrição',
    originalId: 'description',
    width: 400,
  },
  {
    id: 'value_week',
    description: '% Desconto Day Use Semana',
    originalId: 'value_week',
    width: 180,
  },
  {
    id: 'value_holiday',
    description: '% Desconto Day Use Finde',
    originalId: 'value_holiday',
    width: 180,
  },
  {
    id: 'value_associate',
    description: '% Desconto Associado',
    originalId: 'value_associate',
    width: 180,
  },
  {
    id: 'formattedActive',
    description: 'Ativo',
    originalId: 'formattedActive',
    width: 50,
  },
];
