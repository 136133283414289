import { makeStyles, Typography } from '@material-ui/core';
import { BoxReport } from 'components/chart/BoxReport';
import React from 'react';
import { DayUseInvoice } from 'types/dayUseInvoice';

interface DayUseInvoiceProps {
  invoicesDayUse: DayUseInvoice[];
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const DayUseInvoiceChart: React.FC<DayUseInvoiceProps> = ({ invoicesDayUse }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <BoxReport title="Qtd de faturas day use">
        <Typography variant="body2">Total de faturas day use: {invoicesDayUse.length}</Typography>
      </BoxReport>
    </div>
  );
};
