import React from 'react';
import { fade, ListItem, makeStyles, Typography } from '@material-ui/core';
import { useDialog } from 'components/dialogs/Dialog';
import { useDaysWeek } from '../hooks/useDaysWeek';
import { Court } from 'types/court';

const styles = makeStyles(theme => ({
  listItem: {
    justifyContent: 'space-between',
    boxShadow: '1px 1px 9px 1px #eee',
    '&.selected': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
  },
}));

interface CourtSelectorItemProps {
  court: Court;
}

const CourtSelectorItem: React.FC<CourtSelectorItemProps> = ({ court }) => {
  const classes = styles();
  const { handleClose } = useDialog();
  const { handleChange } = useDaysWeek();

  function handleClick() {
    handleChange('block', court);
    handleChange('block_id', court.id);
    handleClose();
  }

  return (
    <ListItem button className={classes.listItem} onClick={handleClick}>
      <div>
        <Typography variant="body2">{court.description}</Typography>
        <Typography variant="subtitle1">Arena - {court.arena.description}</Typography>
      </div>
    </ListItem>
  );
};

export default CourtSelectorItem;
