import React, { FormEvent, useCallback, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import { useCourtValidation } from 'pages/court/validation/CourtValidation';
import CourtForm from '../CourtForm';
import CourtActions from '../CourtActions';
import { CourtProvider } from 'pages/court/hooks/useCourt';
import { Court } from 'types/court';
import { Arena } from 'types/arena';

const INITIAL_STATE: Court = {
  description: '',
  active: true,
  arena: {} as Arena,
  arena_id: 0,
  id: 0,
};

const CourtNew: React.FC = () => {
  const [court, setCourt] = useState<Court>(INITIAL_STATE);
  const [validation, setValidation, validate] = useCourtValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof Court, value: any) => {
    setCourt(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(court)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/blocks`, court)
      .then(() => {
        setSaving(false);
        history.push('/courts');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.message ? 'Não foi possível criar a quadra' : '');
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <CourtProvider value={{ handleChange, handleValidation, court }}>
      {saving && <Loading />}
      <Appbar
        title="Quadras"
        ActionsComponent={<CourtActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/courts')}
      />
      <PageHeader title="Quadras" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <CourtForm handleChange={handleChange} court={court} validation={validation} />
        </form>
      </Grid>
    </CourtProvider>
  );
};

export default CourtNew;
