import { User } from 'types/user';
import { UserProfile } from 'types/userProfile';

export const USER_CHANGE = 'USER_CHANGE';
export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';

type UserChangeAction = {
  type: typeof USER_CHANGE;
  value: string;
  index: string;
};

type SetUserAction = {
  type: typeof SET_USER;
  user: User;
};

type SetUsetProfile = {
  type: typeof SET_PROFILE;
  profile: UserProfile;
};

export type UserActionsType = UserChangeAction | SetUserAction | SetUsetProfile;
