import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import SiteBannerItemModule from './SiteBannerItemModule';
import { SliderBanner } from 'types/sliderBanner';

type SiteBannerListModuleProps = {
  banners: SliderBanner[];
};

const SiteBannerListModule: React.FC<SiteBannerListModuleProps> = ({ banners }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {banners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(banner => (
            <SiteBannerItemModule key={banner.id} banner={banner} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default SiteBannerListModule;
