import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { DayUseInvoice } from 'types/dayUseInvoice';

type DayUseInvoiceContextValue = {
  selectedDayUseInvoice: DayUseInvoice | null;
  setSelectedDayUseInvoice: Dispatch<SetStateAction<DayUseInvoice | null>>;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  selectAll: boolean;
  handleSelectInvoiceInGrid(invoiceId: string): void;
};

const DayUseInvoiceContext = createContext<DayUseInvoiceContextValue>({} as DayUseInvoiceContextValue);
export const DayUseInvoicesProvider = DayUseInvoiceContext.Provider;

export function useDayUseInvoices(): DayUseInvoiceContextValue {
  const context = useContext(DayUseInvoiceContext);
  return context;
}
