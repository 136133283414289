import React from 'react';
import { ListItem, Typography, makeStyles } from '@material-ui/core';
import history from 'services/history';
import { UserProfile } from 'types/userProfile';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
  },
});

type ProfileItemProps = {
  profile: UserProfile;
};

const ProfileItem: React.FC<ProfileItemProps> = ({ profile }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/user-profile/${profile.id}`)}>
      <Typography variant="caption" color="primary">
        Perfil {profile.id}
      </Typography>
      <Typography variant="h6">{profile.name}</Typography>
    </ListItem>
  );
};

export default ProfileItem;
