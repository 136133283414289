import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#fff',
    borderRadius: '8px 8px 0 0',
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const FilterBox: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>{children}</div>
    </>
  );
};

export default FilterBox;
