import React, { FormEvent, useCallback, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import CustomerActivityForm from './CustomerActivityForm';
import { CustomerActivityProvider } from '../../hooks/useCustomerActivity';
import { Activity } from 'types/activity';
import ActivityActions from './ActivityActions';
import { useActivityValidation } from 'pages/customers-rating/validation/ActivityValidation';

const INITIAL_STATE: Activity = {
  description: '',
  active: true,
  value_holiday: 0,
  value_associate: 0,
  id: 0,
  value_week: 0,
};

const CustomerActivityNew: React.FC = () => {
  const [activity, setActivity] = useState<Activity>(INITIAL_STATE);
  const [validation, setValidation, validate] = useActivityValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof Activity, value: any) => {
    setActivity(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(activity)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/activities`, activity)
      .then(() => {
        setSaving(false);
        history.push('/customers-activity');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <CustomerActivityProvider value={{ handleChange, handleValidation, activity }}>
      {saving && <Loading />}
      <Appbar
        title="Classificação de clientes"
        ActionsComponent={<ActivityActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/customers-activity')}
      />
      <PageHeader title="Classificação de clientes" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <CustomerActivityForm handleChange={handleChange} activity={activity} validation={validation} />
        </form>
      </Grid>
    </CustomerActivityProvider>
  );
};

export default CustomerActivityNew;
