import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

interface BoxReportProps {
  title: string;
}

const useStyles = makeStyles({
  box: {
    width: '100%',
    height: '200px',
    padding: 15,
    border: '1px solid #e5e5e5cc',
    textAlign: 'center',
    borderRadius: 5,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75%',
  },
  header: {
    borderBottom: '1px solid #cfcfcf',
    paddingBottom: '5px',
  },
});

export const BoxReport: React.FC<BoxReportProps> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <div className={classes.header}>
        <Typography
          variant="button"
          style={{
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
