import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import { CustomersActivitiesProvider } from './hooks/useCustomersActivities';
import { customerActivityTableTemplate } from './customerActivityTableTemplate';
import CustomerActivityFilterBox from './CustomerActivityFilterBox';
import { Activity } from 'types/activity';
import CustomerActivityListTable from './list/table/CustomerActivityListTable';
import CustomerActivityListModule from './list/module/CustomerActivityListModule';
import history from 'services/history';
import CustomerActivityActions from './CustomerActivityActions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const CustomersActivity: React.FC = () => {
  const classes = useStyles();
  const [customersActivity, setCustomersActivity] = useState<Activity[]>([]);
  const [selectedCustomerActivity, setSelectedCustomerActivity] = useState<null | Activity>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Activity[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    api
      .get<Activity[]>('/activities')
      .then(response => {
        setCustomersActivity(
          response.data.map(activity => ({
            ...activity,
            formattedActive: activity.active ? 'Sim' : 'Não',
          })),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    const f = search(searchValue, 'description', customersActivity);
    setFiltered(f);
  }, [customersActivity, searchValue, search]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <CustomersActivitiesProvider
      value={{ selectedCustomerActivity, setSelectedCustomerActivity, setCustomersActivity }}
    >
      <Appbar title="Classificação de clientes" ActionsComponent={<CustomerActivityActions />} />
      <PageHeaderActions
        title="Classificação de clientes"
        description="Classificação de clientes da Arena H2i"
        ActionComponent={
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => history.push('/customers-activity/new')}
          >
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={customerActivityTableTemplate}>
        <CustomerActivityFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch="description"
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum cliente para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <CustomerActivityListTable activities={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <CustomerActivityListModule activities={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </CustomersActivitiesProvider>
  );
};

export default CustomersActivity;
