import { createContext, useContext } from 'react';
import { Activity } from 'types/activity';
import { User } from 'types/user';

interface CustomerContextValue {
  handleChange(index: keyof User, value: any): void;
  customer: User | null;
  activities: Activity[];
}

const CustomerContext = createContext<CustomerContextValue>({} as CustomerContextValue);
export const CustomerProvider = CustomerContext.Provider;
export const CustomerConsumer = CustomerContext.Consumer;

export function useCustomer(): CustomerContextValue {
  const context = useContext(CustomerContext);
  return context;
}
