import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import CourtItemModule from './CourtItemModule';
import { Court } from 'types/court';

type CourtListModuleProps = {
  courts: Court[];
};

const CourtListModule: React.FC<CourtListModuleProps> = ({ courts }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {courts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(court => (
            <CourtItemModule key={court.id} court={court} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CourtListModule;
