import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  listItemText: {
    fontSize: 14,
  },
  listItemIcon: {
    color: theme.palette.primary.contrastText,
  },
  nested: (props: { nested: number }) => ({
    paddingLeft: props.nested ? theme.spacing(props.nested) : 16,
    borderLeft: '2px solid transparent',
    minHeight: 50,
    color: theme.palette.primary.contrastText,
  }),
}));

interface SidebarItemMoreProps {
  text: string;
  icon: React.ReactElement;
  nested?: number;
  handleClick(): void;
}

const SidebarItemMore: React.FC<SidebarItemMoreProps> = ({ icon, text, handleClick, nested = 0 }) => {
  const classes = useStyles({ nested });

  return (
    <ListItem className={`${classes.nested}`} onClick={handleClick} button>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText color="inherit" classes={{ primary: classes.listItemText }} primary={text} />
      <ChevronRightIcon color="inherit" />
    </ListItem>
  );
};

export default SidebarItemMore;
