import React, { useReducer, FormEvent, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import UserActions from './UserActions';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import UserProfile from './UserProfile';
import { UserProfile as UserProfileType } from 'types/userProfile';
import UserDeleteConfirm from './UserDeleteConfirm';
import userReducer, { USER_INITIAL_STATE } from 'pages/users/reducer/reducer';
import { setUser, userChange } from 'pages/users/reducer/actions';
import UserTab from '../UserTab';
import UserForm from '../UserForm';
import { useUserValidation } from '../validation/userValidation';
import PageHeader from 'components/page-header/PageHeader';

export type UserValidation = {
  phone?: string;
  email?: string;
  name?: string;
};

const EditUser: React.FC = () => {
  const [user, dispatch] = useReducer(userReducer, USER_INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState(0);
  const { handleOpen } = useMessaging();
  const [userProfiles, setUserProfiles] = useState<UserProfileType[]>([]);
  const { id } = useParams<{ id: string }>();
  const [userDeleteConfirm, setUserDeleteConfirm] = useState(false);
  const [validation, setValidation, validate] = useUserValidation();

  useEffect(() => {
    if (!id) return;

    async function loadUserProfile() {
      const response = await api.get('/userProfiles');
      setUserProfiles(response.data);
    }

    async function loadUser() {
      const response = await api.get(`/adminUsers/${id}`);
      dispatch(setUser(response.data));
    }

    setLoading(true);

    Promise.all([loadUser(), loadUserProfile()])
      .catch(() => {
        handleOpen('Não foi possível carregar o usuário');
        history.push('/users');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleOpen, id]);

  function handleChange(index: string, value: any) {
    dispatch(userChange(index, value));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(user)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/adminUsers/${id}`, user)
      .then(() => {
        handleOpen('Salvo');
        setLoading(false);
        history.push('/users');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar o usuário');
      });
  }

  function handleDelete() {
    api
      .delete(`/adminUsers/${id}`)
      .then(() => {
        history.push('/users');
        handleOpen('Usuário excluído');
        setLoading(true);
      })
      .catch(err => {
        setLoading(false);
        handleOpen(err.response ? err.response.data.error : 'Não foi possível excluir o usuário');
      });
  }

  return (
    <>
      <Appbar
        backAction={() => history.push('/users')}
        title="Editar usuário"
        ActionsComponent={
          <UserActions handleSubmit={handleValidation} handleDelete={() => setUserDeleteConfirm(true)} />
        }
        Tab={<UserTab tab={tab} handleChange={value => setTab(value)} />}
      />
      {saving && <Loading />}
      {userDeleteConfirm && (
        <UserDeleteConfirm handleDelete={handleDelete} onExited={() => setUserDeleteConfirm(false)} />
      )}
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          {tab === 0 ? (
            <Grid item xs={12} lg={4} xl={4} md={6}>
              <PageHeader backUrl="/users" title="Novo usuário" />
              <form onSubmit={handleValidation}>
                <UserForm handleChange={handleChange} user={user} validation={validation} />
                <button type="submit" style={{ display: 'none' }} />
              </form>
            </Grid>
          ) : (
            <>
              <UserProfile user={user} handleChange={handleChange} userProfiles={userProfiles} />
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default EditUser;
