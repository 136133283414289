import { TableTemplate } from 'types/tableTemplate';

export const plansTableTemplate: TableTemplate[] = [
  {
    id: 'checkbox',
    description: 'CLIENTE',
    originalId: 'checkbox',
    width: 50,
    notFilterable: true,
  },
  /*   {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 50,
    notFilterable: true,
  }, */
  {
    id: 'customerName',
    description: 'CLIENTE',
    originalId: 'customerName',
    width: 300,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 450,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    originalId: 'value',
    width: 120,
  },
  {
    id: 'formattedInstallments',
    description: 'PARCELAS',
    originalId: 'installments',
    width: 100,
  },
  {
    id: 'formattedPayday',
    description: 'VENCIMENTO',
    originalId: 'formattedPayday',
    width: 100,
  },
  {
    id: 'formattedPaidAt',
    description: 'PAGO EM',
    originalId: 'formattedPaidAt',
    width: 120,
  },
  {
    id: 'formattedConciliatedAt',
    description: 'CONCILIADO EM',
    originalId: 'formattedConciliatedAt',
    width: 120,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 120,
  },
];
