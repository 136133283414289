import React, { FormEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from 'components/dialogs/Dialog';
import InsideSaving from 'components/loading/InsideSaving';
import { User } from 'types/user';
import { useCustomers } from '../hooks/useCustomers';
import { api } from 'services/api';
import CustomerActions from './SelectedCustomerActions';
import { useCustomerValidation } from '../validation/CustomerValidation';
import { CustomerProvider } from '../hooks/useCustomer';
import CustomerTabs, { SelectedCustomerTabValues } from './SelectedCustomerTabs';
import CustomerDayUses from './day-use/CustomerDayUseInvoices';
import CustomerData from './customer-data/CustomerData';
import { useFetchCustomerData } from './hooks/useFetchCustomerData';
import InsideLoading from 'components/loading/InsideLoading';
import CustomerInvoicesPaid from './invoices/CustomerInvoicesPaid';
import CustomerInvoicesNoPaid from './invoices/CustomerInvoicesNoPaid';

const styles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  messageSave: {
    textAlign: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    marginTop: '250px',
  },
  error: {
    textAlign: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  content: {
    marginTop: 20,
  },
});

interface CustomerSelectorProps {
  onExited(): void;
}

const CustomerSelector: React.FC<CustomerSelectorProps> = ({ onExited }) => {
  const classes = styles();
  const { selectedCustomer, handleUpdateCustomers } = useCustomers();
  const [customer, setCustomer] = useState<User | null>(JSON.parse(JSON.stringify(selectedCustomer)));
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [customerValidation, setCustomerValidation, validateCustomer] = useCustomerValidation();
  const [tab, setTab] = useState<SelectedCustomerTabValues>('data');
  const [dayUseInvoices, invoices, activities, loading] = useFetchCustomerData(selectedCustomer?.id);

  function handleChange(index: keyof User, value: any) {
    setCustomer(state => {
      if (!state) return null;
      return {
        ...state,
        [index]: value,
      };
    });
  }

  function handleValidation(handleCloseModal: () => void, e?: FormEvent<HTMLFormElement>) {
    if (!customer) return;

    e?.preventDefault();

    setCustomerValidation({});

    validateCustomer(customer)
      .then(() => handleSubmit(handleCloseModal))
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  function handleSubmit(handleCloseModal: () => void) {
    setError(false);
    setSaving(true);

    api
      .put(`/users/${selectedCustomer?.id}`, customer)
      .then(() => {
        if (customer) {
          handleUpdateCustomers(customer);
          handleCloseModal();
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setSaving(false));
  }

  function handleTabs() {
    const components = {
      data: <CustomerData validation={customerValidation} />,
      invoicePaid: <CustomerInvoicesPaid invoices={invoices} />,
      invoiceNoPaid: <CustomerInvoicesNoPaid invoices={invoices} />,
      day_use: <CustomerDayUses dayUseInvoices={dayUseInvoices} />,
    };

    return components[tab];
  }

  return (
    <CustomerProvider
      value={{
        handleChange,
        customer,
        activities,
      }}
    >
      <Dialog
        onExited={onExited}
        maxWidth="sm"
        title={`Clientes`}
        ComponentActions={<CustomerActions handleValidation={handleValidation} />}
      >
        <CustomerTabs tab={tab} handleTabChange={value => setTab(value)} />

        {saving && <InsideSaving />}

        {loading ? (
          <InsideLoading />
        ) : error ? (
          <div className={classes.error}>Existem campos obrigatórios</div>
        ) : (
          <div className={classes.content}>{handleTabs()}</div>
        )}
      </Dialog>
    </CustomerProvider>
  );
};

export default CustomerSelector;
