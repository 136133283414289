import React, { useEffect, useMemo, useRef } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { DayUse } from 'types/dayuse';
import { DayUseValidation } from './validation/dayUseValidation';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';

const styles = makeStyles({
  formControlLabel: {
    marginTop: 30,
  },
});

type DayUseFormProps = {
  dayUse: DayUse;
  handleChange(index: keyof DayUse, value: any): void;
  validation: DayUseValidation;
  type?: boolean;
};

const DayUseForm: React.FC<DayUseFormProps> = ({ dayUse, type, handleChange, validation }) => {
  const classes = styles();

  const inputs = {
    day: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
  };

  const day = useMemo(() => {
    if (!dayUse.day) {
      return dayUse.day;
    }

    if (typeof dayUse.day === 'string') {
      const date = parseISO(dayUse.day);
      return format(date, 'P');
    }
    return format(dayUse.day, 'P');
  }, [dayUse]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Grid item xs={12} xl={4} lg={4} md={6}>
      <KeyboardDatePicker
        inputRef={inputs.day}
        error={!!validation.day}
        helperText={validation.day}
        label={!dayUse.day && 'Data'}
        placeholder="Informe o day use"
        value={type ? day : dayUse.day}
        onChange={date => handleChange('day', date)}
        fullWidth
        margin="normal"
        autoOk
        minDate={new Date()}
      />

      <TextField
        inputRef={inputs.value}
        error={!!validation.value}
        helperText={validation.value}
        label="Valor (R$)"
        placeholder="Informe o valor da fatura"
        value={dayUse.value || ''}
        onChange={e => handleChange('value', e.target.value)}
        fullWidth
        inputMode="numeric"
        margin="normal"
        type="number"
        inputProps={{
          inputMode: 'decimal',
          step: '0.01',
        }}
      />

      <FormControl fullWidth>
        <InputLabel id="demo-controlled-open-select-label">Tipo dia</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={dayUse.type_day}
          label="Age"
          onChange={e => handleChange('type_day', e.target.value)}
        >
          <MenuItem value="weeked">Dia de semana</MenuItem>
          <MenuItem value="holiday">Final de semana</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox checked={dayUse.active} onChange={e => handleChange('active', e.target.checked)} color="primary" />
        }
        label="Inativo"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Grid>
  );
};

export default DayUseForm;
