import React from 'react';
import { Grid, TextField, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import UserProfileRules from '../../UserProfileRules';
import { UserProfile as UserProfileType } from 'types/userProfile';
import { User } from 'types/user';

type UserProfileProps = {
  userProfiles: UserProfileType[];
  user: User;
  handleChange(index: string, value: any): void;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rules: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'column',
    '& div': {
      marginTop: 10,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 7,
      },
    },
  },
});

const UserProfile: React.FC<UserProfileProps> = ({ user, handleChange, userProfiles }) => {
  const classes = useStyles();

  return userProfiles.length > 0 ? (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            O perfil determina as permissões de acesso aos módulos do sistema
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} md={6} xl={4}>
          <TextField
            fullWidth
            margin="normal"
            select
            label="Perfil"
            value={user.user_profile_id || ''}
            onChange={event => handleChange('user_profile_id', event.target.value)}
          >
            {userProfiles.map(profile => (
              <MenuItem key={profile.id} value={profile.id}>
                {profile.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <UserProfileRules userProfile={userProfiles.find(profile => profile.id === user.user_profile_id)} />
      </Grid>
    </>
  ) : (
    <div className={classes.container}>
      <Typography gutterBottom variant="h5" color="textSecondary">
        Não há perfis cadastrados
      </Typography>
      <Link to="/profile">Adicionar perfil</Link>
    </div>
  );
};

export default UserProfile;
