import React from 'react';
import { Switch, Router } from 'react-router-dom';
import history from 'services/history';
import PublicRoute from './PublicRoute';
import Login from 'pages/login/Login';
import PrivateRoute from './PrivateRoute';
import Dashboard from 'pages/dashboard/Dashboard';
import Forgot from 'pages/forgot/Forgot';
import PasswordReset from 'pages/passoword-reset/PasswordReset';
import Error404 from 'pages/error/Error404';
import Home from 'pages/home/Home';
import SliderBanners from 'pages/slider-banners/SliderBanners';
import SiteBannerNew from 'pages/slider-banners/registration/new/SiteBannerNew';
import SiteBannerUpdate from 'pages/slider-banners/registration/update/SiteBannerUpdate';
import NewsletterSubscribers from 'pages/newsletters/NewsletterSubscribers';
import Banners from 'pages/banners/Banners';
import BannerNew from 'pages/banners/registration/new/BannerNew';
import BannerUpdate from 'pages/banners/registration/update/BannerUpdate';
import Pages from 'pages/site-pages/Pages';
import PageNew from 'pages/site-pages/registration/new/PageNew';
import PageUpdate from 'pages/site-pages/registration/update/PageUpdate';
import SiteSettings from 'pages/settings/Settings';
import Invoices from 'pages/invoices/Invoices';
import NewInvoice from 'pages/invoices/registration/new/NewInvoice';
import EditInvoice from 'pages/invoices/registration/edit/EditInvoice';
import Account from 'pages/account/Account';
import DayUse from 'pages/day-use/DayUse';
import DayUseNew from 'pages/day-use/registration/new/DayUseNew';
import DayUseEdit from 'pages/day-use/registration/edit/DayUseEdit';
import Customers from 'pages/customers/Customers';
import DayUseInvoices from 'pages/day-use-invoices/DayUseInvoices';
import CustomersActivity from 'pages/customers-rating/CustomersActivity';
import CustomerActivityNew from 'pages/customers-rating/registration/new/CustomerActivityNew';
import CustomerActivityEdit from 'pages/customers-rating/registration/edit/CustomerActivityEdit';
import Associates from 'pages/associate/Associates';
import Arenas from 'pages/arena/Arenas';
import ArenaNew from 'pages/arena/registration/new/ArenaNew';
import ArenaEdit from 'pages/arena/registration/edit/ArenaEdit';
import Courts from 'pages/court/Courts';
import CourtEdit from 'pages/court/registration/edit/CourtEdit';
import CourtNew from 'pages/court/registration/new/CourtNew';
import DaysWeeks from 'pages/days-week/DaysWeeks';
import DaysWeekNew from 'pages/days-week/registration/new/DaysWeekNew';
import DaysWeekEdit from 'pages/days-week/registration/edit/DaysWeekEdit';
import AffiliationInvoices from 'pages/affiliation-invoices/AffiliationInvoices';
import Profiles from 'pages/user-profiles/Profiles';
import AccessRules from 'pages/access-rules/AccessRules';
import EditAccessRule from 'pages/access-rules/registration/edit/AccessRule';
import NewAccessRule from 'pages/access-rules/registration/new/AccessRule';
import NewProfile from 'pages/user-profiles/new/Profile';
import EditProfile from 'pages/user-profiles/edit/Profile';
import Users from 'pages/users/Users';
import EditUser from 'pages/users/registration/edit/EditUser';
import NewUser from 'pages/users/registration/new/NewUser';

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact component={Home} path="/" />
        <PrivateRoute exact component={Account} path="/account" />
        <PrivateRoute exact component={Dashboard} path="/dashboard" />
        <PublicRoute exact component={Login} path="/login" />
        <PublicRoute exact component={Forgot} path="/forgot" />
        <PublicRoute exact component={PasswordReset} path="/password-reset/:token" />

        <PrivateRoute exact component={SliderBanners} path="/slider-banners" />
        <PrivateRoute exact component={SiteBannerNew} path="/slider-banner" />
        <PrivateRoute exact component={SiteBannerUpdate} path="/slider-banners/:id" />
        <PrivateRoute exact component={NewsletterSubscribers} path="/newsletter-subscribers" />

        <PrivateRoute exact component={Banners} path="/banners" />
        <PrivateRoute exact component={BannerNew} path="/banner" />
        <PrivateRoute exact component={BannerUpdate} path="/banners/:id" />

        <PrivateRoute exact component={Pages} path="/pages" />
        <PrivateRoute exact component={PageNew} path="/page" />
        <PrivateRoute exact component={PageUpdate} path="/pages/:id" />
        <PrivateRoute exact component={SiteSettings} path="/settings" />

        <PrivateRoute exact component={Invoices} path="/invoices" />
        <PrivateRoute exact component={NewInvoice} path="/invoices/new" />
        <PrivateRoute exact component={EditInvoice} path="/invoices/:id" />

        <PrivateRoute exact component={DayUse} path="/day-use" />
        <PrivateRoute exact component={DayUseNew} path="/day-use/new" />
        <PrivateRoute exact component={DayUseEdit} path="/day-use/:id" />

        <PrivateRoute exact component={Customers} path="/customers" />

        <PrivateRoute exact component={DayUseInvoices} path="/day-use-invoices" />

        <PrivateRoute exact component={CustomersActivity} path="/customers-activity" />
        <PrivateRoute exact component={CustomerActivityNew} path="/customers-activity/new" />
        <PrivateRoute exact component={CustomerActivityEdit} path="/customers-activity/:id" />

        <PrivateRoute exact component={Associates} path="/associates" />

        <PrivateRoute exact component={Arenas} path="/arenas" />
        <PrivateRoute exact component={ArenaNew} path="/arena/new" />
        <PrivateRoute exact component={ArenaEdit} path="/arena/:id" />

        <PrivateRoute exact component={Courts} path="/courts" />
        <PrivateRoute exact component={CourtNew} path="/court/new" />
        <PrivateRoute exact component={CourtEdit} path="/court/:id" />

        <PrivateRoute exact component={DaysWeeks} path="/daysWeek" />
        <PrivateRoute exact component={DaysWeekNew} path="/daysWeek/new" />
        <PrivateRoute exact component={DaysWeekEdit} path="/daysWeek/:id" />

        <PrivateRoute exact component={AffiliationInvoices} path="/affiliation-invoices" />
        <PrivateRoute exact component={Profiles} path="/user-profiles" />
        <PrivateRoute exact component={NewProfile} path="/user-profile" />
        <PrivateRoute exact component={EditProfile} path="/user-profile/:id" />

        <PrivateRoute exact component={AccessRules} path="/access-rules" />
        <PrivateRoute exact component={EditAccessRule} path="/access-rule/:id" />
        <PrivateRoute exact component={NewAccessRule} path="/access-rule" />

        <PrivateRoute exact component={Users} path="/users" />
        <PrivateRoute exact component={NewUser} path="/users/new" />
        <PrivateRoute exact component={EditUser} path="/users/:id" />

        <PublicRoute exact component={PasswordReset} path="/password-reset" />

        <PublicRoute component={Error404} />
      </Switch>
    </Router>
  );
};

export default Routes;
