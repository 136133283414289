import React from 'react';
import { ListItem, makeStyles, Typography } from '@material-ui/core';
import { moneyFormat } from 'helpers/numberFormat';
import { DayUseInvoice } from 'types/dayUseInvoice';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type CustomerDayUseInvoiceItemProps = {
  dayUseInvoice: DayUseInvoice;
};

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gap: 5,
    padding: 5,
    borderLeft: '4px solid black',
    margin: '10px 0',
    borderRadius: 10,
  },
});

const CustomerDayUseInvoiceItem: React.FC<CustomerDayUseInvoiceItemProps> = ({ dayUseInvoice }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.list}>
      <Typography>Valor (R$) - {moneyFormat(dayUseInvoice.value)}</Typography>
      <Typography color="primary">
        Day use - {format(parseISO(dayUseInvoice.day_use.day), 'P', { locale: ptBR })}
      </Typography>
    </ListItem>
  );
};

export default CustomerDayUseInvoiceItem;
