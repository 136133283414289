import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { ArenaPlan } from 'types/arenaPlan';
import { useArena } from 'pages/arena/hooks/useArena';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type ArenaPlanItemModuleProps = {
  plan: ArenaPlan;
};

const ArenaPlanItemModule: React.FC<ArenaPlanItemModuleProps> = ({ plan }) => {
  const classes = useStyles();
  const { setSelectedPlan } = useArena();

  return (
    <ListItem button className={classes.listItem} onClick={() => setSelectedPlan(plan)}>
      <Typography gutterBottom>{plan.months_quantity}</Typography>
      <Typography variant="h6">{plan.formattedValue}</Typography>
    </ListItem>
  );
};

export default ArenaPlanItemModule;
