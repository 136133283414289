import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import history from 'services/history';
import PlanActions from './InvoicesActions';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'hooks/app';
import { api } from 'services/api';
import { InvoicesProvider } from './hooks/useInvoices';
import TableContainer from '../../components/table/TableContainer';
import { plansTableTemplate } from './invoicesTableTemplate';
import PlansFilterBox from './InvoicesFilterBox';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import { Invoice } from 'types/invoice';
import InvoiceListTable from './list/table/InvoiceListTable';
import InvoiceListModule from './list/module/BannerListModule';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import Loading from 'components/loading/Loading';
import InvoicesData from './InvoicesData';
import { cpfFormatter } from 'helpers/cpfFormatter';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

let timer: NodeJS.Timeout;

export interface QueryParams {
  initial_date: Date | null;
  final_date: Date | null;
  user_name: string;
  only_paid: boolean;
  only_conciliated: boolean;
  date_field: 'created_at' | 'payday' | 'paid_at';
}

const queryParamsInitialValue: QueryParams = {
  initial_date: subDays(new Date(), 30),
  final_date: new Date(),
  user_name: '',
  only_paid: false,
  date_field: 'created_at',
  only_conciliated: false,
};

const Invoices: React.FC = () => {
  const classes = useStyles();
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);
  const [selectedInvoice, setSelectedInvoice] = useState<null | Invoice>(null);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [filtered, setFiltered] = useState<Invoice[]>([]);
  const [showData, setShowData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderedIndex, sort] = useTableOrder();
  const [saving, setSaving] = useState(false);
  const app = useApp();

  const hasSelected = useMemo(() => filtered.some(invoice => invoice.selectedInGrid), [filtered]);

  useEffect(() => {
    setFiltered(state =>
      state.map(invoice => {
        invoice.selectedInGrid = selectAll;
        return invoice;
      }),
    );
  }, [selectAll]);

  const fetchInvoices = useCallback((query?: QueryParams, selected = false) => {
    setLoading(true);
    api
      .get<Invoice[]>('/invoices', {
        params: { ...query, only_paid: query?.only_paid ? 1 : 0, only_conciliated: query?.only_conciliated ? 1 : 0 },
      })
      .then(response => {
        setInvoices(
          response.data.map(invoice => ({
            ...invoice,
            formattedCreatedAt: format(parseISO(invoice.created_at), 'PP', { locale: ptBR }),
            formattedPayday: format(parseISO(invoice.payday), 'PP', { locale: ptBR }),
            formattedName: invoice.user.name,
            formattedCpf: cpfFormatter(invoice.user.cpf),
            formattedPaidAt: invoice.paid_at ? format(parseISO(invoice.paid_at), 'PP', { locale: ptBR }) : 'Não pago',
            formattedConciliatedAt: invoice.conciliated_at
              ? format(parseISO(invoice.conciliated_at), 'PP', { locale: ptBR })
              : '',
            formattedValue: moneyFormat(invoice.value),
            formattedInstallments: `${invoice.installments}x`,
            customerName: invoice.user.name,
            selectedInGrid: selected,
          })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => fetchInvoices(queryParamsInitialValue), [fetchInvoices]);

  useEffect(() => {
    setFiltered(invoices);
  }, [invoices]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'user_name') {
      fetchInvoices(query, selectAll);
      return;
    }

    if (index === 'user_name' && value.lenght < 4) {
      return;
    }

    timer = setTimeout(() => fetchInvoices(query, selectAll), 500);
  }

  function handleSelectInvoiceInGrid(invoiceId: string) {
    setFiltered(state =>
      state.map(invoice => {
        invoice.selectedInGrid = invoice.id === invoiceId ? !invoice.selectedInGrid : invoice.selectedInGrid;
        return invoice;
      }),
    );
  }

  function handleConciliated() {
    const ids = filtered.filter(invoice => invoice.selectedInGrid).map(invoice => invoice.id);
    setSaving(true);

    api
      .post('/invoices/updateConciliated', { ids })
      .then(() => {
        setFiltered(state =>
          state.map(invoice => {
            invoice.selectedInGrid = false;
            return invoice;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <InvoicesProvider
      value={{ selectedInvoice, setSelectedInvoice, setSelectAll, handleSelectInvoiceInGrid, selectAll }}
    >
      {saving && <Loading />}

      {showData && <InvoicesData data={invoices} onExited={() => setShowData(false)} />}
      <Appbar title="Faturas" ActionsComponent={<PlanActions />} />
      <PageHeaderActions
        title="Faturas"
        description="Gestão das faturas dos clientes Arena H2i"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => history.push('/invoices/new')}>
              Adicionar
            </Button>
            <Button size="small" color="primary" variant="contained" onClick={() => setShowData(true)}>
              Imprimir
            </Button>
          </>
        }
      />
      <TableContainer tableTemplate={plansTableTemplate}>
        <PlansFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
          hasSelected={hasSelected}
          handleConciliated={handleConciliated}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum fatura para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <InvoiceListTable invoices={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <InvoiceListModule invoices={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </InvoicesProvider>
  );
};

export default Invoices;
