import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { User } from 'types/user';

type CustomersContextValue = {
  selectedCustomer: User | null;
  setSelectedCustomer(customer: User | null): void;
  setCustomers: Dispatch<SetStateAction<User[]>>;
  handleUpdateCustomers(customer: User): void;
};

const CustomersContext = createContext<CustomersContextValue>({} as CustomersContextValue);
export const CustomersProvider = CustomersContext.Provider;

export function useCustomers(): CustomersContextValue {
  const context = useContext(CustomersContext);
  return context;
}
