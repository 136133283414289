import { Dispatch, SetStateAction, useState } from 'react';
import { DaysWeek } from 'types/daysWeek';
import * as yup from 'yup';

export type DaysWeekValidation = {
  arena_id?: string;
  block_id?: string;
  week_day?: string;
};

type UseDaysWeekValidation = [
  DaysWeekValidation,
  Dispatch<SetStateAction<DaysWeekValidation>>,
  (daysWeek: DaysWeek) => Promise<void>,
];

export function useDaysWeekValidation(): UseDaysWeekValidation {
  async function handleValidation(daysWeek: DaysWeek) {
    const schema = yup.object().shape({
      week_day: yup.string().required('Dia da semana é obrigatória'),
      arena_id: yup.number().required('A arena é obrigatória'),
      block_id: yup.number().required('A arena é obrigatória'),
    });

    try {
      await schema.validate(daysWeek);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<DaysWeekValidation>({});
  return [validation, setValidation, handleValidation];
}
