import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useApp } from 'hooks/app';

type UserTabProps = {
  handleChange(value: number): void;
  tab: number;
};

const UserTab: React.FC<UserTabProps> = ({ tab, handleChange }) => {
  const app = useApp();

  return (
    <Tabs value={tab} onChange={(e, value) => handleChange(value)} variant={app.isMobile ? 'scrollable' : 'standard'}>
      <Tab label="Usuário" />
      <Tab label="Perfil" />
    </Tabs>
  );
};

export default UserTab;
