import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { SliderBanner } from 'types/sliderBanner';

type AppBannerItemModuleProps = {
  banner: SliderBanner;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const AppBannerItemModule: React.FC<AppBannerItemModuleProps> = ({ banner }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/slider-banners/${banner.id}`)}>
      <Typography variant="caption" color="primary">
        Banner {banner.id}
      </Typography>
      <Typography variant="h6">{banner.name}</Typography>
    </ListItem>
  );
};

export default AppBannerItemModule;
