import { createContext, useContext } from 'react';
import { Invoice } from 'types/invoice';

type InvoiceContextValue = {
  handleChange(index: keyof Invoice, value: any): void;
  invoice: Invoice;
};

const InvoiceContext = createContext<InvoiceContextValue>({} as InvoiceContextValue);
export const InvoiceProvider = InvoiceContext.Provider;

export function useInvoice(): InvoiceContextValue {
  const context = useContext(InvoiceContext);
  return context;
}
