import React, { useReducer, FormEvent, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import userReducer, { USER_INITIAL_STATE } from '../../reducer/reducer';
import { userChange, setProfile } from '../../reducer/actions';
import UserActions from './UserActions';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import UserTab from '../UserTab';
import UserProfile from './UserProfile';
import { UserProfile as UserProfileType } from 'types/userProfile';
import UserForm from '../UserForm';
import { useUserValidation } from '../validation/userValidation';

export type UserValidation = {
  phone?: string;
  email?: string;
  name?: string;
  password?: string;
};

const NewUser: React.FC = () => {
  const [user, dispatch] = useReducer(userReducer, USER_INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const messaging = useMessaging();
  const [userProfiles, setUserProfiles] = useState<UserProfileType[]>([]);
  const [validation, setValidation, validate] = useUserValidation();

  useEffect(() => {
    api
      .get('/userProfiles')
      .then(response => {
        if (response.data.length > 0) {
          dispatch(setProfile(response.data[0]));
        }
        setUserProfiles(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  function handleChange(index: string, value: any) {
    dispatch(userChange(index, value));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(user)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setLoading(true);

    api
      .post('/adminUsers', user)
      .then(() => {
        messaging.handleOpen('Salvo');
        setLoading(false);
        history.push('/users');
      })
      .catch(err => {
        setLoading(false);
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o usuário');
      });
  }

  return (
    <>
      <Appbar
        title="Novo usuário"
        ActionsComponent={<UserActions handleSubmit={handleValidation} />}
        Tab={<UserTab tab={tab} handleChange={value => setTab(value)} />}
        backAction={() => history.push('/users')}
      />
      {loading && <Loading />}
      <Grid container style={{ flex: 1 }} direction="column">
        {tab === 0 ? (
          <Grid item xs={12} lg={4} xl={4} md={6}>
            <form onSubmit={handleValidation}>
              <UserForm handleChange={handleChange} user={user} validation={validation} />
              <button type="submit" style={{ display: 'none' }} />
            </form>
          </Grid>
        ) : (
          <UserProfile user={user} handleChange={handleChange} userProfiles={userProfiles} />
        )}
      </Grid>
    </>
  );
};

export default NewUser;
