import { TableTemplate } from 'types/tableTemplate';

export const arenaPlansTableTemplate: TableTemplate[] = [
  {
    id: 'formattedMonthQuantity',
    description: 'PLANO',
    originalId: 'month_quantity',
    width: 300,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    originalId: 'value',
    width: 150,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'formattedActive',
    width: 150,
  },
];
