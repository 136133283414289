import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { Arena } from 'types/arena';
import { useArenas } from 'pages/arena/hooks/useArenas';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type ArenaItemTableProps = {
  arena: Arena;
};

const ArenaItemTable: React.FC<ArenaItemTableProps> = ({ arena }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedArena } = useArenas();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedArena(arena);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{arena[item.id as keyof Arena]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default ArenaItemTable;
