import { useEffect, useState } from 'react';
import { User } from 'types/user';
import { api } from 'services/api';
import { Invoice } from 'types/invoice';
import { DayUseInvoice } from 'types/dayUseInvoice';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

export type UseFetchDashboard = [
  () => string,
  (days: number) => void,
  (initialDt: Date, finalDt: Date) => void,
  number | null,
  Invoice[],
  Invoice[],
  boolean,
  number,
  DayUseInvoice[],
];

export function useFetchDashboard(): UseFetchDashboard {
  const [loading, setLoading] = useState(false);
  const [finalDate, setFinalDate] = useState(new Date());
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesNoPaid, setInvoicesNoPaid] = useState<Invoice[]>([]);
  const [customerLength, setCustomerLength] = useState(0);
  const [initialDate, setInitialDate] = useState(new Date());
  const [dateConfig, setDateConfig] = useState<number | null>(0);
  const formattedFinalDate = format(finalDate, "dd'.'MM'.'yyyy");
  const formattedInitialDate = format(initialDate, "dd'.'MM'.'yyyy");
  const [invoicesDayUse, setInvoicesDayUse] = useState<DayUseInvoice[]>([]);

  function getDescriptionPageHeader(): string {
    return `Você está visualizando informações de ${formattedInitialDate} à ${formattedFinalDate}`;
  }

  function handleDateConfig(days: number) {
    setDateConfig(days);
    if (days === 0) {
      const date = new Date();
      setInitialDate(startOfDay(date));
      setFinalDate(endOfDay(date));
    } else {
      const date = new Date();
      setFinalDate(endOfDay(date));
      setInitialDate(startOfDay(subDays(date, days)));
    }
  }

  function handleConfirmCustomDates(initialDt: Date, finalDt: Date) {
    setInitialDate(initialDt);
    setFinalDate(finalDt);
    setDateConfig(null);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get<User[]>('/users')
      .then(response => {
        setCustomerLength(response.data.length);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!api) return;

    setLoading(true);

    api
      .get<Invoice[]>('/invoices', {
        params: {
          initial_date: formattedInitialDate,
          final_date: formattedFinalDate,
          user_name: '',
          only_paid: 0,
          date_field: 'created_at',
          only_conciliated: 0,
        },
      })
      .then(response => {
        setInvoicesNoPaid(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [formattedInitialDate, formattedFinalDate]);

  useEffect(() => {
    if (!api) return;

    setLoading(true);

    api
      .get<Invoice[]>('/invoices', {
        params: {
          initial_date: formattedInitialDate,
          final_date: formattedFinalDate,
          user_name: '',
          only_paid: 1,
          date_field: 'created_at',
          only_conciliated: 0,
        },
      })
      .then(response => {
        setInvoices(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [formattedInitialDate, formattedFinalDate]);

  useEffect(() => {
    setLoading(true);

    api
      .get<DayUseInvoice[]>('/dayUseInvoices', {
        params: {
          initial_date: initialDate,
          final_date: finalDate,
          date_field: 'created_at',
          customer_name: '',
          only_conciliated: 0,
        },
      })
      .then(response => {
        setInvoicesDayUse(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  }, [initialDate, finalDate]);

  return [
    getDescriptionPageHeader,
    handleDateConfig,
    handleConfirmCustomDates,
    dateConfig,
    invoices,
    invoicesNoPaid,
    loading,
    customerLength,
    invoicesDayUse,
  ];
}
