import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Done } from '@material-ui/icons';

type CourtActionsProps = {
  handleSubmit(): void;
};

const CourtActions: React.FC<CourtActionsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default CourtActions;
