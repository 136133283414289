import React, { useState, FormEvent } from 'react';
import { makeStyles, Typography, Button, CircularProgress } from '@material-ui/core';
import LoginForm from './LoginForm';
import { Link, Redirect } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import history from 'services/history';
import * as yup from 'yup';
import { useMessaging } from 'hooks/messaging';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      backgroundColor: '#fff',
    },
  },
  content: {
    display: 'flex',
    padding: 30,
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    minWidth: 400,
    margin: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 300,
      padding: 10,
    },
  },
  header: {
    padding: '0 0 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h5': {
      marginTop: 20,
    },
  },
  actions: {
    display: 'flex',
    padding: '50px 0',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .forgot': {
      marginTop: 30,
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  logo: {
    width: 120,
  },
  logoWrapper: {
    width: 120,
    height: 120,
  },
}));

export type LoginValidation = {
  email?: string;
  password?: string;
};

const Login: React.FC = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<LoginValidation>({});
  const auth = useAuth();
  const messaging = useMessaging();

  function handleSubmit() {
    setLoading(true);

    auth
      .login(email, password)
      .then(() => {
        history.push('/');
      })
      .catch(err => {
        setLoading(false);

        console.log(err);

        if (err.response && err.response.status === 401) {
          setValidation({
            email: 'E-mail está correto?',
            password: 'Senha está correta?',
          });
          return;
        }

        if (err.response) {
          messaging.handleOpen('Não foi possível fazer login');
          console.log(err.response);
          return;
        }

        messaging.handleOpen('Não foi possível conectar ao servidor');
      });
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    const schema = yup.object().shape({
      password: yup.string().required('Informe uma senha'),
      email: yup.string().email('Informe um e-mail válido').required('Informe o e-mail'),
    });

    schema
      .validate({ email, password })
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  return (
    <>
      {auth.checkAuth() ? (
        <Redirect to="/" />
      ) : (
        <div className={classes.container}>
          <div className={classes.content}>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress color="primary" />
              </div>
            )}
            <div className={classes.header}>
              <div className={classes.logoWrapper}>
                <a href={process.env.PUBLIC_URL}>
                  <img
                    className={classes.logo}
                    src="https://h2i.s3-sa-east-1.amazonaws.com/upload/images/logo.png"
                    alt="H2I logo"
                  />
                </a>
              </div>
              <Typography align="center" variant="h5">
                Login
              </Typography>
            </div>
            <form onSubmit={handleValidation}>
              <LoginForm
                email={email}
                password={password}
                setPassword={setPassword}
                setEmail={setEmail}
                validation={validation}
              />
            </form>
            <div className={classes.actions}>
              <Button
                onClick={() => handleValidation(undefined)}
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                disabled={loading}
              >
                Entrar
              </Button>
              <div className="forgot">
                <Link to="/forgot">Esqueci minha senha</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
