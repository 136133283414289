import React, { useState, FormEvent, useEffect, useCallback } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import SettingsActions from './SettingsActions';
import { useSiteSettingsReducer } from './reducer/reducer';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import SettingsForm from './SettingsForm';
import { settingsChange, setSettings } from './reducer/actions';
import { SettingsIndexes } from './reducer/types';
import Loading from 'components/loading/Loading';
import { useSiteSettingValidation } from './validation/settingValidation';
import InsideLoading from 'components/loading/InsideLoading';

const Settings: React.FC = () => {
  const [validation, setValidation, validate] = useSiteSettingValidation();
  const [saving, setSaving] = useState(false);
  const [settings, dispatch] = useSiteSettingsReducer();
  const messaging = useMessaging();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get('/settings')
      .then(response => {
        dispatch(setSettings(response.data));
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [dispatch]);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setValidation({});

    validate(settings)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.error(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/settings`, settings)
      .then(() => {
        messaging.handleOpen('Salvo');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const handleChange = useCallback(
    (index: SettingsIndexes, value: any) => {
      dispatch(settingsChange(index, value));
    },
    [dispatch],
  );

  return (
    <>
      <Appbar title="Site" ActionsComponent={<SettingsActions handleSubmit={handleValidation} />} />
      {saving && <Loading />}
      <PageHeader title="Painel de Controle" description="Configurações do site" />
      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleValidation}>
          <SettingsForm validation={validation} handleChange={handleChange} settings={settings} />
        </form>
      )}
    </>
  );
};

export default Settings;
