import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { Invoice } from 'types/invoice';

type InvoiceItemModuleProps = {
  invoice: Invoice;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const InvoiceItemModule: React.FC<InvoiceItemModuleProps> = ({ invoice }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/invoices/${invoice.id}`)}>
      <Typography gutterBottom variant="caption" color="primary">
        Fatura {invoice.formattedPayday}
      </Typography>
      <Typography>{invoice.user.name}</Typography>
      <Typography gutterBottom variant="body2" color="textSecondary">
        {invoice.description}
      </Typography>
      <Typography gutterBottom variant="body2" color="textSecondary">
        {invoice.formattedValue}
      </Typography>
    </ListItem>
  );
};

export default InvoiceItemModule;
