import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { AffiliationInvoice } from 'types/affiliationInvoice';

type AffiliationInvoiceItemModuleProps = {
  affiliationInvoice: AffiliationInvoice;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const AffiliationInvoiceItemModule: React.FC<AffiliationInvoiceItemModuleProps> = ({ affiliationInvoice }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography gutterBottom variant="caption" color="primary">
        Fatura {affiliationInvoice.id}
      </Typography>
      <Typography>{affiliationInvoice.customer_name}</Typography>
      <Typography gutterBottom variant="body2" color="textSecondary">
        {affiliationInvoice.customer_name}
      </Typography>
    </ListItem>
  );
};

export default AffiliationInvoiceItemModule;
