import React from 'react';
import { makeStyles, ListProps as NativeListProps, List as NativeList } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 7,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

type ListProps = NativeListProps;

const List: React.FC<ListProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <NativeList className={`${classes.list} ${className}`} {...rest}>
      {children}
    </NativeList>
  );
};

export default List;
