import React from 'react';
import { IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

type FilterActionsProps = {
  handleOpenDialog(): void;
};

const FilterActions: React.FC<FilterActionsProps> = ({ handleOpenDialog }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.button} onClick={handleOpenDialog} color="inherit">
      <FilterListIcon />
    </IconButton>
  );
};

export default FilterActions;
