import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { Arena } from 'types/arena';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

type ArenaItemModuleProps = {
  arena: Arena;
};

const ArenaItemModule: React.FC<ArenaItemModuleProps> = ({ arena }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/arena/${arena.id}`)}>
      <Typography variant="caption" color="primary">
        ID {arena.id}
      </Typography>
      <Typography gutterBottom>{arena.description}</Typography>
      <Typography variant="body2" color="textSecondary">
        Contato - {arena.contact}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Whatsapp - {arena.whatsapp}
      </Typography>
    </ListItem>
  );
};

export default ArenaItemModule;
