import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import InvoiceItemModule from './InvoiceItemModule';
import { Invoice } from 'types/invoice';

type InvoiceListModuleProps = {
  invoices: Invoice[];
};

const InvoiceListModule: React.FC<InvoiceListModuleProps> = ({ invoices }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(invoice => (
        <InvoiceItemModule key={invoice.id} invoice={invoice} />
      ))}
    </List>
  );
};

export default InvoiceListModule;
