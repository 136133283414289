import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import history from 'services/history';
import { CourtsProvider } from './hooks/useCourts';
import CourtActions from './CourtActions';
import { courtTableTemplate } from './CourtTableTemplate';
import CourtFilterBox from './CourtFilterBox';
import CourtListTable from './list/table/CourtListTable';
import CourtListModule from './list/module/CourtListModule';
import { Court } from 'types/court';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Courts: React.FC = () => {
  const classes = useStyles();
  const [court, setCourt] = useState<Court[]>([]);
  const [selectedCourt, setSelectedCourt] = useState<null | Court>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Court[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();

  useEffect(() => {
    api
      .get<Court[]>('/blocks')
      .then(response => {
        setCourt(
          response.data.map(item => {
            item.formattedActive = item.active ? 'Sim' : 'Não';
            item.arena_description = item.arena.description;
            return item;
          }),
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    const f = search(searchValue, 'description', court);
    setFiltered(f);
  }, [searchValue, search, court]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <CourtsProvider value={{ selectedCourt, setSelectedCourt, setCourt }}>
      <Appbar title="Quadras" ActionsComponent={<CourtActions />} />
      <PageHeaderActions
        title="Quadras"
        description="Quadras da Arena H2i"
        ActionComponent={
          <Button size="small" variant="contained" color="primary" onClick={() => history.push('/court/new')}>
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={courtTableTemplate}>
        <CourtFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch="description"
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma quadra para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <CourtListTable courts={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <CourtListModule courts={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </CourtsProvider>
  );
};

export default Courts;
