import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { AffiliationInvoice } from 'types/affiliationInvoice';

type AffiliationInvoiceContextValue = {
  selectedAffiliationInvoice: AffiliationInvoice | null;
  setSelectedAffiliationInvoice: Dispatch<SetStateAction<AffiliationInvoice | null>>;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  selectAll: boolean;
  handleSelectInvoiceInGrid(invoiceId: string): void;
};

const AffiliationInvoiceContext = createContext<AffiliationInvoiceContextValue>({} as AffiliationInvoiceContextValue);
export const AffiliationInvoicesProvider = AffiliationInvoiceContext.Provider;

export function useAffiliationInvoice(): AffiliationInvoiceContextValue {
  const context = useContext(AffiliationInvoiceContext);
  return context;
}
