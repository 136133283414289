import { TableTemplate } from 'types/tableTemplate';

export const pagesTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'page_id',
    description: 'PAGE ID',
    originalId: 'page_id',
    width: 200,
  },
  {
    id: 'title',
    description: 'TITLE',
    originalId: 'title',
    width: 300,
  },
  {
    id: 'description',
    description: 'NOME',
    originalId: 'name',
    width: 300,
  },
  {
    id: 'keywords',
    description: 'KEYWORDS',
    originalId: 'keywords',
    width: 300,
  },
  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
