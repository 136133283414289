import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Done } from '@material-ui/icons';

type DayUseActionsProps = {
  handleSubmit(): void;
};

const DayUseActions: React.FC<DayUseActionsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default DayUseActions;
