import { TableTemplate } from 'types/tableTemplate';

export const arenaTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'description',
    description: 'Nome da Arena',
    originalId: 'description',
    width: 300,
  },
  {
    id: 'contact',
    description: 'Nome do contato',
    originalId: 'contact',
    width: 150,
  },
  {
    id: 'formattedPhone',
    description: 'Wapp do Contato',
    originalId: 'formattedPhone',
    width: 150,
  },
  {
    id: 'formattedActive',
    description: 'Ativo',
    originalId: 'active',
    width: 150,
  },
];
