import React from 'react';
import { User } from 'types/user';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';

type UserItemProps = {
  user: User;
};

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .data': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  imageWrapper: {
    display: 'flex',
    height: 70,
    width: 70,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  img: {
    width: '100%',
  },
}));

const UserItem: React.FC<UserItemProps> = ({ user }) => {
  const classes = useStyles();

  function handleClick(id?: number) {
    history.push(`/users/${id}`);
  }

  return (
    <ListItem button className={classes.listItem} onClick={() => handleClick(user.id)}>
      <div className="data">
        <Typography gutterBottom color="primary" variant="caption">
          ID {user.id}
        </Typography>
        <Typography variant="h6">{user.name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {user.email}
        </Typography>
      </div>
    </ListItem>
  );
};

export default UserItem;
