import React, { useState, FormEvent } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import * as yup from 'yup';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { useQuery } from 'hooks/useQueryParams';
import PasswordResetContent from './PasswordResetContent';
import PasswordResetError from './PasswordResetError';
import PasswordResetSuccess from './PasswordResetSuccess';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.primary.light,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      backgroundColor: '#fff',
    },
  },
  content: {
    display: 'flex',
    padding: 30,
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: 4,
    minWidth: 400,
    minHeight: 580,
    margin: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 300,
      padding: 10,
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
}));

export type PasswordResetValidation = {
  email?: string;
  password?: string;
  password_confirmation?: string;
};

export type ResetData = {
  email: string;
  password: string;
  password_confirmation: string;
};

const PasswordReset: React.FC = () => {
  const classes = useStyles();
  const [validation, setValidation] = useState<PasswordResetValidation>({});
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState<ResetData>({
    email: '',
    password: '',
    password_confirmation: '',
  });
  const messaging = useMessaging();
  const query = useQuery();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  function handleChange(index: 'email' | 'password' | 'password_confirmation', value: string) {
    setReset(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').required('Informe o email'),
      password: yup.string().min(8, 'A senha deve ter no mínimo 8 caracteres').required('Informe a nova senha'),
      password_confirmation: yup.string().oneOf([yup.ref('password'), undefined], 'Nova senha não confere'),
    });

    schema
      .validate(reset)
      .then(handleSubmit)
      .catch((err: yup.ValidationError) => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    const token = query.get('token');

    if (!query) return;

    setLoading(true);

    api
      .post(`/adminUsers/resetPassword`, { ...reset, token })
      .then(() => {
        messaging.handleOpen('Nova senha criada');
        setSuccess(true);
        setReset({
          email: '',
          password: '',
          password_confirmation: '',
        });
      })
      .catch(err => {
        if (err.response && err.response.data.error) setError(err.response.data.error);
        else setError('Não foi possível criar uma nova senha');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        )}
        {error ? (
          <PasswordResetError error={error} setError={setError} />
        ) : success ? (
          <PasswordResetSuccess />
        ) : (
          <PasswordResetContent
            reset={reset}
            validation={validation}
            handleValidation={handleValidation}
            handleChange={handleChange}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
