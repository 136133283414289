import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import DaysWeekActions from '../DaysWeekActions';
import DaysWeekForm from '../DaysWeekForm';
import { DaysWeek } from 'types/daysWeek';
import { Arena } from 'types/arena';
import { Court } from 'types/court';
import { DaysWeekProvider } from 'pages/days-week/hooks/useDaysWeek';
import { useDaysWeekValidation } from 'pages/days-week/validation/DaysWeekValidation';

const INITIAL_STATE: DaysWeek = {
  id: 0,
  active: true,
  arena: {} as Arena,
  block: {} as Court,
  arena_id: 0,
  block_id: 0,
  week_day: '',
};

const DaysWeekEdit: React.FC = () => {
  const [daysWeek, setDaysWeek] = useState<DaysWeek>(INITIAL_STATE);
  const [validation, setValidation, validate] = useDaysWeekValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<DaysWeek>(`/weekdays/${id}`)
      .then(response => {
        setDaysWeek(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  const handleChange = useCallback((index: keyof DaysWeek, value: any) => {
    setDaysWeek(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(daysWeek)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/weekdays/${id}`, daysWeek)
      .then(() => {
        setSaving(false);
        history.push('/daysweek');
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível atualizar o dia da semana');
        setSaving(false);
      });
  }

  return (
    <DaysWeekProvider value={{ handleChange, daysWeek, handleValidation }}>
      {saving && <Loading />}
      <Appbar
        title="Dia da Semana"
        ActionsComponent={<DaysWeekActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/daysWeeks')}
      />
      <PageHeader title="Editar dia da semana" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <DaysWeekForm handleChange={handleChange} daysWeek={daysWeek} validation={validation} />
          </form>
        </Grid>
      )}
    </DaysWeekProvider>
  );
};

export default DaysWeekEdit;
