import React, { useRef, useEffect } from 'react';
import { TextField, makeStyles, Switch, Typography } from '@material-ui/core';
import { ProfileValidation } from './Profile';
import { UserProfile } from 'types/userProfile';
import { AccessRule } from 'types/accessRule';
import ProfileFormAccessRules from './ProfileFormAccessRuleItem';

type ProfileFormProps = {
  handleUserProfileChange(value: string): void;
  handleAccessRulesChange(id: number): void;
  profile: UserProfile | null;
  validation: ProfileValidation;
  accessRules: AccessRule[];
};

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #ccc',
    padding: '20px 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rules: {
    marginTop: 20,
  },
  title: {
    textTransform: 'uppercase',
  },
});

const ProfileForm: React.FC<ProfileFormProps> = ({
  handleAccessRulesChange,
  handleUserProfileChange,
  profile,
  validation,
  accessRules,
}) => {
  const classes = useStyles();
  const nameInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (validation.name) nameInputRef.current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={nameInputRef}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        placeholder="Digite o nome do perfil"
        margin="normal"
        value={profile ? profile.name : ''}
        onChange={e => handleUserProfileChange(e.target.value)}
        fullWidth
        autoFocus
      />
      <div className={classes.rules}>
        {accessRules.map(
          rule =>
            !rule.access_rule_id && (
              <div key={rule.id} className={classes.formControl}>
                <div className={classes.header}>
                  <Typography className={classes.title}>{rule.title}</Typography>
                  <Switch color="primary" onChange={() => handleAccessRulesChange(rule.id)} checked={rule.active} />
                </div>
                <Typography variant="body2" color="textSecondary">
                  {rule.description}
                </Typography>
                {rule.access_rules.length > 0 && (
                  <ProfileFormAccessRules
                    accessRules={rule.access_rules}
                    handleAccessRulesChange={handleAccessRulesChange}
                    accessRuleId={rule.id}
                  />
                )}
              </div>
            ),
        )}
      </div>
    </>
  );
};

export default ProfileForm;
