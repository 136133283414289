import { Dispatch, SetStateAction, useState } from 'react';
import { AccessRule } from 'types/accessRule';
import * as yup from 'yup';

export type AccessRuleValidation = {
  name?: string;
  title?: string;
  description?: string;
};

type UseAccessRuleValidation = [
  AccessRuleValidation,
  Dispatch<SetStateAction<AccessRuleValidation>>,
  (accessRule: AccessRule) => Promise<void>,
];

export function useAccessRuleValidation(): UseAccessRuleValidation {
  async function handleValidation(accessRule: AccessRule) {
    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      title: yup.string().required('O título é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
    });

    try {
      await schema.validate(accessRule);
    } catch (err) {
      setValidation({
        [err.path]: err.message,
      });
      throw new Error(err.message);
    }
  }

  const [validation, setValidation] = useState<AccessRuleValidation>({});
  return [validation, setValidation, handleValidation];
}
