import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { User } from 'types/user';
import UserSelectorItem from './UserSelectorItem';

const styles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
});

interface UsersSelectorListProps {
  users: User[];
}

const UsersSelectorList: React.FC<UsersSelectorListProps> = ({ users }) => {
  const classes = styles();

  return (
    <List className={classes.list}>
      {users.map(user => (
        <UserSelectorItem user={user} key={user.id} />
      ))}
    </List>
  );
};

export default UsersSelectorList;
