import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { Activity } from 'types/activity';
import { useActivityValidation } from 'pages/customers-rating/validation/ActivityValidation';
import { CustomerActivityProvider } from 'pages/customers-rating/hooks/useCustomerActivity';
import CustomerActivityForm from '../new/CustomerActivityForm';
import ActivityActions from './ActivityActions';

const INITIAL_STATE: Activity = {
  id: 0,
  description: '',
  value_holiday: 0,
  value_associate: 0,
  value_week: 0,
  active: true,
};

const CustomerActivityEdit: React.FC = () => {
  const [activity, setActivity] = useState<Activity>(INITIAL_STATE);
  const [validation, setValidation, validate] = useActivityValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<Activity>(`/activities/${id}`)
      .then(response => {
        setActivity(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  const handleChange = useCallback((index: keyof Activity, value: any) => {
    setActivity(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(activity)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/activities/${id}`, activity)
      .then(() => {
        setSaving(false);
        history.push('/customers-activity');
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível atualizar a atividade');
        setSaving(false);
      });
  }

  return (
    <CustomerActivityProvider value={{ handleChange, activity, handleValidation }}>
      {saving && <Loading />}
      <Appbar
        title="Classificação de clientes"
        ActionsComponent={<ActivityActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/customers-rating')}
      />
      <PageHeader title="Editar classificação de clientes" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <CustomerActivityForm handleChange={handleChange} activity={activity} validation={validation} />
          </form>
        </Grid>
      )}
    </CustomerActivityProvider>
  );
};

export default CustomerActivityEdit;
