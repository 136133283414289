import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, TextField, InputAdornment, Button, IconButton, Typography } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import { MdSearch } from 'react-icons/md';
import { api, getCancelTokenSource } from 'services/api';
import { User } from 'types/user';
import UserListTable from './table/UserList';
import UserListCard from './card/UserList';
import { useApp } from 'hooks/app';
import UsersTableLoading from './table/UsersTableLoading';
import UserActions from './UserActions';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import UsersCardLoading from './card/UsersCardLoading';
import history from 'services/history';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import useSearch from 'hooks/search';
import Pagination from 'components/pagination/Pagination';

const useStyles = makeStyles(theme => ({
  bottomActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  options: {
    backgroundColor: '#fff',
    borderRadius: '8px 8px 0 0',
    padding: 10,
  },
  noUsers: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    marginTop: 10,
  },
}));

type DisplayMode = 'module' | 'list';

const NoUsers: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.noUsers}>
      <Typography variant="h6" color="textSecondary">
        Nenhum usuário
      </Typography>
    </div>
  );
};

const Users: React.FC = () => {
  const classes = useStyles();
  const app = useApp();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(app.isMobile ? 'module' : 'list');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [indexFiltered, sort] = useTableOrder();
  const search = useSearch();

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get<User[]>('/adminUsers', { cancelToken: source.token })
      .then(response => {
        if (request) {
          const _users = response.data.map(user => ({
            ...user,
            profileName: user.user_profile ? user.user_profile.name : '',
          }));
          setUsers(_users);
          setFilteredUsers(_users);
          console.log(_users);
        }
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) request = false;

      source.cancel();
    };
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSearch(searchValue: string) {
    if (searchValue) {
      const p = search(searchValue, 'name', users);
      setFilteredUsers(p);
      return;
    }

    setFilteredUsers(users);
  }

  function handleSort(id: string) {
    setFilteredUsers(sort(id, filteredUsers));
  }

  return (
    <>
      <Appbar title="Configurações" ActionsComponent={<UserActions />} />
      <PageHeaderActions
        title="Usuários"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/users/new')}>
            Adicionar
          </Button>
        }
      />
      <Grid container justify="space-between" alignItems="center" className={classes.options}>
        <Grid item xs={app.isMobile || app.windowWidth < 960 ? 12 : 4}>
          <TextField
            fullWidth
            label="Pesquisar"
            placeholder="Digite sua pesquisa"
            autoFocus
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <div className={classes.bottomActions}>
          <IconButton onClick={() => setDisplayMode('list')}>
            <ViewListIcon color={displayMode === 'list' ? 'primary' : undefined} />
          </IconButton>
          <IconButton onClick={() => setDisplayMode('module')}>
            <ViewModuleIcon color={displayMode === 'module' ? 'primary' : undefined} />
          </IconButton>
        </div>
      </Grid>
      <PaginationProvider>
        {displayMode === 'list' ? (
          loading ? (
            <UsersTableLoading />
          ) : filteredUsers.length > 0 ? (
            <UserListTable users={filteredUsers} handleSort={handleSort} orderedIndex={indexFiltered} />
          ) : (
            <NoUsers />
          )
        ) : loading ? (
          <UsersCardLoading />
        ) : filteredUsers.length > 0 ? (
          <UserListCard users={filteredUsers} />
        ) : (
          <NoUsers />
        )}
        <Pagination count={filteredUsers.length} />
      </PaginationProvider>
    </>
  );
};

export default Users;
