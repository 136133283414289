import React from 'react';
import { User } from 'types/user';
import UserItem from './UserItem';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';

type UserListProps = {
  users: User[];
};

const UserList: React.FC<UserListProps> = ({ users }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {users.map(user => (
            <UserItem key={user.id} user={user} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default UserList;
