import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { Activity } from 'types/activity';
import { DayUseInvoice } from 'types/dayUseInvoice';
import { Invoice } from 'types/invoice';

type UseFetchCustomerData = [DayUseInvoice[], Invoice[], Activity[], boolean];

export function useFetchCustomerData(customerId?: number): UseFetchCustomerData {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [dayUseInvoices, setDayUseInvoices] = useState<DayUseInvoice[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    const invoicesRequest = api.get<Invoice[]>(`/users/${customerId}/invoices`);
    const dayUseInvoicesRequest = api.get<DayUseInvoice[]>(`/users/${customerId}/dayUseInvoices`);
    const activityRequest = api.get<Activity[]>(`/activities`);

    Promise.all([invoicesRequest, dayUseInvoicesRequest, activityRequest])
      .then(([invoicesResponse, dayUseInvoiceResponse, activityResponse]) => {
        setInvoices(invoicesResponse.data);
        setDayUseInvoices(dayUseInvoiceResponse.data);
        setActivities(activityResponse.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [customerId]);

  return [dayUseInvoices, invoices, activities, loading];
}
