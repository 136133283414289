import React, { useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import PhoneInput from 'components/masked-input/PhoneInput';
import { User } from 'types/user';
import { UserValidation } from './validation/userValidation';

type UserFormProps = {
  user: User;
  handleChange(index: string, value: string): void;
  validation: UserValidation;
};

const UserForm: React.FC<UserFormProps> = ({ user, handleChange, validation }) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome"
        placeholder="Digite o nome"
        margin="normal"
        fullWidth
        value={user.name}
        onChange={e => handleChange('name', e.target.value)}
        autoFocus
      />
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="E-mail"
        placeholder="Digite o e-mail"
        margin="normal"
        fullWidth
        value={user.email}
        onChange={e => handleChange('email', e.target.value)}
        autoComplete="none"
      />
      <TextField
        inputRef={inputs.phone}
        error={!!validation.phone}
        helperText={validation.phone}
        label="Telefone"
        placeholder="Digite o número do telefone"
        margin="normal"
        fullWidth
        value={user.phone}
        onChange={e => handleChange('phone', e.target.value)}
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
      />
    </>
  );
};

export default UserForm;
