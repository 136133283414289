import { Dispatch, SetStateAction, useState } from 'react';
import { Court } from 'types/court';
import * as yup from 'yup';

export type CourtValidation = {
  arena_id?: string;
  active?: string;
  description?: string;
};

type UseCourtValidation = [CourtValidation, Dispatch<SetStateAction<CourtValidation>>, (court: Court) => Promise<void>];

export function useCourtValidation(): UseCourtValidation {
  async function handleValidation(court: Court) {
    const schema = yup.object().shape({
      active: yup.boolean().required('O whatsapp é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
      arena_id: yup.number().required('A arena é obrigatória'),
    });

    try {
      await schema.validate(court);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<CourtValidation>({});
  return [validation, setValidation, handleValidation];
}
