import React, { useState, useEffect } from 'react';
import Dialog from 'components/dialogs/Dialog';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { api } from 'services/api';
import { Search } from '@material-ui/icons';
import useSearch from 'hooks/search';
import UsersSelectorList from './UsersSelectorList';
import { User } from 'types/user';

const useStyles = makeStyles(theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(250,250,250,0.5)',
    zIndex: 1110,
  },
  draggableZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
}));

interface UsersSelectorProps {
  onExited: () => void;
}

const UsersSelector: React.FC<UsersSelectorProps> = ({ onExited }) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [filtered, setFiltered] = useState<User[]>([]);
  const search = useSearch();
  const [textSearch, setTextSearch] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setFiltered(search(textSearch, 'name', users));
  }, [search, textSearch, users]);

  useEffect(() => {
    if (!api) return;

    setLoading(true);
    api
      .get('/users')
      .then(response => {
        setUsers(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Dialog title="Selecione um usuário" onExited={onExited} maxWidth="sm">
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <TextField
            label="Pesquisa"
            placeholder="Pesquisar usuários..."
            fullWidth
            margin="normal"
            value={textSearch}
            onChange={e => setTextSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <UsersSelectorList users={filtered} />
        </>
      )}
    </Dialog>
  );
};

export default UsersSelector;
