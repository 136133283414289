import React, { ChangeEvent, MouseEvent } from 'react';
import { Checkbox, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { useDayUseInvoices } from 'pages/day-use-invoices/hooks/useDayUseInvoices';
import { DayUseInvoice } from 'types/dayUseInvoice';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type DayUseInvoiceItemTableProps = {
  dayUseInvoice: DayUseInvoice;
};

const DayUseInvoiceItemTable: React.FC<DayUseInvoiceItemTableProps> = ({ dayUseInvoice }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedDayUseInvoice, handleSelectInvoiceInGrid } = useDayUseInvoices();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedDayUseInvoice(dayUseInvoice);
  }

  function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    handleSelectInvoiceInGrid(dayUseInvoice.id);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'checkbox' ? (
            <div>
              <Checkbox
                checked={dayUseInvoice.selectedInGrid}
                onChange={event => handleCheckboxChange(event)}
                onClick={e => e.stopPropagation()}
              />
            </div>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{dayUseInvoice[item.id as keyof DayUseInvoice]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default DayUseInvoiceItemTable;
