import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useDialog } from 'components/dialogs/Dialog';

interface CustomerActionsProps {
  handleValidation(handleCloseDialog: () => void): void;
}

const CustomerActions: React.FC<CustomerActionsProps> = ({ handleValidation }) => {
  const { handleClose } = useDialog();

  function handleClick() {
    handleValidation(handleClose);
  }

  return (
    <Tooltip title="Salvar favorecido">
      <IconButton onClick={handleClick} color="inherit">
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default CustomerActions;
