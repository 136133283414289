import { TableTemplate } from 'types/tableTemplate';

export const affiliationInvoicesTableTemplate: TableTemplate[] = [
  {
    id: 'checkbox',
    description: '',
    originalId: 'checkbox',
    width: 50,
    notFilterable: true,
  },
  {
    id: 'customer_name',
    description: 'CLIENTE',
    originalId: 'customer_name',
    width: 300,
  },
  {
    id: 'customer_email',
    description: 'E-MAIL',
    originalId: 'customer_email',
    width: 300,
  },
  {
    id: 'customer_document',
    description: 'DOCUMENTO',
    originalId: 'customer_document',
    width: 150,
  },
  {
    id: 'customer_phone',
    description: 'TELEFONE',
    originalId: 'customer_phone',
    width: 150,
  },
  {
    id: 'credit_card_number',
    description: 'CARTÃO DE CRÉDITO',
    originalId: 'credit_card_number',
    width: 200,
  },
  {
    id: 'transaction_id',
    description: 'ID TRANSAÇÃO',
    originalId: 'transaction_id',
    width: 200,
  },
  {
    id: 'transaction_nsu',
    description: 'NSU TRANSAÇÃO',
    originalId: 'transaction_nsu',
    width: 200,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    originalId: 'value',
    width: 120,
  },
  {
    id: 'formattedPaidAt',
    description: 'PAGO EM',
    originalId: 'formattedPaidAt',
    width: 150,
  },
  {
    id: 'formattedConciliatedAt',
    description: 'CONCILIADO EM',
    originalId: 'formattedConciliatedAt',
    width: 150,
  },
  {
    id: 'formattedCanceledAt',
    description: 'CANCELADO EM',
    originalId: 'formattedCanceledAt',
    width: 150,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 150,
  },
];
