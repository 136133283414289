import { createContext, useContext } from 'react';
import { DayUse } from 'types/dayuse';

type DayUseContextValue = {
  handleChange(index: keyof DayUse, value: any): void;
  dayUse: DayUse;
};

const DayUseContext = createContext<DayUseContextValue>({} as DayUseContextValue);
export const DayUseProvider = DayUseContext.Provider;

export function useInvoice(): DayUseContextValue {
  const context = useContext(DayUseContext);
  return context;
}
