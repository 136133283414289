import React, { useEffect, useRef } from 'react';
import { Button, Checkbox, FormControlLabel, InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { QueryParams } from './Invoices';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '220px 1fr 1fr',
    columnGap: 15,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '200px 200px 200px 1fr',
      columnGap: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        columnGap: 10,
      },
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 11,
  },
  date: {
    display: 'grid',
    gap: '10px',
  },
}));

type PlansFilterBoxProps = {
  queryParams: QueryParams;
  handleQueryParamsChange(index: keyof QueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  hasSelected: boolean;
  handleConciliated(): void;
};

const PlansFilterBox: React.FC<PlansFilterBoxProps> = ({
  queryParams,
  handleQueryParamsChange,
  setDisplayMode,
  displayMode,
  hasSelected,
  handleConciliated,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, []);

  return (
    <FilterBox>
      {hasSelected ? (
        <div>
          <Button onClick={handleConciliated} color="primary" variant="contained" size="small">
            conciliar
          </Button>
        </div>
      ) : (
        <div className={classes.filter}>
          <TextField
            inputRef={inputSearchRef}
            label="Pesquisar"
            placeholder="Digite sua pesquisa"
            autoFocus
            value={queryParams.user_name}
            onChange={e => handleQueryParamsChange('user_name', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <div className="search-params">
            <TextField
              select
              label="Tipo da data"
              onChange={e => handleQueryParamsChange('date_field', e.target.value)}
              value={queryParams.date_field}
            >
              <MenuItem value="payday">Vencimento</MenuItem>
              <MenuItem value="created_at">Lançamento</MenuItem>
              <MenuItem value="paid_at">Pagamento</MenuItem>
            </TextField>
            <div className={classes.date}>
              <KeyboardDatePicker
                label="Data inicial"
                value={queryParams.initial_date}
                onChange={date => handleQueryParamsChange('initial_date', date)}
                autoOk
              />
              <KeyboardDatePicker
                label="Data Final"
                value={queryParams.final_date}
                onChange={date => handleQueryParamsChange('final_date', date)}
                autoOk
              />
            </div>
            <div className={classes.box}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={queryParams.only_paid}
                    onChange={e => handleQueryParamsChange('only_paid', e.target.checked)}
                    color="primary"
                  />
                }
                label="Somente pagos"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={queryParams.only_conciliated}
                    onChange={e => handleQueryParamsChange('only_conciliated', e.target.checked)}
                    color="primary"
                  />
                }
                label="Conciliados"
              />
            </div>
          </div>
        </div>
      )}

      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default PlansFilterBox;
