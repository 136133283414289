import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Affiliate } from 'types/affiliate';

type AssociatesContextValue = {
  selectedAssociate: Affiliate | null;
  setSelectedAssociate(Affiliate: Affiliate | null): void;
  setAffiliates: Dispatch<SetStateAction<Affiliate[]>>;
  handleUpdate(Affiliate: Affiliate): void;
};

const AssociatesContext = createContext<AssociatesContextValue>({} as AssociatesContextValue);
export const AssociatesProvider = AssociatesContext.Provider;

export function useAssociates(): AssociatesContextValue {
  const context = useContext(AssociatesContext);
  return context;
}
