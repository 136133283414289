import React, { useMemo } from 'react';
import { makeStyles, TablePagination as NativeTablePagination } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import { Pagination as PaginationLab } from '@material-ui/lab';

type PaginationProps = {
  count: number;
};

const useStyles = makeStyles(theme => ({
  selectRoot: {
    marginRight: 10,
  },
  toolbar: {
    paddingLeft: '0!important',
  },
  actions: {
    marginLeft: 0,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  paginationLab: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Pagination: React.FC<PaginationProps> = ({ count }) => {
  const { rowsPerPage, page, handleSetPage, handleSetRowsPerPage, rowsPerPageOption } = usePagination();
  const classes = useStyles();

  const pages = useMemo(() => Math.ceil(count / rowsPerPage), [rowsPerPage, count]);

  return (
    <div className={classes.container}>
      <NativeTablePagination
        classes={{ selectRoot: classes.selectRoot, toolbar: classes.toolbar, actions: classes.actions }}
        labelRowsPerPage="Registros"
        rowsPerPageOptions={rowsPerPageOption}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Página anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página',
        }}
        onPageChange={(e, page) => handleSetPage(page)}
        onRowsPerPageChange={e => handleSetRowsPerPage(parseFloat(e.target.value))}
      />
      <PaginationLab
        className={classes.paginationLab}
        count={pages}
        page={page + 1}
        showFirstButton
        showLastButton
        onChange={(e, page) => handleSetPage(page - 1)}
        color="primary"
      />
    </div>
  );
};

export default Pagination;
