import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
  },
  actions: {
    marginTop: 30,
  },
});

const PasswordResetSuccess: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Typography gutterBottom>Sua senha foi atualizada</Typography>

      <div className={classes.actions}>
        <Link to="/login">ir para o login</Link>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
