import React, { HTMLAttributes } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface DisplayModeButtonsProps extends HTMLAttributes<HTMLDivElement> {
  handleShowList(): void;
  handleShowModule(): void;
  displayMode: 'list' | 'module';
}

const DisplayModeButtons: React.FC<DisplayModeButtonsProps> = ({
  handleShowList,
  handleShowModule,
  displayMode,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${className}`} {...rest}>
      <IconButton onClick={handleShowList}>
        <ViewListIcon color={displayMode === 'list' ? 'primary' : undefined} />
      </IconButton>
      <IconButton onClick={handleShowModule}>
        <ViewModuleIcon color={displayMode === 'module' ? 'primary' : undefined} />
      </IconButton>
    </div>
  );
};

export default DisplayModeButtons;
