import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';

const styles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
  },
  actions: {
    marginTop: 30,
  },
});

interface PasswordResetErrorProps {
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

const PasswordResetError: React.FC<PasswordResetErrorProps> = ({ error, setError }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Typography gutterBottom>{error}</Typography>

      <div className={classes.actions}>
        <Button variant="contained" color="primary" onClick={() => setError('')}>
          tentar novamente
        </Button>
      </div>
    </div>
  );
};

export default PasswordResetError;
