import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import { Arena } from 'types/arena';
import ArenaItemModule from './ArenaItemModule';

type ArenaListModuleProps = {
  arenas: Arena[];
};

const ArenaListModule: React.FC<ArenaListModuleProps> = ({ arenas }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {arenas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(arena => (
            <ArenaItemModule key={arena.id} arena={arena} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ArenaListModule;
