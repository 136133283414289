import React, { FormEvent, useCallback, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import PlansActions from './PlansActions';
import { useInvoiceValidation } from '../validation/invoiceValidation';
import { User } from 'types/user';
import PlanForm from '../InvoiceForm';
import { Invoice } from 'types/invoice';
import { InvoiceProvider } from 'pages/invoices/hooks/useInvoice';

const INITIAL_STATE: Invoice = {
  id: '',
  created_at: '',
  description: '',
  payday: '',
  user_id: 0,
  value: 0,
  user: {} as User,
  formattedCreatedAt: '',
  formattedPayday: '',
  formattedValue: '',
  paid_at: '',
  installments: 1,
  formattedInstallments: '',
  conciliated_at: '',
  formattedConciliatedAt: '',
  formattedPaidAt: '',
};

const PlanNew: React.FC = () => {
  const [invoice, setInvoice] = useState<Invoice>(INITIAL_STATE);
  const [validation, setValidation, validate] = useInvoiceValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof Invoice, value: any) => {
    setInvoice(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(invoice)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/invoices`, invoice)
      .then(() => {
        setSaving(false);
        history.push('/invoices');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <InvoiceProvider value={{ handleChange, invoice }}>
      {saving && <Loading />}
      <Appbar
        title="Fatura"
        ActionsComponent={<PlansActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/invoices')}
      />
      <PageHeader title="Emitir fatura" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <PlanForm handleChange={handleChange} invoice={invoice} validation={validation} />
        </form>
      </Grid>
    </InvoiceProvider>
  );
};

export default PlanNew;
