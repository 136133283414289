import React, { FormEvent, useCallback, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import DaysWeekForm from '../DaysWeekForm';
import DaysWeekActions from '../DaysWeekActions';
import { DaysWeek } from 'types/daysWeek';
import { Arena } from 'types/arena';
import { DaysWeekProvider } from 'pages/days-week/hooks/useDaysWeek';
import { useDaysWeekValidation } from 'pages/days-week/validation/DaysWeekValidation';
import { Court } from 'types/court';

const INITIAL_STATE: DaysWeek = {
  id: 0,
  active: true,
  arena: {} as Arena,
  block: {} as Court,
  arena_id: 0,
  block_id: 0,
  week_day: '',
};

const DaysWeekNew: React.FC = () => {
  const [daysWeek, setDaysWeek] = useState<DaysWeek>(INITIAL_STATE);
  const [validation, setValidation, validate] = useDaysWeekValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();

  const handleChange = useCallback((index: keyof DaysWeek, value: any) => {
    setDaysWeek(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(daysWeek)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/weekdays`, daysWeek)
      .then(() => {
        setSaving(false);
        history.push('/daysWeeks');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.message ? 'Não foi possível criar o dia da semana' : '');
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  return (
    <DaysWeekProvider value={{ handleChange, handleValidation, daysWeek }}>
      {saving && <Loading />}
      <Appbar
        title="Dia da Semana"
        ActionsComponent={<DaysWeekActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/daysWeeks')}
      />
      <PageHeader title="Dia da Semana" />
      <Grid container>
        <form onSubmit={handleValidation}>
          <DaysWeekForm handleChange={handleChange} daysWeek={daysWeek} validation={validation} />
        </form>
      </Grid>
    </DaysWeekProvider>
  );
};

export default DaysWeekNew;
