import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { DaysWeek } from 'types/daysWeek';
import { useDaysWeeks } from 'pages/days-week/hooks/useDaysWeeks';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type DaysWeekItemTableProps = {
  daysWeek: DaysWeek;
};

const DaysWeekItemTable: React.FC<DaysWeekItemTableProps> = ({ daysWeek }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedDaysWeek } = useDaysWeeks();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedDaysWeek(daysWeek);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{daysWeek[item.id as keyof DaysWeek]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default DaysWeekItemTable;
