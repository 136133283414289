import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Dialog from 'components/dialogs/Dialog';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'store/selector';
import { Invoice } from 'types/invoice';
import { InvoicesExport } from './InvoicesExport';

type InvoicesDataProps = {
  data: Invoice[];
  onExited(): void;
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    columnGap: '8px',
    gridTemplateColumns: '1.5fr 3fr 110px 110px 70px 60px',
    marginBottom: 1,
    paddingBottom: 1,
  },
  headerInfo: {
    marginTop: 10,
  },
  rowP: {
    fontSize: '7pt',
    textAlign: 'end',
  },
  rowPL: {
    fontSize: '7pt',
    textAlign: 'start',
  },
});

const InvoicesData: React.FC<InvoicesDataProps> = ({ data, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [excelExport, setExcelExport] = useState(false);

  return (
    <Dialog
      fullScreen
      title="Faturas"
      ComponentActions={
        <Button
          disabled={data.length === 0}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setExcelExport(true)}
        >
          Exportar faturas
        </Button>
      }
      onExited={onExited}
    >
      {excelExport && <InvoicesExport onExited={() => setExcelExport(false)} data={data} />}
      <div className="page">
        <div className="header">
          <div>
            <h6>Relatório de faturas</h6>
          </div>
          <div>
            <p>{user?.name}</p>
            <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
          </div>
        </div>
        <div className={`${classes.row} table-header`}>
          <p className={classes.rowPL}>Cliente</p>
          <p className={classes.rowPL}>Descrição</p>
          <p className={classes.rowPL}>Data de vencimento</p>
          <p className={classes.rowPL}>CPF</p>
          <p className={classes.rowPL}>Pago em</p>
          <p className={classes.rowP}>Valor</p>
        </div>
        {data.map(item => (
          <div key={item.id} className={classes.row}>
            <p className={classes.rowPL}>{item.user.name}</p>
            <p className={classes.rowPL}>{item.description}</p>
            <p className={classes.rowPL}>{item.formattedPayday}</p>
            <p className={classes.rowPL}>{item.formattedCpf}</p>
            <p className={classes.rowPL}>{!item.formattedPaidAt ? 'Não' : item.formattedPaidAt}</p>
            <p className={classes.rowP}>{item.formattedValue}</p>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default InvoicesData;
