import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

type SettingsActionsProps = {
  handleSubmit(): void;
};

const SettingsActions: React.FC<SettingsActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default SettingsActions;
