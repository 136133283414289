import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import history from 'services/history';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import CourtActions from '../CourtActions';
import CourtForm from '../CourtForm';
import { Court } from 'types/court';
import { useCourtValidation } from 'pages/court/validation/CourtValidation';
import { CourtProvider } from 'pages/court/hooks/useCourt';
import { Arena } from 'types/arena';

const INITIAL_STATE: Court = {
  id: 0,
  description: '',
  active: true,
  arena: {} as Arena,
  arena_id: 0,
};

const CourtEdit: React.FC = () => {
  const [court, setCourt] = useState<Court>(INITIAL_STATE);
  const [validation, setValidation, validate] = useCourtValidation();
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<Court>(`/blocks/${id}`)
      .then(response => {
        setCourt(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  const handleChange = useCallback((index: keyof Court, value: any) => {
    setCourt(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }, []);

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(court)
      .then(() => {
        handleSubmit();
      })
      .catch((err: yup.ValidationError) => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/blocks/${id}`, court)
      .then(() => {
        setSaving(false);
        history.push('/courts');
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível atualizar a quadra');
        setSaving(false);
      });
  }

  return (
    <CourtProvider value={{ handleChange, court, handleValidation }}>
      {saving && <Loading />}
      <Appbar
        title="Quadras"
        ActionsComponent={<CourtActions handleSubmit={handleValidation} />}
        backAction={() => history.push('/courts')}
      />
      <PageHeader title="Editar quadra" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <form onSubmit={handleValidation}>
            <CourtForm handleChange={handleChange} court={court} validation={validation} />
          </form>
        </Grid>
      )}
    </CourtProvider>
  );
};

export default CourtEdit;
