import { TableTemplate } from 'types/tableTemplate';

export const customerTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'cpf',
    description: 'CPF',
    originalId: 'cpf',
    width: 130,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 150,
  },
  {
    id: 'customerAddress',
    description: 'ENDEREÇO',
    originalId: 'customerAddress',
    width: 400,
  },
  {
    id: 'customerNeighborhood',
    description: 'BAIRRO',
    originalId: 'customerNeighborhood',
    width: 120,
  },
  {
    id: 'customerCity',
    description: 'CIDADE',
    originalId: 'customerCity',
    width: 130,
  },
  {
    id: 'customerState',
    description: 'ESTADO',
    originalId: 'customerState',
    width: 130,
  },
  {
    id: 'phone',
    description: 'TELEFONE',
    originalId: 'phone',
    width: 150,
  },
  {
    id: 'email',
    description: 'E-MAIL',
    originalId: 'email',
    width: 250,
  },
];
