import { TableTemplate } from 'types/tableTemplate';

export const associatesTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 50,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'active',
    width: 60,
  },
  {
    id: 'formattedValidAt',
    description: 'VÁLIDO ATÉ',
    originalId: 'formattedValidAt',
    width: 150,
  },
  {
    id: 'planName',
    description: 'PLANO',
    originalId: 'is_recurrent',
    width: 150,
  },
  {
    id: 'arenaName',
    description: 'ARENA',
    originalId: 'arenaName',
    width: 300,
  },
  {
    id: 'customerName',
    description: 'NOME',
    originalId: 'CustomerName',
    width: 300,
  },
  {
    id: 'formattedCreatedAt',
    description: 'ASSOCIADO EM',
    originalId: 'CustomerName',
    width: 300,
  },
];
