import React from 'react';
import { IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

type AccountActionsProps = {
  handleSubmit(): void;
};

const AccountActions: React.FC<AccountActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <IconButton color="inherit" onClick={handleSubmit}>
        <DoneIcon />
      </IconButton>
    </>
  );
};

export default AccountActions;
