import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { PasswordResetValidation, ResetData } from './PasswordReset';
import PassowordResetForm from './PassowordResetForm';
import { Link } from 'react-router-dom';

const styles = makeStyles({
  header: {
    padding: '40px 0',
  },
  actions: {
    display: 'flex',
    padding: '50px 0',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .login': {
      marginTop: 30,
    },
  },
});

interface PasswordResetContentProps {
  reset: ResetData;
  handleValidation(): void;
  validation: PasswordResetValidation;
  handleChange(index: 'email' | 'password' | 'password_confirmation', value: string): void;
  loading: boolean;
}

const PasswordResetContent: React.FC<PasswordResetContentProps> = ({
  handleValidation,
  reset,
  validation,
  handleChange,
  loading,
}) => {
  const classes = styles();

  return (
    <>
      <div className={classes.header}>
        <Typography align="center" variant="h6">
          Nova senha
        </Typography>
      </div>
      <form onSubmit={handleValidation}>
        <PassowordResetForm validation={validation} reset={reset} handleChange={handleChange} />
      </form>
      <div className={classes.actions}>
        <Button
          onClick={() => handleValidation()}
          color="primary"
          variant="contained"
          disableElevation
          fullWidth
          disabled={loading}
        >
          Confirmar
        </Button>
        <div className="login">
          <Link to="/login">Ir ao Login</Link>
        </div>
      </div>
    </>
  );
};

export default PasswordResetContent;
