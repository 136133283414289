import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import React, { useEffect, useRef } from 'react';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    // alignItems: 'center',
    gridTemplateColumns: '220px 1fr 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      // alignItems: 'baseline',
      gridTemplateColumns: '200px 200px 200px',
      columnGap: 10,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

type ArenaFilterBoxProps = {
  indexToSearch: string;
  setSearchValue(value: string): void;
  searchValue: string;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const ArenaFilterBox: React.FC<ArenaFilterBoxProps> = ({
  indexToSearch,
  setDisplayMode,
  setSearchValue,
  searchValue,
  displayMode,
}) => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [indexToSearch]);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default ArenaFilterBox;
