import { createContext, useContext } from 'react';
import { Court } from 'types/court';

interface CourtContextValue {
  handleValidation(): void;
  handleChange(index: keyof Court, value: any): void;
  court: Court | null;
}

const CourtContext = createContext<CourtContextValue>({} as CourtContextValue);
export const CourtProvider = CourtContext.Provider;
export const CourtConsumer = CourtContext.Consumer;

export function useCourt(): CourtContextValue {
  const context = useContext(CourtContext);
  return context;
}
