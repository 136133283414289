import { useReducer, Dispatch } from 'react';

import { SiteSetting } from 'types/siteSetting';
import { SettingsActionsType, SETTINGS_CHANGE, SET_SETTINGS } from './types';

const SETTINGS_INITIAL_VALUE: SiteSetting = {
  contact_email: '',
  facebook_profile_url: '',
  primary_color: '',
  footer_company_name: '',
  secondary_color: '',
  home_description: '',
  home_keywords: '',
  home_title: '',
  instagram_profile_url: '',
  whatsapp: '',
  youtube_channel_url: '',
  home_image_url: '',
  facebook_pixel_id: '',
  google_analytics_id: '',
  about_page: '',
  home_segments_title: '',
  bottom_banners_title: '',
};

function settingsReducer(state = SETTINGS_INITIAL_VALUE, action: SettingsActionsType): SiteSetting {
  switch (action.type) {
    case SETTINGS_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case SET_SETTINGS: {
      return action.settings;
    }

    default: {
      return state;
    }
  }
}

type SettingsReducer = [SiteSetting, Dispatch<SettingsActionsType>];

export function useSiteSettingsReducer(): SettingsReducer {
  const [settings, dispatch] = useReducer(settingsReducer, SETTINGS_INITIAL_VALUE);
  return [settings, dispatch];
}
