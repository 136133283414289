import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Invoice } from 'types/invoice';
import CustomerInvoicePaidItem from './CustomerInvoicePaidItem';

interface CustomerInvoicesPaidProps {
  invoices: Invoice[];
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '50vh',
  },
});

const CustomerInvoicesPaid: React.FC<CustomerInvoicesPaidProps> = ({ invoices }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      {invoices.length > 0 ? (
        <>
          {invoices.map(invoice => (
            <CustomerInvoicePaidItem invoice={invoice} key={invoice.id} />
          ))}
        </>
      ) : (
        <div className={classes.container}>
          <Typography>Não possui faturas pagas</Typography>
        </div>
      )}
    </Grid>
  );
};

export default CustomerInvoicesPaid;
