import { TableTemplate } from 'types/tableTemplate';

export const dayUseTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    originalId: 'value',
    width: 200,
  },
  {
    id: 'formattedDay',
    description: 'DATA',
    originalId: 'day',
    width: 150,
  },
  {
    id: 'formattedActive',
    description: 'INATIVO',
    originalId: 'active',
    width: 150,
  },
  {
    id: 'formattedTypeDay',
    description: 'TIPO DIA',
    originalId: 'type_day',
    width: 150,
  },
];
