import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import { Activity } from 'types/activity';
import CustomerActivityItemModule from './CustomerActivityItemModule';

type CustomerActivityListModuleProps = {
  activities: Activity[];
};

const CustomerActivityListModule: React.FC<CustomerActivityListModuleProps> = ({ activities }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(activity => (
            <CustomerActivityItemModule key={activity.id} activity={activity} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerActivityListModule;
