import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { NewsletterSubscriber } from 'types/newsletterSubscriber';
import { useNewsletterSubscribers } from 'pages/newsletters/hooks/useNewsletters';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type NewsletterSubscriberItemTableProps = {
  subscriber: NewsletterSubscriber;
};

const NewsletterSubscriberItemTable: React.FC<NewsletterSubscriberItemTableProps> = ({ subscriber }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedSubscriber } = useNewsletterSubscribers();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedSubscriber(subscriber);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{subscriber[item.id as keyof NewsletterSubscriber]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default NewsletterSubscriberItemTable;
