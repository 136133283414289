import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { usePagination } from 'hooks/pagination';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { DayUse } from 'types/dayuse';
import DayUseItemTable from './DayUseItemTable';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type DayUseListTableProps = {
  daysUse: DayUse[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const DayUseListTable: React.FC<DayUseListTableProps> = ({ daysUse, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  return (
    <TableContent>
      <TableHeader>
        {tableTemplate.map(item => (
          <div
            className={classes.headerItem}
            key={item.id}
            onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
          >
            <Typography variant="caption" color="primary">
              {item.description}
            </Typography>
            {orderedIndex.index === item.originalId && (
              <>
                {orderedIndex.direction === 'asc' ? <ArrowUpward color="primary" /> : <ArrowDownward color="primary" />}
              </>
            )}
          </div>
        ))}
      </TableHeader>
      <TableBody>
        {daysUse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(dayUse => (
          <TableRow key={dayUse.id} onClick={() => history.push(`/day-use/${dayUse.id}`)}>
            <DayUseItemTable dayUse={dayUse} />
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

export default DayUseListTable;
