import { Dispatch, SetStateAction, useState } from 'react';
import { Invoice } from 'types/invoice';
import * as yup from 'yup';

export type InvoiceValidation = {
  description?: string;
  value?: string;
  payday?: string;
  user_id?: string;
};

type UseInvoiceValidation = [
  InvoiceValidation,
  Dispatch<SetStateAction<InvoiceValidation>>,
  (invoice: Invoice) => Promise<void>,
];

export function useInvoiceValidation(): UseInvoiceValidation {
  async function handleValidation(invoice: Invoice) {
    const schema = yup.object().shape({
      description: yup.string().required('A descrição é obrigatória'),
      payday: yup.date().required('O vencimento é obrigatório'),
      value: yup.number().required('O valor é obrigatório'),
      user_id: yup.number().required('O usuário é obrigatório'),
    });

    try {
      await schema.validate(invoice);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<InvoiceValidation>({});
  return [validation, setValidation, handleValidation];
}
