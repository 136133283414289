import React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

const mask = (): Array<RegExp | string> => {
  return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
};

interface TextMaskCustomProps extends MaskedInputProps {
  inputRef: (ref: HTMLElement | null) => void;
}

const CpfInput: React.FC<TextMaskCustomProps> = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
};

export default CpfInput;
