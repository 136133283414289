import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, InputAdornment, IconButton, MenuItem } from '@material-ui/core';
import { InvoiceValidation } from './validation/invoiceValidation';
import { Invoice } from 'types/invoice';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import UsersSelector from './user-selector/UsersSelector';
import { Search } from '@material-ui/icons';

const installments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

type InvoiceFormProps = {
  invoice: Invoice;
  handleChange(index: keyof Invoice, value: any): void;
  validation: InvoiceValidation;
};

const InvoiceForm: React.FC<InvoiceFormProps> = ({ invoice, handleChange, validation }) => {
  const inputs = {
    description: useRef<HTMLInputElement>(null),
    value: useRef<HTMLInputElement>(null),
    user_id: useRef<HTMLInputElement>(null),
    payday: useRef<HTMLInputElement>(null),
  };
  const [usersSelector, setUsersSelector] = useState(false);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      {usersSelector && <UsersSelector onExited={() => setUsersSelector(false)} />}

      <Grid item xs={12} xl={4} lg={4} md={6}>
        <TextField
          inputRef={inputs.user_id}
          error={!!validation.user_id}
          helperText={validation.user_id}
          label="Usuário"
          placeholder="Selecione um usuário"
          margin="normal"
          fullWidth
          value={invoice.user.name || ''}
          autoFocus
          disabled={!!invoice.paid_at}
          InputProps={{
            endAdornment: (
              <>
                {!invoice.paid_at && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setUsersSelector(true)}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />

        <TextField
          inputRef={inputs.description}
          error={!!validation.description}
          helperText={validation.description}
          label="Descrição"
          placeholder="Digite uma descrição para a fatura"
          margin="normal"
          fullWidth
          type="text"
          value={invoice.description}
          onChange={e => handleChange('description', e.target.value)}
          multiline
          rows={5}
          disabled={!!invoice.paid_at}
        />

        <TextField
          inputRef={inputs.value}
          error={!!validation.value}
          helperText={validation.value}
          label="Valor (R$)"
          placeholder="Informe o valor da fatura"
          value={invoice.value || ''}
          onChange={e => handleChange('value', e.target.value)}
          fullWidth
          inputMode="numeric"
          margin="normal"
          disabled={!!invoice.paid_at}
          type="number"
          inputProps={{
            inputMode: 'decimal',
            step: '0.01',
          }}
        />

        <TextField
          select
          label="Parcelas"
          placeholder="Selecione a quantidade de parcelas"
          margin="normal"
          fullWidth
          disabled={!!invoice.paid_at}
          value={invoice.installments}
          onChange={e => handleChange('installments', e.target.value)}
        >
          {installments.map(installment => (
            <MenuItem key={installment} value={installment}>{`${installment}x`}</MenuItem>
          ))}
        </TextField>

        <KeyboardDatePicker
          inputRef={inputs.payday}
          error={!!validation.payday}
          helperText={validation.payday}
          label="Vencimento"
          placeholder="Informe o vencimento de dias"
          value={invoice.payday || null}
          onChange={date => handleChange('payday', date)}
          fullWidth
          margin="normal"
          autoOk
          disabled={!!invoice.paid_at}
        />

        <button type="submit" style={{ display: 'none' }} />
      </Grid>
    </>
  );
};

export default InvoiceForm;
