import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { Activity } from 'types/activity';
import { useCustomersActivities } from 'pages/customers-rating/hooks/useCustomersActivities';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type CustomerActivityItemTableProps = {
  activity: Activity;
};

const CustomerActivityItemTable: React.FC<CustomerActivityItemTableProps> = ({ activity }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedCustomerActivity } = useCustomersActivities();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedCustomerActivity(activity);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{activity[item.id as keyof Activity]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default CustomerActivityItemTable;
