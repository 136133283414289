import React from 'react';
import { fade, ListItem, makeStyles, Typography } from '@material-ui/core';
import { useDialog } from 'components/dialogs/Dialog';
import { Arena } from 'types/arena';
import { useCourt } from '../hooks/useCourt';

const styles = makeStyles(theme => ({
  listItem: {
    justifyContent: 'space-between',
    boxShadow: '1px 1px 9px 1px #eee',
    '&.selected': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
  },
}));

interface ArenaSelectorItemProps {
  arena: Arena;
}

const ArenaSelectorItem: React.FC<ArenaSelectorItemProps> = ({ arena }) => {
  const classes = styles();
  const { handleClose } = useDialog();
  const { handleChange } = useCourt();

  function handleClick() {
    handleChange('arena', arena);
    handleChange('arena_id', arena.id);
    handleClose();
  }

  return (
    <ListItem button className={classes.listItem} onClick={handleClick}>
      <div>
        <Typography variant="body1">{arena.description}</Typography>
        <Typography variant="subtitle1">Contato - {arena.contact}</Typography>
        <Typography variant="body2" color="textSecondary">
          Telefone - {arena.whatsapp}
        </Typography>
      </div>
    </ListItem>
  );
};

export default ArenaSelectorItem;
