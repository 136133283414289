import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Delete, Done } from '@material-ui/icons';
import { useDialog } from 'components/dialogs/Dialog';
import { useArena } from 'pages/arena/hooks/useArena';

type ArenaPlanActionsProps = {
  handleSubmit(): void;
};

const ArenaPlanActions: React.FC<ArenaPlanActionsProps> = ({ handleSubmit: submit }) => {
  const dialog = useDialog();
  const { handleDeletePlan: deletePlan, selectedPlan } = useArena();

  function handleDeletePlan() {
    deletePlan(selectedPlan?.id as number);
    dialog.handleClose();
  }

  function handleSubmit() {
    submit();
    dialog.handleClose();
  }

  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <Done />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir plano">
        <IconButton color="inherit" onClick={handleDeletePlan}>
          <Delete />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ArenaPlanActions;
