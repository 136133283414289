import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import history from 'services/history';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const UserActions: React.FC = () => {
  const classes = styles();

  return (
    <IconButton className={classes.container} color="inherit" onClick={() => history.push('/user')}>
      <AddIcon />
    </IconButton>
  );
};

export default UserActions;
