import React, { MouseEvent, ChangeEvent } from 'react';
import { Checkbox, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { Banner } from 'types/banner';
import { Invoice } from 'types/invoice';
import { useInvoices } from 'pages/invoices/hooks/useInvoices';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type InvoiceItemTableProps = {
  invoice: Invoice;
};

const InvoiceItemTable: React.FC<InvoiceItemTableProps> = ({ invoice }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedInvoice, handleSelectInvoiceInGrid } = useInvoices();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedInvoice(invoice);
  }

  function handleCheckboxClick(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    handleSelectInvoiceInGrid(invoice.id);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'checkbox' ? (
            <div>
              <Checkbox
                checked={invoice.selectedInGrid}
                onChange={event => handleCheckboxClick(event)}
                onClick={e => e.stopPropagation()}
              />
            </div>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{invoice[item.id as keyof Banner]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default InvoiceItemTable;
