import React from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialogs/DialogInput';
import { Typography, Button, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: 200,
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  },
});

interface UserDeleteConfirmProps {
  onExited(): void;
  handleDelete(): void;
}

const UserDeleteConfirm: React.FC<UserDeleteConfirmProps> = ({ onExited, handleDelete }) => {
  const classes = styles();

  function handleClickYes(handleClose: () => void) {
    handleDelete();
    handleClose();
  }

  return (
    <DialogInput hideBackdrop={false} onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography align="center">Deseja realmente excluir esse usuário?</Typography>
            <div className={classes.actions}>
              <Button color="primary" variant="text" onClick={context.handleClose}>
                Não
              </Button>
              <Button onClick={() => handleClickYes(context.handleClose)} variant="contained" color="primary">
                Sim, excluir
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default UserDeleteConfirm;
