import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { User } from 'types/user';
import ReactExport from 'react-export-excel';
import ExcelButtonDownload from 'components/button-download/ExcelButtonDownload';
import Dialog from 'components/dialogs/Dialog';

interface CustomerExportProps {
  data: User[];
  onExited(): void;
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
  },
});

export const CustomerExport: React.FC<CustomerExportProps> = ({ data, onExited }) => {
  const classes = useStyles();
  return (
    <Dialog onExited={onExited} title="Export para Excel" maxWidth="sm" height="60vh">
      <div className={classes.container}>
        <Typography>Sua planilha está pronta.</Typography>

        <ExcelFile element={<ExcelButtonDownload />}>
          <ExcelSheet data={data} name="Clientes">
            <ExcelColumn label="NOME" value="name" />
            <ExcelColumn label="EMAIL" value="email" />
            <ExcelColumn label="TELEFONE" value="phone" />
            <ExcelColumn label="ENDEREÇO" value="customerAddress" />
            <ExcelColumn label="CIDADE" value="customerCity" />
            <ExcelColumn label="ESTADO" value="customerState" />
            <ExcelColumn label="BAIRRO" value="customerNeighborhood" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    </Dialog>
  );
};
