import { Dispatch, SetStateAction, useState } from 'react';
import { ArenaPlan } from 'types/arenaPlan';
import * as yup from 'yup';

export type ArenaPlanValidation = {
  months_quantity?: string;
  value?: string;
};

type UseArenaPlanValidation = [
  ArenaPlanValidation,
  Dispatch<SetStateAction<ArenaPlanValidation>>,
  (plan: ArenaPlan) => Promise<void>,
];

export function useArenaPlanValidation(): UseArenaPlanValidation {
  async function handleValidation(plan: ArenaPlan) {
    const schema = yup.object().shape({
      months_quantity: yup
        .number()
        .min(1, 'O mínimo é 1')
        .max(12, 'O máximo é 12')
        .typeError('A quantidade de meses é obrigatória')
        .required('A quantidade de meses é obrigatória'),
      value: yup.number().typeError('O valor deve ser um número válido').required('O valor é obrigatório'),
    });

    try {
      await schema.validate(plan);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<ArenaPlanValidation>({});
  return [validation, setValidation, handleValidation];
}
