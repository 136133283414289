import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import { DayUse } from 'types/dayuse';
import DayUseItemModule from './DayUseItemModule';

type DayUseListModuleProps = {
  daysUse: DayUse[];
};

const DayUseListModule: React.FC<DayUseListModuleProps> = ({ daysUse }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {daysUse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(dayUse => (
        <DayUseItemModule key={dayUse.id} dayUse={dayUse} />
      ))}
    </List>
  );
};

export default DayUseListModule;
