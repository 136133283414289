import React, { useEffect, useRef } from 'react';
import { FormControlLabel, makeStyles, Switch, TextField } from '@material-ui/core';
import { ArenaPlan } from 'types/arenaPlan';
import { ArenaPlanValidation } from './validation/arenaPlanValidation';

const styles = makeStyles({
  formControlLabel: {
    marginTop: 15,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
  },
});

type ArenaPlanFormProps = {
  plan: ArenaPlan;
  handleChange(index: keyof ArenaPlan, value: any): void;
  validation: ArenaPlanValidation;
};

const ArenaPlanForm: React.FC<ArenaPlanFormProps> = ({ plan, handleChange, validation }) => {
  const classes = styles();
  const inputs = {
    value: useRef<HTMLInputElement>(null),
    months_quantity: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <div className={classes.container}>
      <TextField
        inputRef={inputs.months_quantity}
        error={!!validation.months_quantity}
        helperText={validation.months_quantity}
        label="Quantidade de meses"
        placeholder="Quantidade de meses do plano"
        value={plan.months_quantity}
        onChange={e => handleChange('months_quantity', e.target.value)}
        fullWidth
        margin="normal"
        type="number"
        inputProps={{
          inputMode: 'decimal',
          step: 1,
          min: 1,
          max: 12,
        }}
        autoFocus
      />

      <TextField
        inputRef={inputs.value}
        error={!!validation.value}
        helperText={validation.value}
        label="(R$) Valor"
        placeholder="Valor do plano"
        value={plan.value}
        onChange={e => handleChange('value', e.target.value)}
        fullWidth
        margin="normal"
        type="number"
        inputProps={{
          inputMode: 'decimal',
          step: 0.01,
        }}
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Switch checked={plan.active} onChange={e => handleChange('active', e.target.checked)} color="primary" />
        }
        label="Ativo"
      />

      <button type="submit" style={{ display: 'none' }} />
    </div>
  );
};

export default ArenaPlanForm;
