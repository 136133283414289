import React, { useState } from 'react';
import { useAuth } from 'hooks/auth';
import { useSelector } from 'store/selector';
import Appbar from 'components/appbar/Appbar';
import { DashboardCharts } from './charts/DashboardCharts';
import InsideLoading from 'components/loading/InsideLoading';
import { useFetchDashboard } from './hooks/useFetchDashboard';
import FilterActions from 'components/filter-box/FilterActions';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import DashboardReportDateFilter from 'components/chart/DashboardReportDateFilter';
import DashboardReportCustomDate from 'components/chart/DashboardReportCustomerDate';
import DashboardReportHeaderButtons from 'components/chart/DashboardReportHeaderButtons';

const Dashboard: React.FC = () => {
  const auth = useAuth();
  const user = useSelector(state => state.user);
  const [dialogDate, setDialogDate] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [
    getDescriptionPageHeader,
    handleDateConfig,
    handleConfirmCustomDates,
    dateConfig,
    invoices,
    invoicesNoPaid,
    loading,
    customerLength,
    invoicesDayUse,
  ] = useFetchDashboard();

  return (
    <>
      <Appbar title="Dashboard" />
      {auth.isLoading || !user ? (
        <InsideLoading />
      ) : (
        <>
          {dialogDate && (
            <DashboardReportDateFilter
              handleDateConfig={handleDateConfig}
              dateConfig={dateConfig}
              onExited={() => setDialogDate(false)}
              handleConfirmCustomDates={handleConfirmCustomDates}
            />
          )}
          <DashboardReportCustomDate
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            handleConfirmCustomDates={handleConfirmCustomDates}
          />
          <Appbar
            title={`Olá, ${user?.name}`}
            ActionsComponent={<FilterActions handleOpenDialog={() => setDialogDate(true)} />}
          />
          <PageHeaderActions
            title={`Olá, ${user.name}`}
            description={getDescriptionPageHeader()}
            ActionComponent={
              <DashboardReportHeaderButtons
                dateConfig={dateConfig}
                handleDateConfig={handleDateConfig}
                setAnchorEl={setAnchorEl}
              />
            }
          />
          <DashboardCharts
            invoicesNoPaid={invoicesNoPaid}
            invoices={invoices}
            invoicesDayUse={invoicesDayUse}
            customerLength={customerLength}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
