import React, { useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core';
import { Activity } from 'types/activity';
import { ActivityValidation } from 'pages/customers-rating/validation/ActivityValidation';

const styles = makeStyles({
  formControlLabel: {
    marginTop: 30,
  },
});

type CustomerActivityFormProps = {
  activity: Activity;
  handleChange(index: keyof Activity, value: any): void;
  validation: ActivityValidation;
};

const CustomerActivityForm: React.FC<CustomerActivityFormProps> = ({ activity, handleChange, validation }) => {
  const classes = styles();

  const inputs = {
    value_holiday: useRef<HTMLInputElement>(null),
    value_associate: useRef<HTMLInputElement>(null),
    value_week: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Grid item xs={12} xl={4} lg={4} md={6}>
      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Descrição"
        placeholder="Descrição"
        value={activity.description}
        onChange={e => handleChange('description', e.target.value)}
        fullWidth
        margin="normal"
      />

      <TextField
        inputRef={inputs.value_week}
        error={!!validation.value_week}
        helperText={validation.value_week}
        label="Valor dia da semana (%)"
        placeholder="Dia da semana"
        value={activity.value_week}
        onChange={e => handleChange('value_week', e.target.value)}
        fullWidth
        inputMode="numeric"
        margin="normal"
        type="number"
        inputProps={{
          inputMode: 'decimal',
          step: '0.01',
        }}
      />

      <TextField
        inputRef={inputs.value_holiday}
        error={!!validation.value_holiday}
        helperText={validation.value_holiday}
        label="Valor fim de semana (%)"
        placeholder="Fim de semana"
        value={activity.value_holiday}
        onChange={e => handleChange('value_holiday', e.target.value)}
        fullWidth
        inputMode="numeric"
        margin="normal"
        type="number"
        inputProps={{
          inputMode: 'decimal',
          step: '0.01',
        }}
      />

      <TextField
        inputRef={inputs.value_associate}
        error={!!validation.value_associate}
        helperText={validation.value_associate}
        label="Desconto associado (%)"
        placeholder="Desconto Associado"
        value={activity.value_associate}
        onChange={e => handleChange('value_associate', e.target.value)}
        fullWidth
        inputMode="numeric"
        margin="normal"
        type="number"
        inputProps={{
          inputMode: 'decimal',
          step: '0.01',
        }}
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            checked={activity.active}
            onChange={e => handleChange('active', e.target.checked)}
            color="primary"
          />
        }
        label="Ativo"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Grid>
  );
};

export default CustomerActivityForm;
