import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Court } from 'types/court';

type CourtsContextValue = {
  selectedCourt: Court | null;
  setSelectedCourt(court: Court | null): void;
  setCourt: Dispatch<SetStateAction<Court[]>>;
};

const CourtsContext = createContext<CourtsContextValue>({} as CourtsContextValue);
export const CourtsProvider = CourtsContext.Provider;

export function useCourts(): CourtsContextValue {
  const context = useContext(CourtsContext);
  return context;
}
