import React from 'react';
import { ListItem, makeStyles, Typography } from '@material-ui/core';
import { Invoice } from 'types/invoice';
import history from 'services/history';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR';
import { moneyFormat } from 'helpers/numberFormat';

type CustomerInvoicePaidItemProps = {
  invoice: Invoice;
};

const useStyles = makeStyles({
  list: {
    cursor: 'pointer',
    display: 'grid',
    gap: 5,
    padding: 15,
    borderLeft: '4px solid #68df59',
    marginTop: 10,
    borderRadius: 5,
  },
});

const CustomerInvoicePaidItem: React.FC<CustomerInvoicePaidItemProps> = ({ invoice }) => {
  const classes = useStyles();
  function handleClick() {
    history.push(`/invoices/${invoice.id}`);
  }
  return (
    <>
      {invoice.paid_at && (
        <ListItem className={classes.list} onClick={handleClick}>
          <Typography>Usuário - {invoice.user.name}</Typography>
          <Typography>Valor - {moneyFormat(invoice.value)}</Typography>
          <Typography>Descrição - {invoice.description}</Typography>
          <Typography color="primary">Pago em - {format(parseISO(invoice.paid_at), 'P', { locale: ptBR })}</Typography>
        </ListItem>
      )}
    </>
  );
};

export default CustomerInvoicePaidItem;
