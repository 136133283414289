import React from 'react';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { Button, IconButton, makeStyles } from '@material-ui/core';

type DashboardReportHeaderButtonsProps = {
  loading?: boolean;
  handleDateConfig(days: number): void;
  dateConfig: number | null;
  setAnchorEl(element: HTMLButtonElement): void;
};

const useStyles = makeStyles({
  buttons: {
    //
  },
});

const DashboardReportHeaderButtons: React.FC<DashboardReportHeaderButtonsProps> = ({
  loading,
  handleDateConfig,
  dateConfig,
  setAnchorEl,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.buttons}>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 0 ? 'primary' : 'default'}
          onClick={() => handleDateConfig(0)}
        >
          Hoje
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 15 ? 'primary' : 'default'}
          onClick={() => handleDateConfig(15)}
        >
          15 dias
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 30 ? 'primary' : 'default'}
          onClick={() => handleDateConfig(30)}
        >
          30 dias
        </Button>
        <Button
          disabled={loading}
          variant="text"
          size="small"
          color={dateConfig === 60 ? 'primary' : 'default'}
          onClick={() => handleDateConfig(60)}
        >
          60 dias
        </Button>
        <IconButton
          disabled={loading}
          color={dateConfig === null ? 'primary' : 'default'}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <CalendarIcon />
        </IconButton>
      </div>
    </>
  );
};

export default DashboardReportHeaderButtons;
