import React from 'react';
import { Invoice } from 'types/invoice';
import { makeStyles } from '@material-ui/core';
import { DayUseInvoice } from 'types/dayUseInvoice';
import { InvoiceChart } from './invoice/InvoiceChart';
import { CustomerChart } from './customer/CustomerChart';
import InsideLoading from 'components/loading/InsideLoading';
import { InvoiceNoPaidChart } from './invoice/InvoiceNoPaidChart';
import { DayUseInvoiceChart } from './dayUseInvoice/DayUseInvoiceChart';

interface DashboardChartsProps {
  loading: boolean;
  customerLength: number;
  invoices: Invoice[];
  invoicesNoPaid: Invoice[];
  invoicesDayUse: DayUseInvoice[];
}

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr 1fr 1fr 1fr)',
    gap: '10px',
  },
});

export const DashboardCharts: React.FC<DashboardChartsProps> = ({
  loading,
  customerLength,
  invoices,
  invoicesNoPaid,
  invoicesDayUse,
}) => {
  const classes = useStyles();
  return (
    <>
      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          <CustomerChart customerLength={customerLength} />
          <InvoiceChart invoices={invoices} />
          <InvoiceNoPaidChart invoicesNoPaid={invoicesNoPaid} />
          <DayUseInvoiceChart invoicesDayUse={invoicesDayUse} />
        </div>
      )}
    </>
  );
};
