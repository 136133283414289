import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useInvoice } from 'pages/invoices/hooks/useInvoice';

type PlansActionsProps = {
  handleSubmit(): void;
};

const PlansActions: React.FC<PlansActionsProps> = ({ handleSubmit }) => {
  const { invoice } = useInvoice();
  return (
    <Tooltip title="Salvar">
      <IconButton disabled={!!invoice.paid_at} color="inherit" onClick={!invoice.paid_at ? handleSubmit : undefined}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default PlansActions;
