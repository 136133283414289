import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Appbar from 'components/appbar/Appbar';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/no-data/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import { Affiliate } from 'types/affiliate';
import { associatesTableTemplate } from './associatesTableTemplate';
import { AssociatesProvider } from './hooks/useAssociates';
import AssociateListModule from './list/module/AssociateListModule';
import AssociateListTable from './list/table/AssociateListTable';

let timer: NodeJS.Timeout;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '300px',
    [theme.breakpoints.down('md')]: {
      flex: 1,
      gridTemplateColumns: '1fr',
    },
  },
}));

const Associates: React.FC = () => {
  const classes = useStyles();
  const [selectedAssociate, setSelectedAssociate] = useState<null | Affiliate>(null);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Affiliate[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchText, setSearchText] = useState('');
  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);
  const app = useApp();

  const fetchAssociates = useCallback((searchText?: string) => {
    setLoading(true);
    api
      .get<Affiliate[]>('/affiliates', { params: { q: searchText } })
      .then(response => {
        setAffiliates(
          response.data.map(affiliate => {
            const plural = affiliate.plan.months_quantity > 1 ? 'meses' : 'mês';
            return {
              ...affiliate,
              customerName: affiliate.user.name,
              arenaName: affiliate.plan.arena?.description as string,
              planName: `${affiliate.plan.months_quantity} ${plural}`,
              formattedActive: affiliate.active ? 'Sim' : 'Não',
              formattedValidAt: format(parseISO(affiliate.valid_at), 'PP', { locale: ptBR }),
              formattedCreatedAt: format(parseISO(affiliate.created_at), 'PP', { locale: ptBR }),
            };
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => fetchAssociates(''), [fetchAssociates]);

  function handleTextSearchChange(value: string) {
    setSearchText(value);

    clearTimeout(timer);

    if (value.length < 4 && value.length > 0) {
      return;
    }

    timer = setTimeout(() => fetchAssociates(value), 500);
  }

  useEffect(() => {
    setFiltered(affiliates);
  }, [affiliates]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleUpdate(affiliate: Affiliate) {
    setAffiliates(state =>
      state.map(_affiliate => {
        if (_affiliate.id === affiliate.id) {
          return {
            ..._affiliate,
            ...affiliate,
          };
        }

        return _affiliate;
      }),
    );
  }

  return (
    <AssociatesProvider value={{ selectedAssociate, setSelectedAssociate, setAffiliates, handleUpdate }}>
      <Appbar title="Associados" />
      <PageHeaderActions title="Associados" description="Relação de associados" />
      <FilterBox>
        <div className={classes.filter}>
          <TextField
            label="Pesquisar"
            placeholder="Pesquisar"
            autoFocus
            value={searchText}
            onChange={e => handleTextSearchChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Nenhum associado para mostrar" />
      ) : (
        <TableContainer tableTemplate={associatesTableTemplate}>
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <AssociateListTable affiliates={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <AssociateListModule affiliates={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        </TableContainer>
      )}
    </AssociatesProvider>
  );
};

export default Associates;
