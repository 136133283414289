import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTable } from './hook/useTable';

const useStyles = makeStyles({
  bodyContainer: ({ width }: { width?: number; minHeight?: number }) => ({
    overflowX: 'hidden',
    width: width ? `${width}px` : '100%',
    maxHeight: 'calc(100vh - 374px)',
  }),
  body: ({ width }: { width?: number; minHeight?: number }) => ({
    width: width ? `${width}px` : '100%',
  }),
});

type TableBodyProps = {
  width?: number;
  minHeight?: number;
};

const TableBody: React.FC<TableBodyProps> = ({ children }) => {
  const { width } = useTable();
  const classes = useStyles({ width });

  return (
    <div className={classes.bodyContainer}>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

export default TableBody;
