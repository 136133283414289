import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import history from 'services/history';
import PlanActions from './AffiliationInvoicesActions';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'hooks/app';
import { api } from 'services/api';
import { AffiliationInvoicesProvider } from './hooks/useAffiliationInvoices';
import TableContainer from '../../components/table/TableContainer';
import { affiliationInvoicesTableTemplate } from './affiliationInvoicesTableTemplate';
import AffiliationInvoicesFilterBox from './AffiliationInvoicesFilterBox';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import Pagination from 'components/pagination/Pagination';
import DayUseInvoiceListTable from './list/table/AffiliationInvoiceListTable';
import DayUseInvoiceListModule from './list/module/AffiliationInvoiceListModule';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/numberFormat';
import { AffiliationInvoice } from 'types/affiliationInvoice';
import Loading from 'components/loading/Loading';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

export interface QueryParams {
  initial_date: Date | null;
  final_date: Date | null;
  username: string;
  only_conciliated: boolean;
}

const queryParamsInitialValue: QueryParams = {
  initial_date: subDays(new Date(), 30),
  final_date: new Date(),
  username: '',
  only_conciliated: false,
};

let timer: NodeJS.Timeout;

const AffiliationInvoices: React.FC = () => {
  const classes = useStyles();
  const [affiliationInvoices, setAffiliationInvoices] = useState<AffiliationInvoice[]>([]);
  const [selectedAffiliationInvoice, setSelectedAffiliationInvoice] = useState<null | AffiliationInvoice>(null);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<AffiliationInvoice[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsInitialValue);
  const [selectAll, setSelectAll] = useState(false);
  const [saving, setSaving] = useState(false);
  const hasSelected = useMemo(() => filtered.some(invoice => invoice.selectedInGrid), [filtered]);

  useEffect(() => {
    setFiltered(state =>
      state.map(invoice => {
        invoice.selectedInGrid = selectAll;
        return invoice;
      }),
    );
  }, [selectAll]);

  const fetchInvoices = useCallback((query?: QueryParams, selected = false) => {
    setLoading(true);
    api
      .get<AffiliationInvoice[]>('/affiliationInvoices', {
        params: { ...query, only_conciliated: query?.only_conciliated ? 1 : 0 },
      })
      .then(response => {
        setAffiliationInvoices(
          response.data.map(affiliationInvoice => ({
            ...affiliationInvoice,
            formattedCreatedAt: format(parseISO(affiliationInvoice.created_at), 'PPp', { locale: ptBR }),
            formattedConciliatedAt: affiliationInvoice.conciliated_at
              ? format(parseISO(affiliationInvoice.created_at), 'PPp', { locale: ptBR })
              : '-',
            formattedPaidAt: affiliationInvoice.paid_at
              ? format(parseISO(affiliationInvoice.paid_at), 'PPp', { locale: ptBR })
              : '-',
            formattedCanceledAt: affiliationInvoice.canceled_at
              ? format(parseISO(affiliationInvoice.canceled_at), 'PPp', { locale: ptBR })
              : '-',
            formattedValue: moneyFormat(affiliationInvoice.value),
            selectedInGrid: selected,
          })),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => fetchInvoices(queryParamsInitialValue), [fetchInvoices]);

  useEffect(() => {
    setFiltered(affiliationInvoices);
  }, [affiliationInvoices]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleQueryParamsChange(index: keyof QueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));

    const query = {
      ...queryParams,
      [index]: value,
    };

    clearTimeout(timer);

    if (index !== 'username') {
      fetchInvoices(query);
      return;
    }

    if (index === 'username' && value.lenght < 4) {
      return;
    }

    timer = setTimeout(() => fetchInvoices(query), 500);
  }

  function handleSelectInvoiceInGrid(invoiceId: string) {
    setFiltered(state =>
      state.map(invoice => {
        invoice.selectedInGrid = invoice.id === invoiceId ? !invoice.selectedInGrid : invoice.selectedInGrid;
        return invoice;
      }),
    );
  }

  function handleConciliated() {
    const ids = filtered.filter(invoice => invoice.selectedInGrid).map(invoice => invoice.id);
    setSaving(true);

    api
      .post('/affiliationInvoices/updateConciliated', { ids })
      .then(() => {
        setFiltered(state =>
          state.map(invoice => {
            invoice.selectedInGrid = false;
            return invoice;
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  return (
    <AffiliationInvoicesProvider
      value={{
        selectedAffiliationInvoice,
        setSelectedAffiliationInvoice,
        setSelectAll,
        handleSelectInvoiceInGrid,
        selectAll,
      }}
    >
      <Appbar title="Faturas" ActionsComponent={<PlanActions />} />
      <PageHeaderActions
        title="Faturas"
        description="Gestão das faturas dos associados Arena H2i"
        ActionComponent={
          <Button size="small" variant="contained" color="primary" onClick={() => history.push('/invoices/new')}>
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={affiliationInvoicesTableTemplate}>
        <AffiliationInvoicesFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          handleQueryParamsChange={handleQueryParamsChange}
          queryParams={queryParams}
          hasSelected={hasSelected}
          handleConciliated={handleConciliated}
        />

        {saving && <Loading />}

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum fatura de associado para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <DayUseInvoiceListTable
                  affiliationInvoices={filtered}
                  handleSort={handleSort}
                  orderedIndex={orderedIndex}
                />
              ) : (
                <DayUseInvoiceListModule affiliationInvoices={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </AffiliationInvoicesProvider>
  );
};

export default AffiliationInvoices;
