import { createContext, useContext } from 'react';
import { DayUse } from 'types/dayuse';

type DaysUseContextValue = {
  selectedDayUse: DayUse | null;
  setSelectedDayUse(dayUse: DayUse | null): void;
};

const DaysUseContext = createContext<DaysUseContextValue>({} as DaysUseContextValue);
export const DaysUseProvider = DaysUseContext.Provider;

export function useDaysUse(): DaysUseContextValue {
  const context = useContext(DaysUseContext);
  return context;
}
