import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { UserProfile } from 'types/userProfile';

type UserProfileRulesProps = {
  userProfile?: UserProfile;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rules: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'column',
    '& div': {
      marginTop: 10,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 7,
      },
    },
  },
});

const UserProfileRules: React.FC<UserProfileRulesProps> = ({ userProfile }) => {
  const classes = useStyles();

  return (
    <div className={classes.rules}>
      <Typography gutterBottom>Permissões do usuário</Typography>
      {userProfile &&
        userProfile.access_rules.map(rule => (
          <div key={rule.id}>
            <DoneIcon color="secondary" />
            <Typography>{rule.title}</Typography>
          </div>
        ))}
    </div>
  );
};

export default UserProfileRules;
