import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { api } from 'services/api';
import jwt from 'jsonwebtoken';
import { setUser } from 'store/modules/user/actions';
import history from 'services/history';

interface AuthContextData {
  login(email: string, password: string): Promise<boolean>;
  logout(): Promise<boolean>;
  checkAuth(): boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  loggingOff: boolean;
}

const AuthContext = React.createContext({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loggingOff, setLoggingOff] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('access_token');

    if (token) {
      const payload: any = jwt.decode(token);

      if (payload) setIsAuthenticated(true);

      setIsLoading(true);
      api
        .get(`/adminUsers/${payload.sub}`)
        .then(response => {
          dispatch(setUser(response.data));
        })
        .catch(err => {
          if (err.response) console.log(err.response.data.error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch]);

  const login = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        api
          .post('/login', { email, password })
          .then(_response => {
            const response = _response.data;
            localStorage.setItem('access_token', response.token);
            dispatch(setUser(response.user));
            setIsAuthenticated(true);
            resolve(true);
          })
          .catch(err => {
            if (err.response) {
              if (err.response.status === 401) reject(err);
            } else reject(err);
          });
      });
    },
    [dispatch],
  );

  const logout = useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      setLoggingOff(true);
      api
        .post('/logout')
        .then(() => {
          localStorage.removeItem('access_token');
          setIsAuthenticated(false);
          dispatch(setUser(null));
          history.push('/login');
          setLoggingOff(false);
          resolve(true);
        })
        .catch(err => {
          setLoggingOff(false);
          reject(new Error(err));
        });
    });
  }, [dispatch]);

  const checkAuth = useCallback((): boolean => {
    const token = localStorage.getItem('access_token');
    const secret: jwt.Secret = process.env.REACT_APP_SECRET || '';

    if (token) {
      try {
        jwt.verify(token, secret, {
          ignoreNotBefore: true,
        });

        return true;
      } catch (e) {
        console.log(e);
        localStorage.removeItem('access_token');
        setIsAuthenticated(false);
        dispatch(setUser(null));
      }
    }

    return false;
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ login, logout, checkAuth, isAuthenticated, isLoading, loggingOff }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) throw new Error('This hook must be in Auth Context Component');

  return context;
}

export default AuthProvider;
