import React from 'react';
import List from 'components/list/List';
import ProfileItem from './ProfileItem';
import { UserProfile } from 'types/userProfile';

type ProfileListProps = {
  profiles: UserProfile[];
};

const ProfileList: React.FC<ProfileListProps> = ({ profiles }) => {
  return (
    <List>
      {profiles.map(profile => (
        <ProfileItem profile={profile} key={profile.id} />
      ))}
    </List>
  );
};

export default ProfileList;
