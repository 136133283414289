import React from 'react';
import { Collapse } from '@material-ui/core';
import SidebarItem from '../SidebarItem';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { useSidebar } from '../hook/useSidebar';
import { Collections, Description, Email, LaptopChromebook } from '@material-ui/icons';

const Site: React.FC = () => {
  const { handleCollapseClick, collapsible } = useSidebar();
  return (
    <>
      <SidebarItemExpendable
        onClick={() => handleCollapseClick('site')}
        icon={<LaptopChromebook />}
        text="Site"
        opened={collapsible.site}
      />
      <Collapse in={collapsible.site}>
        <SidebarItem to="/slider-banners" icon={<Collections />} text="Slider" nested={4} />
        <SidebarItem to="/banners" icon={<Collections />} text="Banners" nested={4} />
        <SidebarItem to="/pages" icon={<Description />} text="Páginas" nested={4} />
        <SidebarItem to="/newsletter-subscribers" icon={<Email />} text="Newsletter" nested={4} />
      </Collapse>
    </>
  );
};

export default Site;
