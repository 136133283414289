import React, { useRef } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { CustomerValidation } from '../../validation/CustomerValidation';
import { useCustomer } from '../../hooks/useCustomer';
import CpfInput from 'components/masked-input/CpfInput';
import PhoneInput from 'components/masked-input/PhoneInput';
import InputWhatsAppButton from 'components/input-whatsapp-button/InputWhatsAppButton';

interface CustomerDataProps {
  validation: CustomerValidation;
}

const CustomerData: React.FC<CustomerDataProps> = ({ validation }) => {
  const { customer, handleChange, activities } = useCustomer();

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    cpf: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
  };

  return (
    <Grid container spacing={0}>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={4} md={4}>
          <TextField
            disabled
            fullWidth
            label="Código"
            placeholder="Código"
            margin="normal"
            defaultValue={customer?.id || ''}
            variant="standard"
            InputProps={{
              readOnly: true,
              style: { textTransform: 'uppercase' },
            }}
          />
        </Grid>
        <Grid item xs={8} md={8}>
          <TextField
            disabled
            inputRef={inputs.cpf}
            label="CPF"
            placeholder="CPF"
            inputMode="numeric"
            InputProps={{
              inputComponent: CpfInput as any,
              readOnly: true,
            }}
            value={customer?.cpf}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label="Nome"
            placeholder="Nome"
            margin="normal"
            value={customer?.name}
            inputRef={inputs.name}
            error={!!validation.name}
            helperText={validation.name}
            onChange={e => handleChange('name', e.target.value)}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            autoCapitalize="words"
            type="text"
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            autoFocus
            fullWidth
            label="Telefone"
            placeholder="Telefone"
            margin="normal"
            inputRef={inputs.phone}
            error={!!validation.phone}
            helperText={validation.phone}
            value={customer?.phone}
            type="phone"
            autoComplete="phone"
            InputProps={{
              inputComponent: PhoneInput as any,
              endAdornment: <InputWhatsAppButton phone={customer?.phone || ''} />,
            }}
            inputMode="numeric"
            onChange={e => handleChange('phone', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            label="E-mail"
            placeholder="E-mail"
            margin="normal"
            value={customer?.email}
            onChange={e => handleChange('email', e.target.value)}
            inputRef={inputs.email}
            error={!!validation.email}
            helperText={validation.email}
            autoComplete="email"
            type="email"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            select
            fullWidth
            label="Classificação do cliente"
            margin="normal"
            value={customer?.activity_id || ''}
            onChange={e => handleChange('activity_id', e.target.value)}
          >
            {activities.map(activity => (
              <MenuItem value={activity.id} key={activity.id}>
                {activity.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerData;
