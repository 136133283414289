import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { SliderBanner } from 'types/sliderBanner';
import { useSiteBanners } from 'pages/slider-banners/hooks/useSiteBanners';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type SiteBannerItemTableProps = {
  banner: SliderBanner;
};

const SiteBannerItemTable: React.FC<SiteBannerItemTableProps> = ({ banner }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedBanner } = useSiteBanners();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedBanner(banner);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{banner[item.id as keyof SliderBanner]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default SiteBannerItemTable;
