import { User } from 'types/user';
import { UserProfile } from 'types/userProfile';
import { UserActionsType, USER_CHANGE, SET_USER, SET_PROFILE } from './types';

export function userChange(index: string, value: string): UserActionsType {
  return {
    type: USER_CHANGE,
    index,
    value,
  };
}

export function setUser(user: User): UserActionsType {
  return {
    type: SET_USER,
    user,
  };
}

export function setProfile(profile: UserProfile): UserActionsType {
  return {
    type: SET_PROFILE,
    profile,
  };
}
