import { Dispatch, SetStateAction, useState } from 'react';
import { DayUse } from 'types/dayuse';
import * as yup from 'yup';

export type DayUseValidation = {
  value?: string;
  day?: string;
};

type UseDayUseValidation = [
  DayUseValidation,
  Dispatch<SetStateAction<DayUseValidation>>,
  (dayUse: DayUse) => Promise<void>,
];

export function useDayUseValidation(): UseDayUseValidation {
  async function handleValidation(dayUse: DayUse) {
    const schema = yup.object().shape({
      value: yup.number().required('O valor é obrigatório'),
      day: yup.date().required('O vencimento é obrigatório'),
    });

    try {
      await schema.validate(dayUse);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<DayUseValidation>({});
  return [validation, setValidation, handleValidation];
}
