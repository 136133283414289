import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SidebarItem from '../SidebarItem';
import { useApp } from 'hooks/app';
import { SIDEBAR_WIDTH } from 'constants/constants';
import SidebarBackAction from '../SidebarBackAction';
import { AccountBox } from '@material-ui/icons';
import PeopleIcon from '@material-ui/icons/People';
import SecurityIcon from '@material-ui/icons/Security';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#272c3a',
    zIndex: 2,
    transform: `translateX(-${SIDEBAR_WIDTH}px)`,
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
}));

type UserControlSidebarProps = {
  show: boolean;
  handleClose(): void;
};

const UserControlSidebar: React.FC<UserControlSidebarProps> = ({ show, handleClose }) => {
  const classes = useStyles();
  const app = useApp();

  return (
    <div className={classes.container} style={{ transform: show ? 'translateX(0)' : undefined }}>
      <div className={classes.drawerHeader}>
        <IconButton color="inherit" onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color="inherit" variant="h6">
          Controle de usuário
        </Typography>
      </div>
      {!app.isMobile && app.windowWidth > 960 && (
        <SidebarBackAction handleClick={handleClose} icon={<ArrowBackIcon />} text="voltar" />
      )}
      <SidebarItem to="/users" icon={<PeopleIcon />} text="Usuários" />
      <SidebarItem to="/user-profiles" icon={<AccountBox />} text="Perfis de usuário" />
      <SidebarItem to="/access-rules" icon={<SecurityIcon />} text="Regras de acesso" />
    </div>
  );
};

export default UserControlSidebar;
