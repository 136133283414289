import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import DayUseInvoiceItemModule from './DayUseInvoiceItemModule';
import { DayUseInvoice } from 'types/dayUseInvoice';

type DayUseInvoiceListModuleProps = {
  dayUseInvoices: DayUseInvoice[];
};

const DayUseInvoiceListModule: React.FC<DayUseInvoiceListModuleProps> = ({ dayUseInvoices }) => {
  const { rowsPerPage, page } = usePagination();
  return (
    <List>
      {dayUseInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(dayUseInvoice => (
        <DayUseInvoiceItemModule key={dayUseInvoice.id} dayUseInvoice={dayUseInvoice} />
      ))}
    </List>
  );
};

export default DayUseInvoiceListModule;
