import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { NewsletterSubscriber } from 'types/newsletterSubscriber';

type NewsletterSubscriberItemModuleProps = {
  subscriber: NewsletterSubscriber;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const AppBannerItemModule: React.FC<NewsletterSubscriberItemModuleProps> = ({ subscriber }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <Typography variant="caption" color="primary">
        Inscrito {subscriber.id}
      </Typography>
      <Typography>{subscriber.email}</Typography>
      <Typography>{subscriber.phone}</Typography>
    </ListItem>
  );
};

export default AppBannerItemModule;
