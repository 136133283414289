import { createContext, useContext } from 'react';
import { DaysWeek } from 'types/daysWeek';

interface DaysWeekContextValue {
  handleValidation(): void;
  handleChange(index: keyof DaysWeek, value: any): void;
  daysWeek: DaysWeek | null;
}

const DaysWeekContext = createContext<DaysWeekContextValue>({} as DaysWeekContextValue);
export const DaysWeekProvider = DaysWeekContext.Provider;
export const DaysWeekConsumer = DaysWeekContext.Consumer;

export function useDaysWeek(): DaysWeekContextValue {
  const context = useContext(DaysWeekContext);
  return context;
}
