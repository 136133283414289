import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Arena } from 'types/arena';
import { ArenaPlan } from 'types/arenaPlan';

interface ArenaContextValue {
  arena: Arena | null;
  selectedPlan: ArenaPlan | null;
  handleValidation(): void;
  handleChange(index: keyof Arena, value: any): void;
  setSelectedPlan: Dispatch<SetStateAction<null | ArenaPlan>>;
  handleDeletePlan(planId: number): void;
  handleUpdatePlan(plan: ArenaPlan): void;
  handleAddPlan(plan: ArenaPlan): void;
}

const ArenaContext = createContext<ArenaContextValue>({} as ArenaContextValue);
export const ArenaProvider = ArenaContext.Provider;
export const ArenaConsumer = ArenaContext.Consumer;

export function useArena(): ArenaContextValue {
  const context = useContext(ArenaContext);
  return context;
}
