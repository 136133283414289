import React from 'react';
import { useTable } from 'components/table/hook/useTable';
import { ArenaPlan } from 'types/arenaPlan';
import { Typography } from '@material-ui/core';

type ArenaPlanItemTableProps = {
  plan: ArenaPlan;
};

const ArenaPlanItemTable: React.FC<ArenaPlanItemTableProps> = ({ plan }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{plan[item.id as keyof ArenaPlan]}</Typography>
          </div>
        ))}
    </>
  );
};

export default ArenaPlanItemTable;
