import React, { FormEvent, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import * as yup from 'yup';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { AccessRule } from 'types/accessRule';
import AccessRuleActions from './AccessRuleActions';
import AccessRuleForm from '../AccessRuleForm';
import PageHeader from 'components/page-header/PageHeader';
import { useAccessRuleValidation } from '../validation/accessRuleValidation';

const EditAccessRule: React.FC = () => {
  const [rule, setRule] = useState<AccessRule>({
    name: '',
    title: '',
    description: '',
    active: false,
    id: new Date().getTime(),
    access_rules: [],
  });
  const [validation, setValidation, validate] = useAccessRuleValidation();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [accessRules, setAccessRules] = useState<AccessRule[]>([]);

  useEffect(() => {
    if (!id) return;

    async function loadAccessRule() {
      const response = await api.get(`/accessRules/${id}`);
      setRule(response.data);
    }

    function loadAccessRules() {
      return api
        .get('/accessRules')
        .then(response => {
          setAccessRules(response.data);
        })
        .catch(err => {
          console.error(err);
        });
    }

    Promise.all([loadAccessRule(), loadAccessRules()])
      .catch(() => {
        handleOpen('Não foi possível carregar o a regra de acesso');
        history.push('/access-rules');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleOpen, id]);

  function handleChange(index: keyof AccessRule, value: any) {
    setRule(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    validate(rule)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch((err: yup.ValidationError) => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/accessRules/${id}`, rule)
      .then(() => {
        handleOpen('Salvo');
        setLoading(false);
        history.push('/access-rules');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar a regra de acesso');
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/accessRules/${id}`)
      .then(() => {
        setLoading(false);
        history.push('/access-rules');
        handleOpen('Regra excluída');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar a regra de acesso');
      });
  }

  return (
    <>
      <Appbar
        backAction={() => history.push('/access-rules')}
        title="Regra de acesso"
        ActionsComponent={<AccessRuleActions handleSubmit={handleValidation} handleDelete={handleDelete} />}
      />
      {saving && <Loading />}
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container direction="column">
          <PageHeader title="Editar regra de acesso" />
          <Grid item xs={12} lg={4} xl={4} md={6}>
            <form onSubmit={handleValidation}>
              <AccessRuleForm
                accessRules={accessRules}
                handleChange={handleChange}
                accessRule={rule}
                validation={validation}
              />
              <button type="submit" style={{ display: 'none' }} />
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EditAccessRule;
