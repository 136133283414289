import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { CourtValidation } from '../validation/CourtValidation';
import { Court } from 'types/court';
import { Search } from '@material-ui/icons';
import ArenasSelector from '../arena-selector/ArenasSelector';

const styles = makeStyles({
  formControlLabel: {
    marginTop: 30,
  },
});

type CourtFormProps = {
  court: Court;
  handleChange(index: keyof Court, value: any): void;
  validation: CourtValidation;
};

const CourtForm: React.FC<CourtFormProps> = ({ court, handleChange, validation }) => {
  const classes = styles();
  const [arenaSelector, setArenaSelector] = useState(false);
  const inputs = {
    active: useRef<HTMLSelectElement>(null),
    arena_id: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Grid item xs={12} xl={4} lg={4} md={6}>
      {arenaSelector && <ArenasSelector onExited={() => setArenaSelector(false)} />}

      <TextField
        inputRef={inputs.arena_id}
        error={!!validation.arena_id}
        helperText={validation.arena_id}
        label="Arena"
        placeholder="Selecione uma arena"
        margin="normal"
        fullWidth
        value={court.arena?.description || ''}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setArenaSelector(true)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Descrição"
        placeholder="Descrição"
        value={court.description}
        onChange={e => handleChange('description', e.target.value)}
        fullWidth
        margin="normal"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox checked={court.active} onChange={e => handleChange('active', e.target.checked)} color="primary" />
        }
        label="Ativo"
      />

      <button type="submit" style={{ display: 'none' }} />
    </Grid>
  );
};

export default CourtForm;
