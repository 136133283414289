import React, { useState, useEffect, FormEvent } from 'react';
import Appbar from 'components/appbar/Appbar';
import { Grid } from '@material-ui/core';
import PageHeader from 'components/page-header/PageHeader';
import * as yup from 'yup';
import ProfileForm from './ProfileForm';
import history from 'services/history';
import ProfileActions from './ProfileActions';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';
import InsideLoading from 'components/loading/InsideLoading';
import { AccessRule } from 'types/accessRule';
import { UserProfile } from 'types/userProfile';

export type ProfileValidation = {
  name?: string;
};

const NewProfile: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validation, setValidation] = useState<ProfileValidation>({});
  const { handleOpen } = useMessaging();
  const [accessRules, setAccessRules] = useState<AccessRule[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile>({
    id: new Date().getTime(),
    name: '',
    access_rules: [],
  });

  useEffect(() => {
    api
      .get<AccessRule[]>('/accessRules', { params: { option: 'parents' } })
      .then(response => {
        setAccessRules(response.data);
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível carregar o perfil');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleOpen]);

  function handleAccessRulesChange(id: number) {
    const updateChildren = (rules: AccessRule[], state: boolean) =>
      rules.map(rule => {
        rule.active = state;
        rule.access_rules = updateChildren(rule.access_rules, state);
        return rule;
      });

    const handleSetValue = (rules: AccessRule[], id: number) =>
      rules.map(rule => {
        if (rule.id === id) {
          rule.active = !rule.active;
          rule.access_rules = updateChildren(rule.access_rules, !!rule.active);
        }
        handleSetValue(rule.access_rules, id);
        return rule;
      });

    const newValue = handleSetValue(accessRules, id);
    setAccessRules(JSON.parse(JSON.stringify(newValue)));
  }

  function handleUserProfileChange(value: string) {
    setUserProfile(oldValue => ({ ...oldValue, name: value }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(userProfile)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .post(`/userProfiles`, { user_profile: userProfile, access_rules: accessRules })
      .then(() => {
        setSaving(false);
        handleOpen('Salvo');
        history.push('/user-profiles');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar');
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        title="Novo perfil"
        backAction={() => history.push('/user-profiles')}
        ActionsComponent={<ProfileActions handleSubmit={handleValidation} />}
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <>
          <Grid container>
            <PageHeader title="Editar perfil" />
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={4} xl={4} md={6}>
              <form onSubmit={handleValidation}>
                <ProfileForm
                  validation={validation}
                  handleUserProfileChange={handleUserProfileChange}
                  handleAccessRulesChange={handleAccessRulesChange}
                  accessRules={accessRules}
                  profile={userProfile}
                />
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default NewProfile;
