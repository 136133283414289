import { makeStyles } from '@material-ui/core';
import TableContainer from 'components/table/TableContainer';
import { useApp } from 'hooks/app';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { useArena } from 'pages/arena/hooks/useArena';
import React, { useEffect, useState } from 'react';
import { ArenaPlan } from 'types/arenaPlan';
import { arenaPlansTableTemplate } from './arenaPlansTableTemplate';
import ArenaPlanListModule from './module/ArenaPlanListModule';
import ArenaPlanListTable from './table/ArenaPlanListTable';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const ArenaPlans: React.FC = () => {
  const classes = styles();
  const { arena } = useArena();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const app = useApp();
  const [filtered, setFiltered] = useState<ArenaPlan[]>([]);
  const [orderedIndex, sort] = useTableOrder();

  console.log(arena?.plans);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    if (!arena?.plans) {
      return;
    }

    setFiltered(arena.plans);
  }, [arena]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <TableContainer tableTemplate={arenaPlansTableTemplate}>
      <PaginationProvider>
        <div className={classes.container}>
          {displayMode === 'list' ? (
            <ArenaPlanListTable plans={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            <ArenaPlanListModule plans={filtered} />
          )}
        </div>
      </PaginationProvider>
    </TableContainer>
  );
};

export default ArenaPlans;
