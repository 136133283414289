import { Dispatch, SetStateAction, useState } from 'react';
import { Activity } from 'types/activity';
import * as yup from 'yup';

export type ActivityValidation = {
  value_week?: string;
  value_holiday?: string;
  value_associate?: string;
  description?: string;
};

type UseActivityValidation = [
  ActivityValidation,
  Dispatch<SetStateAction<ActivityValidation>>,
  (activity: Activity) => Promise<void>,
];

export function useActivityValidation(): UseActivityValidation {
  async function handleValidation(activity: Activity) {
    const schema = yup.object().shape({
      value_associate: yup.number().required('O desconto de associado é obrigatório'),
      value_week: yup.number().required('O valor semana é obrigatório'),
      value_holiday: yup.number().required('O valor fim de  semana é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
    });

    try {
      await schema.validate(activity);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  const [validation, setValidation] = useState<ActivityValidation>({});
  return [validation, setValidation, handleValidation];
}
