import React from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { Affiliate } from 'types/affiliate';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type AssociateItemTableProps = {
  affiliate: Affiliate;
};

const AssociateItemTable: React.FC<AssociateItemTableProps> = ({ affiliate }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{affiliate[item.id as keyof Affiliate]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default AssociateItemTable;
