import React from 'react';
import { Grid } from '@material-ui/core';
import { DayUseInvoice } from 'types/dayUseInvoice';
import CustomerDayUseInvoiceItem from './CustomerDayUseInvoiceItem';

interface CustomerDayUseInvoicesProps {
  dayUseInvoices: DayUseInvoice[];
}

const CustomerDayUseInvoices: React.FC<CustomerDayUseInvoicesProps> = ({ dayUseInvoices }) => {
  return (
    <Grid container spacing={0}>
      {dayUseInvoices.map(dayUseInvoice => (
        <CustomerDayUseInvoiceItem dayUseInvoice={dayUseInvoice} key={dayUseInvoice.id} />
      ))}
    </Grid>
  );
};

export default CustomerDayUseInvoices;
