import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { DayUse } from 'types/dayuse';

type DayUseItemModuleProps = {
  dayUse: DayUse;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
});

const DayUseItemModule: React.FC<DayUseItemModuleProps> = ({ dayUse }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/day-use/${dayUse.id}`)}>
      <Typography variant="caption" color="primary">
        ID {dayUse.id}
      </Typography>
      <Typography gutterBottom variant="body2" color="textSecondary">
        Inativo - {dayUse.active}
      </Typography>
      <Typography>Data - {dayUse.formattedDay}</Typography>
    </ListItem>
  );
};

export default DayUseItemModule;
