import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Activity } from 'types/activity';

type CustomersActivitiesContextValue = {
  selectedCustomerActivity: Activity | null;
  setSelectedCustomerActivity(customer: Activity | null): void;
  setCustomersActivity: Dispatch<SetStateAction<Activity[]>>;
};

const CustomersActivitiesContext = createContext<CustomersActivitiesContextValue>(
  {} as CustomersActivitiesContextValue,
);
export const CustomersActivitiesProvider = CustomersActivitiesContext.Provider;

export function useCustomersActivities(): CustomersActivitiesContextValue {
  const context = useContext(CustomersActivitiesContext);
  return context;
}
