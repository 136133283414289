import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 15,
    '& div + div': {
      marginTop: 10,
    },
  },
  container: {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 7,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  item: {
    width: '100%',
    height: 15,
  },
}));

const cards = Array(4).fill('');

const ProfilesLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {cards.map((value, index) => (
        <div className={classes.card} key={index}>
          <div className={`animated-background ${classes.item}`} />
          <div className={`animated-background ${classes.item}`} />
        </div>
      ))}
    </div>
  );
};

export default ProfilesLoading;
